import Modal from "../components/Modal";

function SoonModal() {
  return (
    <Modal title="This feature is not ready yet !" autoHeight>
      <div className="space-y-4">
        <p>
          As we are working on Yood, some features aren't already 100% ready.
        </p>
        <p>Try again later. We are adding new things regularly.</p>
      </div>
    </Modal>
  );
}

export default SoonModal;
