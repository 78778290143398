import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import OverrachievedComponent from "../components/OverrachievedComponent";

const TARGET_QUERY = gql`
  query LeaderBoardForTeamOfTarget($token: String!) {
    target: targetByToken(token: $token) {
      id
      value
      publicToken
      overachievedPreview
      logTotal
      teamMember {
        kpiName
        user {
          name
          picture
          role
        }
      }
    }
  }
`;

function OverachievedPublic() {
  const params = useParams();

  const { data } = useQuery(TARGET_QUERY, {
    variables: { token: params.token },
  });

  if (!data?.target) return null;
  return (
    <div className="bg-white p-4 flex">
      <Helmet>
        <meta property="og:title" content={`${data.target.teamMember.user.name} overachieved on Yood !`} />
        <meta property="og:description" content={`${data.target.teamMember.user.name} overachieved on Yood !`} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={`${data.target.overachievedPreview}`} />
      </Helmet>
      <OverrachievedComponent target={data?.target} style={{ width: '800px' }} />
    </div>
  );
}

export default OverachievedPublic;
