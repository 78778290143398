import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Modal from "../components/Modal";
import Alert from "../components/Alert";

import FormSelectInput from "../components/FormSelectInput";
import { MY_LOGS_QUERY } from "./MyLogsPage";
import { logEvent } from "../hooks/analytics";

type FormValues = {
  value: string;
  action: string;
  target: string;
};

const QUERY_LOG = gql`
  query Q($log: ID!) {
    getLogById(id: $log) {
      id
      time
      value
      action {
        id
        name
      }
      team {
        id
        name
      }
      target {
        id
        teamMember {
          actions {
            name
            id
          }
        }
      }
    }
  }
`;

const UPDATE_MMUTATION = gql`
  mutation UpdateLog(
    $updateLogId: ID!
    $action: ID!
    $target: ID!
    $value: Float
  ) {
    updateLog(
      id: $updateLogId
      action: $action
      target: $target
      value: $value
    ) {
      success
      message
    }
  }
`;

function LogEditModal() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [success, setSuccess] = React.useState(false);

  const {
    data: initialLogQuery,
    loading,
  } = useQuery(QUERY_LOG, {
    variables: { log: params.id },
    
  });
  const [updateLog, { loading: updateLoading }] = useMutation(UPDATE_MMUTATION, {
    refetchQueries: [ MY_LOGS_QUERY, 'MyLogsQuery' ],
  });

  function onSubmit(data: FormValues) {
    updateLog({
      variables: {
        updateLogId: params.id,
        action: data.action,
        target: initialLogQuery?.getLogById?.target.id,
        value: parseFloat(data.value),
      },
    });

    logEvent('updateProgress');

    navigate(-1);
  }

  const actions:Action[] = useMemo(() => {
    if (initialLogQuery?.getLogById) {
      return initialLogQuery.getLogById?.target.teamMember.actions;
    }

    return [];
  }, [initialLogQuery]);

  if (!initialLogQuery) return null;

  return (
    <Modal title="Edit log (action)">
        <div className="p-4">
          <Alert type="success" display={!!success} onDismiss={() => setSuccess(false)}>Action edited successfully !</Alert>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormSelectInput
              label="Action"
              options={actions.map((a) => ({ value: `${a.id}`, text: a.name }))}
              {...register("action", {
                required: true,
                value: initialLogQuery?.getLogById?.action.id,
              })}
            />

            <FormInput
              label="Value of the action"
              type="number"
              error={errors.value?.message}
              {...register("value", {
                required: true,
                value: initialLogQuery?.getLogById?.value,
              })}
            />

            {loading || updateLoading ? (
              <div>Loading...</div>
            ) : (
              <Button>Save</Button>
            )}
          </form>
        </div>
    </Modal>
  );
}

export default LogEditModal;
