import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import FormSelectInput from "../components/FormSelectInput";
import persoChallenges from "../libs/PersoChallenges";


const SETTARGETS_MUTATION = gql`
  mutation SetTargetsPerso($challenges: [ChallengeInput]) {
    setChallengesPerso(challenges: $challenges) {
      success
      code
    }
  }
`;


interface FormValue {
  challenges: {
    value?: number;
    global?: boolean;
    periodicity?: string;
    kpiName: {
      value?: string,
      label?: string,
    };
    user?: any
    new?: boolean
  }[]
}

function isDirty(fromForm: any, initial: any) {
  console.log(fromForm, initial)
  if (fromForm?.value !== `${initial?.value}`) return true;
  if (fromForm?.periodicity !== initial?.periodicity) return true;
  if (fromForm?.kpiName?.value !== initial?.kpiName) return true;

  return false;
}

function PersoAssignTargetsPage() {
  const navigate = useNavigate();
  const { control, formState: { errors }, register, handleSubmit  } = useForm<FormValue>({ mode: 'all'});
  const [setChallengesPerso] = useMutation(SETTARGETS_MUTATION); 

  const { fields, insert } = useFieldArray({
    control,
    name: "challenges",
  });

  const loading = false;

  const [challenges, setChallenges] = useState<Challenge[]>();

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    if (!data?.challenges?.length) return navigate('/perso/define', { replace: true });

    setChallenges(data.challenges || []);

    data?.challenges?.map((c: Challenge, i:number) => {
      return insert(i, {
        value: c.value,
        new: c.new,
        periodicity: c.periodicity,
        kpiName: { label: c.kpiName, value: c.kpiName },
      });
    });
  }, [insert]);

  async function sendForm({ challenges: challengesForm } : FormValue) {
    if (!challenges?.length) return;

    const challenges2 = challenges.map(({icon, global, alternativeKpi, periodicities, verb, ...c}, i:number) => {
      return {
        periodicity: challengesForm[i].periodicity,
        value: parseFloat(`${challengesForm[i].value}`) || 0,
        kpiName: challengesForm[i].kpiName?.value,
        teamName: c.name,
        name: c.name,
        kpiUnit: c.kpiUnit,
        new: challengesForm[i].new || isDirty(challengesForm[i], c) || false
      }
    });

    await setChallengesPerso({
      variables: {
        challenges: challenges2
      }
    });

    return navigate('/perso/dash');
  }

  function createOptions(challenge?: Challenge) {
    return [
      { label: challenge?.kpiName, value: challenge?.kpiName },
      ...(Object.values(persoChallenges).find((c) => c.name === challenge?.name)?.alternativeKpi?.map((t) => ({label: t, value: t})) || [])
    ];
  }

  function createPeriodicities(challenge?: Challenge) {
    return (Object.values(persoChallenges).find((c) => c.name === challenge?.name)?.periodicities?.map((t) => ({text: t, value: t})) || [])
  }

  return (
    <div className="w-full p-4">
      <h1 className="text-3xl font-bold font-sans flex-1 lg:pl-4">
        Assign your goals
      </h1>

      <div className="primary-box mt-6 lg:p-10">
        <form className="w-full flex flex-col" onSubmit={handleSubmit(sendForm)}>
          <div className="flex-1 my-6 text-center">
              {fields?.map((field, index) => (
                <div className="mx-3 px-1 mb-8 pb-8 border-b lg:border-0 border-gray-200 last:border-0 lg:flex lg:space-x-6" key={field.id}>
                  <div className="flex self-center items-center space-x-2 pb-3 lg:pb-1 lg:w-1/6">
                    <img
                      src={challenges?.[index]?.icon}
                      alt={challenges?.[index]?.name}
                      className="float-left max-h-10 max-w-10 "
                    />
                    <span className="font-medium">{challenges?.[index]?.name}</span>
                  </div>

                  <div className="flex flex-wrap items-baseline space-x-2 text-sm">
                    <span>I want to achieve</span>

                    <FormInput
                      nolabel
                      type="number"
                      noMargin
                      error={errors.challenges?.[index]?.value?.message}
                      className="w-20 appearance-none border-b border-title py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required:border-red-500"
                      {...register(`challenges.${index}.value`, { required: "A target is required" })}
                    />

                    <Controller
                      name={`challenges.${index}.kpiName`}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <CreatableSelect
                          options={createOptions(challenges?.[index])}
                          styles={{ 
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: 'rgba(24, 83, 115, 1)',
                              borderRadius: 0,
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              margin: 0,
                              padding: 0,
                              minHeight: 36,
                            }),
                            container: (baseStyles) => ({ ...baseStyles, margin: 0, padding: 0 }),  
                            valueContainer: (baseStyles) => ({ ...baseStyles, margin: 0 }),  
                            indicatorsContainer: (baseStyles) => ({ ...baseStyles, padding: 1, border: 0 }),     
                            dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 1 }),
                            indicatorSeparator: (baseStyles) => ({ ...baseStyles, width: 0, height: 0 }),     
                          }}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />

                    <div className="flex items-baseline space-x-2">
                      <span> per </span>
                      <FormSelectInput
                        options={createPeriodicities(challenges?.[index])}
                        style={{ borderWidth: 0, borderRadius: 0, borderBottomWidth: 1 }}
                        {...register(`challenges.${index}.periodicity`)}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          

          <div className="justify-between sticky bottom-0 right-0 left-0 py-4 lg:py-0 bg-white flex lg:relative">
              <Button onClick={() => navigate("/perso/define")} variant="noborder">
                Back
              </Button>

            <Button type="submit" disabled={loading}>{loading ? 'Generating...' : 'Validate targets'}</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PersoAssignTargetsPage;
