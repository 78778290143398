import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import Button from "../components/Button";
import persoChallenges from "../libs/PersoChallenges";

const CHALLENGES_LIFE_QUERY = gql`
query LifeChallenges {
  challenges: getLifeChallenges {
    id
    kpiName
    kpiUnit
    name
    actions {
      name
      id
    }
  }
}
`;

const PERSO_TEAMS_QUERY = gql`
query MyPersonalTeams {
  teams: myPersonalTeams {
    id
    name
  }
}
`;


function PersoSelectTargetsPage() {

  const [selectedCategories, setSelectedCategories] = React.useState<Challenge[]>([]);

  const { data: currentTeamsData, loading: currentTeamsLoading } = useQuery(PERSO_TEAMS_QUERY)
  const { data: challengeData, loading: loadingTeams } = useQuery(CHALLENGES_LIFE_QUERY)
  const navigate = useNavigate();

  const challenges = useMemo(() => {
      const t =  challengeData?.challenges?.map((team: any) => ({
        ...team,
        ...(persoChallenges as any)[team.name],
        teamName: team.name,
      }));

      return t;
  }, [challengeData])

  useEffect(() => {
    if (challenges && currentTeamsData?.teams) {
      const currentChallenges = challenges?.filter((c:Challenge) => currentTeamsData?.teams?.find((team:Team) => team.name === c.teamName)).map((c:Challenge) => ({ ...c, new: false }));
      setSelectedCategories(currentChallenges || []);
    }
  }, [currentTeamsData, challenges]);

  function categoryClicked(challenge: Challenge) {
    if (selectedCategories.includes(challenge)) {
      setSelectedCategories(selectedCategories.filter((c) => c.id !== challenge.id));
    } else {
      setSelectedCategories([...selectedCategories, challenge]);
    }
  }

  async function sendSelected() {
    if (!selectedCategories.length) return alert("You must select at least one challenge");

    const data = JSON.parse(sessionStorage.getItem('formValue') || "{}");
    data.challenges = selectedCategories;
    sessionStorage.setItem('formValue', JSON.stringify(data));

    navigate('/perso/assign');
  }
  
  return (
    <div className="w-full p-4">
      <div className="flex pt-3 pb-6">
      <h1 className="text-3xl font-bold font-sans flex-1 lg:pl-4">
          Personal goals: Pick your personal challenges
        </h1>

        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-3 sm:relative sm:bg-transparent sm:border-0">
          {selectedCategories.length < 1 ? (
            <span>{`Select at least one personal challenge to continue`}</span>
          ) : (
            <>
              <Button onClick={sendSelected} className="block lg:hidden">Continue to your goals</Button>
              <Button onClick={sendSelected} className="hidden lg:block">Continue</Button>
            </>
          )}
        </div>
      </div>

      {(loadingTeams || currentTeamsLoading) ? (
        <Alert display type="info">
          Loading categories...
        </Alert>
      ) : (
        <div className="lg:grid lg:grid-cols-4 space-y-2 gap-4 pb-16 lg:pb-6">
          {challenges?.length && challenges.map((challenge: Challenge) => {
            const selected = selectedCategories.find((c) => c.id === challenge.id);
            return (
              <div
                className={`${
                  selected ? "border-blue-400" : "border-white"
                } border-4 p-6 bg-white w-full shadow-sm rounded-lg items-center justify-center flex lg:flex-col cursor-pointer hover:shadow-lg`}
                onClick={() => categoryClicked(challenge)}
                key={`team-perso${challenge.id}`}
              >
                <img 
                  src={challenge.icon}
                  alt={challenge.name}
                  className="max-h-12 max-w-12 lg:max-h-28 lg:max-w-28" />
                <span className="text-lg text-gray-700 font-bold block p-4 text-center">
                  {challenge.name}
                </span>
                <span>
                  Def. KPI: <div className="inline-block">{challenge.kpiName}</div>
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PersoSelectTargetsPage;
