import * as React from "react";
import User from "../interfaces/User";
import { useNavigate } from "react-router";
import { decode } from "jwt-simple";
import { gql, useMutation, useApolloClient } from "@apollo/client";

const REFRESH = gql`
  mutation Mutation {
    refreshSession {
      session {
        jwt
      }
      user {
        id
        name
        picture
        isAdmin
        role
        company {
          name
          periodicity
          disabledFeatures
        }
        teams {
          id
          team {
            id
            name
            type
          }
          currentTarget {
            id
            type
            value
            logTotal
          }
          actions {
            id
            name
          }
          kpiName
          kpiUnit
          isManager
          inputType
        }
      }
      success
      message
    }
  }
`;

const AuthContext = React.createContext<{
  authed: boolean | null;
  user: User | null;
  login: () => Promise<boolean>;
  logout: () => Promise<boolean>;
}>({
  authed: false,
  user: null,
  login: () => {
    return Promise.resolve(false);
  },
  logout: () => {
    return Promise.resolve(false);
  },
});

function useAuth() {
  const [authed, setAuthed] = React.useState<boolean | null>(null);
  const [user, setUser] = React.useState<User | null>(null);
  const [refreshSession] = useMutation(REFRESH);
  const client = useApolloClient();
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token?.length) {
      try {
        const decoded = decode(token, "", true);
        if (decoded) {
          refreshSession().then(({ data }) => {
            if (data?.refreshSession?.success) {
              setUser(data.refreshSession.user);
              return setAuthed(true);
            }
            setAuthed(false);
          });
          return;
        }
      } catch (e) {
        console.warn("Invalid JWT token");
      }
    }
    setAuthed(false);
    return;
  }, []);

  return {
    authed,
    user,
    login(): Promise<boolean> {
      return new Promise((res) => {
        //        navigate('/login');
        res(true);
      });
    },
    logout(): Promise<boolean> {
      return new Promise((res) => {
        localStorage.removeItem('jwt');
        navigate("/login", { replace: true });
        client.resetStore();
        res(false);
      });
    },
  };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(AuthContext);
}
