import React, { useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Confetti from 'react-confetti'
import {
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from "react-share";
import { toBlob } from 'html-to-image';
import FileSaver from 'file-saver';

import Modal from "../components/Modal";
import useAuth from "../hooks/useAuth";
import useWindowSize from '../hooks/windowSize';

import { FaLinkedin, FaRegArrowAltCircleDown, FaSlack, FaTwitter, FaWhatsapp } from "react-icons/fa";
import OverrachievedComponent from "../components/OverrachievedComponent";
import Alert from "../components/Alert";


const PICTURE_MUTATION = gql`
  mutation UpdateOvrachievedImage($target: ID!, $picture: Upload!) {
    updateOverachievedImage(target: $target, picture: $picture) {
      success
      message
      url
    }
  }
`;

const TARGET_QUERY = gql`
  query LeaderBoardForTeamOfTarget($target: ID!) {
    target: targetById(id: $target) {
      id
      value
      publicToken
      logTotal
      teamMember {
        kpiName
        user {
          name
          picture
          role
        }
      }
    }
  }
`;

function OverachievedModal() {
  const { user } = useAuth();
  const { width, height } = useWindowSize();
  const params = useParams();
  const [ downloadData, setDownloadData ] = useState<Blob | null>();

  const { data, loading } = useQuery(TARGET_QUERY, {
    variables: { target: params.target },
  });

  const [ uploadPicture ] = useMutation(PICTURE_MUTATION);

  const url = useMemo(() => {
    const uri = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    if (data?.target) return `${uri}/overachieved/${data?.target.publicToken}`;
    return "";
  }, [data?.target]);

  useEffect(() => {
    // Ready to take screenshot ?
    if (data?.target) {
      // HTML 2 canvas to image => Convert div to image & upload it
      setTimeout(async () => {
        const node = document.getElementById('elementToShare');
        if (!node) return;

        const options = { cacheBust: true, backgroundColor: "#FFFFFF" };
        let dataUrl = await toBlob(node, options)
        setDownloadData(dataUrl);

        uploadPicture({
            variables: {
              target: params.target,
              picture: dataUrl,
            }
          }).catch(console.error)
      }, 1000);     
    }
  }, [data?.target]);

  const downloadImage = () => {
    if (!downloadData) return;
    FileSaver.saveAs(downloadData, 'overachieved.png');
  }

  if (loading) return <Alert type="info" display>Loading...</Alert>;

  if (!user || !data) return null;

  return (
    <Modal title="Wouaw, you overachieved, share it !">
      <Confetti
        width={width}
        height={height}
        recycle={false}
      />
      <div className="lg:m-4 lg:p-6">
        <OverrachievedComponent target={data?.target} id="elementToShare" />
      </div>

      <div className="lg:mx-4 mt-8 p-4 text-center overflow-scroll">
        {url && (
          <div className="text-5xl space-x-10 align-middle flex justify-center">
            <LinkedinShareButton url={url}><FaLinkedin color="#0A66C2" /></LinkedinShareButton>
            {/*<EmailShareButton url={url}><FaEnvelope  color="#DB4437"/></EmailShareButton>*/}
            <TwitterShareButton url={url}><FaTwitter color="#1DA1F2"/></TwitterShareButton>
            <button onClick={downloadImage}><FaSlack color="#ECB22E"/></button>
            <WorkplaceShareButton url={url}><WorkplaceIcon iconFillColor="#2E0078" bgStyle={{ fill: 'transparent' }} /></WorkplaceShareButton>
            <WhatsappShareButton url={url}><FaWhatsapp color="#25D366"/></WhatsappShareButton>
            <button onClick={downloadImage}><FaRegArrowAltCircleDown color="#DB4437"/></button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default OverachievedModal;
