import { gql, useQuery } from "@apollo/client";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";

import useAuth from "../hooks/useAuth";
import { formatForPeriodicity } from "../libs/formatFunctions";

import TargetBlockagePill from "./TargetBlockagePill";
import { getColor } from "./TeamDashComponent";

const TARGET_QUERY = gql`
  query teamMemberById($id: ID!) {
    teamMemberById(id: $id) {
      team {
        name
      }
      user {
        sheetURL
        id
        name
      }
    }

    targetBlockagesForTeamMember(teamMember: $id) {
      blockages {
        id
        solution {
          id
          name
        }
        reason {
          name
          id
        }
        id
        note
        target {
          id
          logTotal
          value
          time
          teamMember {
            id
            team {
              id
            }            
            user {
              id
            }
          }
        }
      }
    }
  }
`;

function getColorPie(value: number) {
  //value from 0 to 1
  var hue = (value * 120).toString(10);
  return `hsl(${hue},80%,65%)`;
}


function ObstaclesComponent({ teamMember }: { teamMember?: string }) {
  const { data, loading } = useQuery(TARGET_QUERY, {
    variables: { id: teamMember },
  });
  const { user } = useAuth();

  if (loading) return <p>Loading...</p>;
  if (!data) return null;

  return (
    <div className="relative">
      <h3 className="text-2xl text-center">Obstacles</h3>

      {!!data?.targetBlockagesForTeamMember?.blockages?.length ? (

      <table className="w-full p-3 table-fixed mt-3 shadow-md rounded-lg bg-white">
        <thead className="">
          <tr>
            <th className="text-left">Period</th>
            <th>Result</th>
            <th>Obstacle</th>
            <th>Solution</th>
          </tr>
        </thead>

        <tbody>
          {data?.targetBlockagesForTeamMember?.blockages?.map(
            (blockage: TargetIssue, index: number) => {
              const target = blockage.target as any;

              const done = target?.logTotal || 0;
              const percentage = Math.min(
                100,
                Math.round(Math.max(0, (100 * done) / (target?.value || 1)))
              );
              const dataIn = [
                { name: "Done", value: percentage },
                { name: "To Do", value: 100 - percentage },
              ];

              return (
                <tr>
                  <td>
                    <h3 className="text-lg font-medium text-left">
                      {formatForPeriodicity(blockage.target.time, user?.company.periodicity)}
                    </h3>
                  </td>
                  <td>
                    <ResponsiveContainer width="100%" height={100}>
                      <PieChart width={40} height={40}>
                        <Pie
                          data={dataIn}
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          innerRadius={35}
                          outerRadius={40}
                          startAngle={90}
                          endAngle={-270}
                          isAnimationActive={false}
                        >
                          {!!target && (
                            <Label
                              value={`${percentage}%`}
                              position="center"
                              fill={getColor(percentage)}
                            />
                          )}
                          <Cell fill={getColor(percentage)} />
                          <Cell fill="#DDDDDD" />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </td>

                  <td>
                    {!!blockage?.reason ? (
                      <TargetBlockagePill
                        color={getColorPie(index / data?.targetBlockagesForTeamMember?.blockages?.length)}
                        lastTarget={target?.id}
                        note={blockage.solution?.note}
                        canEdit={user?.isAdmin || target?.teamMember?.user?.id === user?.id}
                        className={"inline-block"}
                      >
                        {blockage.reason.name}
                      </TargetBlockagePill>
                    ) : (
                      <TargetBlockagePill notSet lastTarget={target?.id}
                      canEdit={user?.isAdmin || target?.teamMember?.user.id === user?.id}
                      className={"inline-block"}
                      >
                        No obstacle set
                      </TargetBlockagePill>
                    )}
                  </td>
                  <td>
                    {!!blockage?.reason ? (
                      <TargetBlockagePill color={getColorPie(index / data?.targetBlockagesForTeamMember?.blockages?.length)} 
                      className={"inline-block"}
                      >
                        {blockage.solution
                          ? blockage.solution.name
                          : "No default solution"}
                      </TargetBlockagePill>
                    ) : (
                      <TargetBlockagePill notSet
                      className={"inline-block"}
                      >
                        No focus set
                      </TargetBlockagePill>
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table> ) : (
        <div className="text-center p-8">This user didn't fill any obstacle yet.</div>
      )}
    </div>
  );
}

export default ObstaclesComponent;
