import React from "react";

import bgLeft from '../../assets/login-left.png';
import bgRight from '../../assets/login-right.png';
import logo from "../../assets/transparent.png";

interface LoginWrapperProps {
  children: React.ReactNode;
}

export default function LoginWrapper(props: LoginWrapperProps) {
  const { children } = props;

  return (
    <div className="absolute top-0 left-0 right-0 h-full flex">
      <div
        className="invisible md:visible md:flex-1 bg-cover bg-center-center"
        style={{ backgroundImage: `url(${bgLeft})` }}
      />
      <div className="bg-white p-8 lg:px-16 lg:py-24 w-full md:w-1/2 lg:w-1/3 justify-center items-center ">
        <img
          src={logo}
          className="rounded-full w-24 h-24 lg:mt-6 lg:mb-16 mx-auto"
          alt="Yood logo"
        />
        {children}
      </div>
      <div
        className="invisible md:visible md:flex-1 bg-cover bg-center-center"
        style={{ backgroundImage: `url(${bgRight})` }}
      />
    </div>
  );
}
