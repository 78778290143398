import React, { useMemo } from "react";
import { useParams } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import Creatable from "react-select/creatable";

import Alert from "../components/Alert";
import Button from "../components/Button";
import Modal from "../components/Modal";
import FormInput from "../components/FormInput";
import { Controller, useForm } from "react-hook-form";
import FormGenericInput from "../components/FormSelectableInput";

type FormValues = {
  name: string;
  logo: string;
  mainMetric: {
    value: string,
    label: string,
  };
};

const COMPANY_TEAM = gql`
  query company {
    company {
      id
      name
      logo
      mainMetric {
        id
      }
    }

    users {
      name
      teams(onlyPro: true) {
        kpiName
        kpiUnit
        currentTarget {
          value
          logTotal
        }
        team {
          name
          kpiName
          kpiUnit
        }
        id
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateCompany(
    $id: ID
    $company: CompanyInput!
  ) {
    updateCompany(
      id: $id
      company: $company
    ) {
      success
      message
    }
  }
`;

export default function AdminCompanyPage() {
  const params = useParams();

  const [success, setSuccess] = React.useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const id = params.id as ID;

  const { data: initialValues, loading } = useQuery(COMPANY_TEAM);

  const [updateTeam, { loading: updateLoading }] = useMutation(
    UPDATE_MUTATION,
    {
      refetchQueries: [COMPANY_TEAM, "company"],
    }
  );


  const actionsOptions = useMemo(() => {
    if (initialValues?.company?.mainMetric?.id) setValue("mainMetric", initialValues?.company?.mainMetric?.id)

    if (initialValues?.users) {
      return initialValues?.users.reduce((acc:[], user:any) => 
        [...acc, ...user?.teams?.map((tm: TeamMember) => ({
            value: tm.id,
            label: `${user.name} - ${tm.team.name}`,
          }))
        ],
        []);
    }

    return [];
  }, [initialValues, setValue]);


  async function onSubmit(data: FormValues) {
    try {
      const result = await updateTeam({
        variables: {
          id: id,
          company: {
            name: data.name,
            logo: data.logo,
            mainMetric: parseInt(data.mainMetric?.value, 10),
          },
        },
      });
      if (result.data?.updateTeam?.success) setSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  if (loading) return null;
  if (!initialValues) return null;

  return (
    <Modal title="Edit company">
      <Alert
        type="success"
        display={!!success}
        onDismiss={() => {
          setSuccess(false);
        }}
        autoDismiss={3000}
      >
        Team edited successfully !
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Company name"
          type="text"
          error={errors.name?.message}
          {...register("name", {
            required: true,
            value: initialValues?.company?.name,
          })}
        />

        <FormInput
          label="Logo URL"
          type="text"
          error={errors.logo?.message}
          {...register("logo", {
            value: initialValues?.company?.logo,
          })}
        />

        <FormGenericInput name="mainMetric" label="Main metric (for company performance)">
          <Controller
            name="mainMetric"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <Creatable
                  options={actionsOptions}
                  onChange={onChange}
                  onBlur={onBlur}
                  isSearchable
                />
              );
            }}
          />
        </FormGenericInput>

        {loading || updateLoading ? (
          <div>Loading...</div>
        ) : (
          <Button>Save changes to company</Button>
        )}
      </form>
    </Modal>
  );
}
