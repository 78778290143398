import { gql, useQuery } from "@apollo/client";
import Alert from "./Alert";

const TARGET_QUERY = gql`
  query teamMemberById($id: ID!) {
    teamMemberById(id: $id) {
      team {
        name
      }
      user {
        sheetURL
        id
        name
      }
    }
  }
`;

function GoogleSheetComponent({ teamMember }: { teamMember?: string }) {
  const { data, loading } = useQuery(TARGET_QUERY, {
    variables: { id: teamMember },
  });

  if (loading) return <p>Loading...</p>;
  if (!data) return null;

  return (
    <div className="relative">
      <h3 className="text-2xl text-center">{`Google sheet for ${data.teamMemberById.team.name}`}</h3>

      {!data.teamMemberById.user.sheetURL ? (
        <Alert
          display
          type="info"
        >{`No Google sheet defined for ${data.teamMemberById.user.name}.`}</Alert>
      ) : (
        <>
          <iframe
            title="Sheet for team"
            src={data.teamMemberById.user.sheetURL}
            className="w-full"
            style={{ minHeight: 550 }}
          />
          <a
            href={data.teamMemberById.user.sheetURL}
            target="_blank"
            rel="noreferrer"
          >
            Open in new Tab
          </a>
        </>
      )}
    </div>
  );
}

export default GoogleSheetComponent;
