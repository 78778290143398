import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from "react-share";

import { FaLinkedin, FaRegArrowAltCircleDown, FaSlack, FaTwitter, FaWhatsapp } from "react-icons/fa";
import Alert from "../components/Alert";
import moment from "moment";


const OVERACHIEVERS_QUERY = gql`
  query getOverachievers {
    targets: getOverachievers {
      id
      value
      publicToken
      logTotal
      overachievedPreview
      teamMember {
        kpiName
        user {
          name
          picture
          role
        }
      }
    }
  }
`;

function OverachieversPage() {
  const { data, loading } = useQuery(OVERACHIEVERS_QUERY);

  const handleDownload = (linkUrl: string) => {
    const link = document.createElement('a');
    link.href = `${linkUrl}?download=true`;
    link.download = "overachieved.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="w-full p-4 pt-6">
    <div className="flex">
      <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
        Overachievers
      </h1>
    </div>

    <div>
      {(loading || !data) && <Alert type="info" display>Loading overachievers...</Alert>}
      {data && !data?.targets?.length && (<Alert type="info" display>Nobody overachieved recently.</Alert>)}

      <div className="grid grid-cols-1 2xl:grid-cols-2 gap-6">
        {
          data?.targets?.filter((t:Target) => t.overachievedPreview.indexOf('dummy-pict') === -1).sort((a:Target, b:Target) => moment(a.time).isBefore(b.time) ? -1 : 1).map((target: Target) => {
            const url = `${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/overachieved/${target?.publicToken}`;
            return (
              <div className="flex items-center justify-evenly p-8">
                <Link to={`/overachieved/${target.id}`}><img src={target.overachievedPreview} alt="Overachieved shareable" /></Link>
                
                <div className="text-5xl align-middle text-center primary-box pb-4">
                  <h3 className="text-xl text-title font-bold font-sans flex-1 px-1 pb-1">
                    Share
                  </h3>
                  <div className="grid grid-cols-2 p-1 gap-2">
                    <LinkedinShareButton url={url}><FaLinkedin color="#0A66C2" size="auto" className="w-8" /></LinkedinShareButton>
                    <TwitterShareButton url={url}><FaTwitter color="#1DA1F2" size="auto" className="w-8" /></TwitterShareButton>
                    <WorkplaceShareButton url={url}><WorkplaceIcon iconFillColor="#2E0078" size="auto" className="w-8" bgStyle={{ fill: 'transparent' }} style={{ scale: '1.4' }} /></WorkplaceShareButton>
                    <WhatsappShareButton url={url}><FaWhatsapp color="#25D366" className="w-8" size="auto" /></WhatsappShareButton>
                    <a href={target.overachievedPreview} download onClick={() => handleDownload(target.overachievedPreview)}><FaSlack color="#ECB22E" size="auto" className="w-8" /></a>
                    <a href={target.overachievedPreview} download onClick={() => handleDownload(target.overachievedPreview)}><FaRegArrowAltCircleDown color="#DB4437" size="auto" className="w-8" /></a> 
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  </div>
  );
}

export default OverachieversPage;
