import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import Button from "../components/Button";
import addLogIcon from "../assets/icons/addLog.svg";
import TeamDashComponent from "../components/TeamDashComponent";
import TeamDashComponentForTeams from "../components/TeamDashComponentForTeams";
import TargetIssueModal from "./TargetIssueModal";
import DashGauge from "../components/DashGauge";
import RankingBar from "../components/RankingBar";
import OKRBar from "../components/OKRBar";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const RESULTS_QUERY = gql`
  query ResultsForTeam($team: ID, $before: DateTime, $after: DateTime, $nextMonth: DateTime, $prevMonth: DateTime) {
    company {
      name
      periodicity
      mainMetric {
        currentTarget {
          logTotal
          value
        }
        kpiName
        kpiUnit
        team {
          kpiName
          kpiUnit
        }
      }
    }
  
    teams(onlyPro: true) {
      id
      name
      members {
        id
        user {
          id
          name
          role
          picture
          deleted
        }
        kpiName
        isManager
        inputType
        targetSelectOptions {
          id
          textColor
          color
          text
        }
      }
    }

    resultsForTeam(team: $team, before: $before, after: $after) {
      targets {
        id
        logs {
          id
          action {
            name
          }
          value
        }
        logTotal
        time
        value
        teamMember {
          id
          user {
            id
            name
            picture
          }
          kpiName
          kpiUnit
          inputType
          targetSelectOptions {
            id
            textColor
            color
          }
        }
      }
      after
      before
    }

    resultsTotalForTeams(before: $nextMonth, after: $prevMonth, perso: false) {
      targets {
        id
        value
        time
        logTotal
        teamMember {
          team {
            name
            id
          }
          user {
            name
            id
          }
        }
        logs {
          id
          valueText
          notes
        }
      }
      after
      before
    }
  }
`;

function RightBar() {
  const [expanded, setExpanded] = useState(true);
  const { user } = useAuth();

  return (
    <div className='mt-0 mr-0 ml-6 relative hidden lg:block'>
      <OKRBar expanded={expanded} />
      {!!user?.teams[0]?.currentTarget && <RankingBar expanded={expanded} />}

      <button
          onClick={() => setExpanded(!expanded)}
          className="absolute -left-2 top-12 shadow-md bg-white p-3 rounded-full text-title"
        >
          {expanded ? <FaArrowRight /> : <FaArrowLeft />}
        </button>
    </div>
  );

}

function DashProPage() {
  const { data: teamsData, loading: teamLoading } =
    useQuery(RESULTS_QUERY, {
      variables: {
        nextMonth: moment().endOf('month'),
        prevMonth: moment().startOf('month'),
      }
    });
  const { user } = useAuth();
  const params = useParams();


  // Only keep pro teams
  const teams = useMemo(
    () => user?.teams.filter((tm: TeamMember) => tm.team?.type === ("pro").toUpperCase()) || [],
    [user]
  );

  const selectedTeam = useMemo<Team | undefined>(() => {
    if (params?.team && teamsData?.teams?.length) return teamsData?.teams.find((t: Team) => t.id === params.team);
    if (teamsData?.teams?.length === 1) return teamsData?.teams[0];

    return undefined;
  }, [teamsData?.teams, params]);

  return (
    <>
      <TargetIssueModal />
      <div className="w-full p-4">
        <div className="flex">
          <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
            Dashboard
          </h1>

          <Link to="/modal/logModal/pro">
            <Button variant="primary" className="flex" sizeVariant="big">
              <img
                src={addLogIcon}
                className="h-6 w-6 mr-2"
                alt="Add progress"
              />
              Update results
            </Button>
          </Link>
        </div>

        <div className="flex border-b mb-4 text-xl">
          <Link
            to="/"
            className={`border-b-4 py-2 mr-4 text-title border-title`}
          >
            Business
          </Link>
          <Link
            to="/perso/dash"
            className={`border-b-4 py-2 mr-4 text-text border-transparent`}
          >
            Life
          </Link>
        </div>

        {teamLoading ? (
          <div>Loading dashboard...</div>
        ) : (
          <div className="space-y-5">
            <div className="flex flex-row">
              <div className="flex flex-col flex-1">
                <div className="flex space-y-4 md:space-y-0 md:space-x-8 flex-col md:flex-row mb-6 mt-4">
                  {teamsData?.company?.mainMetric ? (
                    <DashGauge
                      value={teamsData?.company?.mainMetric?.currentTarget?.logTotal}
                      metric={teamsData?.company?.mainMetric?.kpiName || teamsData?.company?.mainMetric?.team.kpiName}
                      unit={teamsData?.company?.mainMetric?.kpiUnit || teamsData?.company?.mainMetric?.team.kpiUnit}
                      target={teamsData?.company?.mainMetric?.currentTarget?.value}
                      title="Company's Performance"
                      background="#e5eeff"
                    />
                    ) : (
                    <DashGauge
                      value={teamsData?.resultsTotalForTeams?.targets?.reduce((acc:number, t:Target) => acc + (t.logTotal || 0)/ (t.value || 1), 0)/(teamsData?.resultsTotalForTeams?.targets?.length || 1)}
                      metric=""
                      unit=""
                      title="Company's Performance"
                      background="#e5eeff"
                    />
                  )}
                  <DashGauge
                    value={teams[0]?.currentTarget?.logTotal}
                    metric={teams[0]?.kpiName}
                    target={teams[0]?.currentTarget?.value}
                    unit=""
                    title="My Performance"
                    background="#ffeddf"
                  />
                </div>

                {!!teamsData && (teamsData?.teams?.length > 1) && (
                  <TeamDashComponentForTeams
                    teams={teamsData?.teams}
                    targets={teamsData?.resultsTotalForTeams.targets}
                    before={teamsData?.resultsTotalForTeams.before}
                    after={teamsData?.resultsTotalForTeams.after}
                    periodicity={teamsData?.company?.periodicity}
                    loading={teamLoading}
                  />
                )}

                {!!teamsData?.resultsForTeam && (
                  <TeamDashComponent
                    team={selectedTeam}
                    periodicity={teamsData?.company?.periodicity}
                  />
                )}
              </div>
              
              <RightBar />
            </div>
          </div>
        )}
      </div>
    </>
  );
}


function DashProPagePlaceholder() {
  const navigate = useNavigate();

  function askMeeting() {
    window.open('https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3GItJ5XAHwW6FixWMNY1CWdaf0lNJORV1SC11bUT_37ezYqIi3ugLzj_GUl-xtmxsMz94DIpEr', '_blank', 'noreferrer');
  }

  useEffect(() => {
    if (sessionStorage.getItem('firstPage') !== 'false' && (document.location.href.indexOf('life') > -1)) navigate('/perso/dash', { replace: true });
  }, []);


  return (
    <>
      <TargetIssueModal />
      <div className="w-full p-4">
        <div className="flex">
          <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
            Dashboard
          </h1>

          <Link to="/modal/logModal/pro">
            <Button variant="primary" className="flex" sizeVariant="big">
              <img
                src={addLogIcon}
                className="h-6 w-6 mr-2"
                alt="Add progress"
              />
              Update results
            </Button>
          </Link>
        </div>

        <div className="flex border-b mb-4 text-xl">
          <Link
            to="/"
            className={`border-b-4 py-2 mr-4 text-title border-title`}
          >
            Business
          </Link>
          <Link
            to="/perso/dash"
            className={`border-b-4 py-2 mr-4 text-text border-transparent`}
          >
            Life
          </Link>
        </div>

          <div className="space-y-5 relative">
            <div className="primary-box absolute z-10 top-20 left-20 right-20 text-center">
              <h2>The business dashboard is included in business plans only.<br/>Please contact us for a demo and your setup.</h2>
              <Button onClick={askMeeting} className="m-5">Ask a meeting</Button>
            </div>
            <div className="flex flex-row"  style={{ filter: 'blur(3px)', pointerEvents: 'none' }}>
              <div className="flex flex-col flex-1">
                <div className="flex space-y-4 md:space-y-0 md:space-x-8 flex-col md:flex-row mb-6 mt-4">
                  <DashGauge
                    value={0.78}
                    metric="Financial situation"
                    unit=""
                    title="Company's Performance"
                    background="#e5eeff"
                  />

                  <DashGauge
                    value={104}
                    metric={"Inbound leads"}
                    target={123}
                    unit="Leads"
                    title="My Performance"
                    background="#ffeddf"
                  />
                </div>

{/*
                {!!teamsData && (teamsData?.teams?.length > 1) && (
                  <TeamDashComponentForTeams
                    teams={teamsData?.teams}
                    targets={teamsData?.resultsTotalForTeams.targets}
                    before={teamsData?.resultsTotalForTeams.before}
                    after={teamsData?.resultsTotalForTeams.after}
                    loading={teamLoading}
                  />
                )}

                {!!teamsData?.resultsForTeam && (
                  <TeamDashComponent
                    team={selectedTeam}
                  />
                )}
                */}
              </div>

              
              <RightBar />
            </div>
          </div>
      </div>
    </>
  );
}


export default (document.location.href.indexOf('life') > -1 ? DashProPagePlaceholder : DashProPage); 
