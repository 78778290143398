import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

const IMPORT_MUTATION = gql`
  mutation ImportMutation($file: Upload!) {
    importTeamFile(file: $file) {
      success
      code
      message
    }
  }
`;

const processCSV = (str: string, delim = ",") => {
  const headers = str.slice(0, str.indexOf("\n")).split(delim);
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  const newArray = rows.map((row) => {
    const values = row.split(delim);
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = values[i].replace(/"/g, "").replace(/\r/g, "").trim();
      return obj;
    }, {} as any);
    return eachObject;
  });

  return newArray;
};

function AdminImportPage() {
  const { user } = useAuth();

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [users, setUsers] = useState<any[]>();
  const [teams, setTeams] = useState<any[]>();

  const [importMutation] = useMutation(IMPORT_MUTATION);

  const sendToBackend = async () => {
    try {
      const res = await importMutation({
        variables: {
          file: csvFile,
        },
      });

      if (res.data.importTeamFile.success) {
        setUsers([]);
        setTeams([]);

        alert("Successfully imported");
      } else {
        alert(res.data.importTeamFile.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submit = (e: any) => {
    e.preventDefault();

    if (!csvFile) return;

    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e: any) {
      const text = e.target.result;
      const array = processCSV(text);

      const usersToCheck = array.map((line) => {
        const actionsFields = Object.keys(line).filter(
          (n) => n.indexOf("action") === 0
        );
        const actions = actionsFields.map((a) => line[a]);

        const targetsFields = Object.keys(line).filter(
          (n) => n.indexOf("month") === 0
        );
        const targets = targetsFields.map((a) => line[a]);

        const cumulFields = Object.keys(line).filter(
          (n) => n.indexOf("cumulMonth") === 0
        );
        const cumuls = cumulFields.map((a) => line[a]);

        const user = {
          email: line.email,
          name: line.name,
          role: line.role,
          googleSheet: line.googleSheet,
          teamName: line.team,

          actions: [...new Set(actions)],

          kpiUnit: line.kpiUnit,
          kpiName: line.kpiName,
          countsForTeam: line.countsForTeam === "TRUE",
          isTeamLeader: line.isTeamLeader === "TRUE",

          targets,
          cumuls,
        };

        return user;
      });

      setUsers(usersToCheck);

      const teamsToCheck = usersToCheck.reduce((acc, user) => {
        const index = acc.findIndex((t: any) => t.name === user.teamName);

        if (index === -1)
          acc.push({
            name: user.teamName,
            members: [{ name: user.name, email: user.email }],
          });
        else acc[index].members.push({ name: user.name, email: user.email });

        return acc;
      }, [] as any);

      setTeams(teamsToCheck);
    };

    reader.readAsText(file);

    return false;
  };

  if (!user)
    return (
      <Alert type="error" display>
        You are not logged in
      </Alert>
    );

  return (
    <div className="w-full p-4">
      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <h1 className="text-2xl pb-4 font-bold font-sans">
          Import CSV to create teams, users and actions
        </h1>

        <form id="csv-form" onSubmit={submit}>
          <input
            type="file"
            accept=".csv"
            id="csvFile"
            onChange={(e) => {
              setCsvFile(e?.target?.files ? e.target.files[0] : null);
            }}
          />
          <Button type="submit">Analyse file</Button>
        </form>

        {(users?.length && teams?.length) && (
          <Alert
            type="success"
            display
          >{`${users.length} users and ${teams.length} teams found in the file. Please check them bellow`}</Alert>
        )}

        {users && (
          <div className="mt-8">
            <h2 className="font-bold text-xl">Users</h2>
            <div>
              {users.map((user) => (
                <ul className="text-sm mt-4">
                  <li>
                    <strong>Name: </strong>
                    {user.name}
                  </li>
                  <li>
                    <strong>Email: </strong>
                    {user.email}
                  </li>
                  <li>
                    <strong>Team name: </strong>
                    {user.teamName}
                  </li>
                  <li>
                    <strong>actions: </strong>
                    {user.actions.join(", ")}
                  </li>
                  <li>
                    <strong>Objectives: </strong>
                    {user.targets.join(", ")}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        )}

        {teams && (
          <div className="mt-8">
            <h2 className="font-bold text-xl">Teams</h2>
            <div>
              {teams.map((team) => (
                <ul className="text-sm mt-4">
                  <li>
                    <strong>Name: </strong>
                    {team.name}
                  </li>
                  <li>
                    <strong>Users: </strong>
                    {team.members.map((m: any) => m.name).join(", ")}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        )}

        {users && teams && (
          <div className="mt-8">
            <Button onClick={sendToBackend}>Start import</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminImportPage;
