import { gql, useQuery, useMutation } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { FaPlus, FaTrash } from "react-icons/fa";
import IconButton from "../components/IconButton";
import Log from "../interfaces/Log";
import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import FormInput from "../components/FormInput";
import FormGenericInput from "../components/FormSelectableInput";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useMemo } from "react";
import moment from "moment";
import { formatForPeriodicity } from "../libs/formatFunctions";

type FormValues = {
  value: string;
  valueText: string;
  target: { value: string; label: string };
  action: ID;
};

export const GET_USER_QUERY = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      name
      picture
      role
      email
      isAdmin

      company {
        periodicity
      }

      logs {
        id
        value
        valueText
        time
        action {
          name
        }
        valueSelected {
          id
          color
          textColor
          text
        }
      }

      teams {
        id
        kpiName
        inputType
        team {
          id
          name
        }
        actions {
          name
          id
        }
        currentTarget {
          id
        }
        targets {
          time
          value
          teamMember {
            actions {
              name
              id
            }
          }
          id
        }
      }
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation DeleteLog($deleteLogId: ID!) {
    deleteLog(id: $deleteLogId) {
      success
    }
  }
`;

const ADMINLOG = gql`
  mutation Mutation(
    $action: ID!
    $value: Float
    $valueText: String
    $time: DateTime!
    $target: ID
    $userId: ID
  ) {
    logNewAction(
      action: $action
      value: $value
      valueText: $valueText
      time: $time
      target: $target
      userId: $userId
    ) {
      success
      message
    }
  }
`;
function filterDuplicates(arr: TeamMember[]) {
  const uniqueIds: ID[] = [];
  return arr.filter((item: TeamMember) => {
    const id = item.team.id;
    if (uniqueIds.indexOf(id) === -1) {
      uniqueIds.push(id);
      return true;
    }
    return false;
  });
}

function MyProfilePage() {
  const { user } = useAuth();
  const params = useParams();
  const {
    register,
    control,
    watch,
    handleSubmit,
    // formState: { errors },
  } = useForm<FormValues>();

  const { data: userData, loading: UserLoading } = useQuery(GET_USER_QUERY, {
    variables: { id: params.id },
  });

  const [logAction] = useMutation(ADMINLOG, {
    refetchQueries: [GET_USER_QUERY, "getUserById"],
  });

  const [deleteLog] = useMutation(DELETE_MUTATION, {
    refetchQueries: [GET_USER_QUERY, "getUserById"],
  });

  const targetsOptions = useMemo(() => {
    if (userData?.getUserById?.teams) {
      const array: any[] = [];
      const periodicity = (userData?.getUserById?.company?.periodicity || 'month') as moment.unitOfTime.DurationAs;
    
      userData?.getUserById?.teams.forEach((team: any) => {
        if (!team?.targets) return;
        if (team?.inputType !== 'NUMERIC') return;

        team.targets.forEach((target: any) => {
          if (!moment(target.time).add(3, periodicity).isAfter()) return;
          array.push({
            value: target.id,
            label: `${formatForPeriodicity(target.time, periodicity)}: ${team.team.name} - ${team.kpiName}`,
            time: target.time,
          });
        });
      });

      return array.sort((b:any, a:any) => moment(a.time).isBefore(b.time) ? 1 : -1);
    }

    return [];
  }, [userData]);


  const actionsOptions = useMemo(() => {
    const selectedTarget = watch("target");

    if (userData?.getUserById?.teams && selectedTarget) {
      const array: any[] = [];

      userData?.getUserById?.teams.forEach((team: TeamMember) => {
        const target = team.targets.find(
          (target: any) => selectedTarget.value === target.id
        );
        target?.teamMember?.actions.forEach((action: Action) => {
          array.push({ value: action.id, label: action.name });
        });
      });
      return array;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, watch("target")]);

  const inputType = useMemo(() => {
    const selectedTarget = watch("target");
    if (!selectedTarget || !userData?.getUserById?.teams?.length) return 'NUMERIC';

    const teamMember = userData?.getUserById?.teams?.find((teamMember: TeamMember) => teamMember.targets.find((target: Target) => target.id === selectedTarget.value));
    return teamMember.inputType;
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.getUserById?.teams, watch("target")])

  function onSubmit(data: any) {
    logAction({
      variables: {
        action: data.action.value,
        value: parseFloat(data.value),
        valueText: data.valueText,
        target: data.target.value,
        time: moment(data.target.time).day(moment().day()).toDate(), // Set day to today
        userId: params.id,
      },
    });
  }

  function askDelete(log: Log) {
    if (window.confirm(`Are you sure you want to delete this log?`)) {
      deleteLog({ variables: { deleteLogId: log.id } });
    }
  }

  if (UserLoading) return <div>Loading...</div>;

  if (!user)
    return (
      <Alert type="error" display>
        You are not logged in
      </Alert>
    );

  if (!userData?.getUserById)
    return (
      <Alert type="error" display>
        User not found !
      </Alert>
    );

  return (
    <div className="w-full p-4">
      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <div className="flex px-8">
          <img
            src={userData?.getUserById?.picture}
            className="w-32 h-32 rounded-full border-2 border-white"
            alt={`Profile of ${userData?.getUserById.name}`}
          />
          <div className="px-8">
            <h1 className="text-3xl pb-4 font-bold font-sans">
              {userData?.getUserById.name}
            </h1>
            <h2 className="text-xl pb-4 font-sans">
              {(userData?.getUserById?.teams?.length > 1) ? <span>Teams: </span> : null}
              {filterDuplicates(userData?.getUserById?.teams || [])?.map((member: TeamMember, i: number) => (<Link to={`/${member.team.id}`}>{`${i > 0 ? ", " : ""}${member.team.name}`}</Link>))}
            </h2>
            <h2 className="text-xl pb-4 font-sans">
              {userData?.getUserById.role}
              {userData?.getUserById.isAdmin && <em> - Admin</em>}
            </h2>
          </div>
          <div></div>
        </div>
      </div>

      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <h1 className="text-2xl pb-4 font-bold font-sans">Logs (actions)</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="table-auto w-full">
            <thead>
              <tr className="text-left text-lg">
                <th className="pb-5 pt-3">Time</th>
                <th className="pb-5 pt-3">Action</th>
                <th className="pb-5 pt-3" colSpan={2}>
                  Value
                </th>
              </tr>

              {user.isAdmin && (
                <tr className="text-left">
                  <th className="pb-5 pt-3 text-sm font-normal">
                    <FormGenericInput name="target" label="Target" nolabel>
                      <Controller
                        name="target"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <Select
                              options={targetsOptions}
                              onChange={(option: any) => onChange(option)}
                              onBlur={onBlur}
                              value={value}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  padding: 0,
                                  borderColor: "rgba(24, 83, 115, 1)",
                                }),
                              }}
                            />
                          );
                        }}
                      />
                    </FormGenericInput>
                  </th>
                  <th className="pb-5 pt-3 text-sm font-normal">
                    <FormGenericInput name="user" label="User" nolabel>
                      <Controller
                        name="action"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <Select
                              options={actionsOptions}
                              onChange={(option: any) => onChange(option)}
                              onBlur={onBlur}
                              value={value}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  padding: 0,
                                  borderColor: "rgba(24, 83, 115, 1)",
                                }),
                              }}
                            />
                          );
                        }}
                      />
                    </FormGenericInput>
                  </th>
                  <th className="pb-5">
                    { inputType === 'NUMERIC' && (
                      <FormInput
                        label="Value"
                        nolabel
                        type="number"
                        {...register("value", {
                          required: true,
                        })}
                      />
                    )}
                    { inputType === 'TEXT' && (
                      <FormInput
                        label="Value"
                        nolabel
                        type="text"
                        {...register("valueText", {
                          required: true,
                        })}
                      />
                    )}
                  </th>
                  <th className="pb-5 pt-0 text-right">
                    <IconButton
                      icon={FaPlus}
                      style={{ marginTop: -3 }}
                    />
                  </th>
                </tr>
              )}
            </thead>

            <tbody>
              {userData?.getUserById.logs
                .sort((a: Log, b: Log) =>
                  moment(a.time).isAfter(b.time) ? -1 : 1
                )
                .map((log: Log) => {
                  let value = `${log.value}`;
                  if (log.valueText && log.valueText !== 'undefined') value = log.valueText;
                  if (log.valueSelected) value = log.valueSelected.text;

                  return (
                    <tr className="text-left" key={`mylogs-${log.id}`}>
                      <td className="font-bold pb-3">
                        {userData?.getUserById.id === user.id || user.isAdmin ? (
                          <Link to={`/modal/editLog/${log.id}`}>
                            {formatForPeriodicity(log.time, (userData?.getUserById?.company?.periodicity || 'month') as moment.unitOfTime.DurationAs)}
                          </Link>
                        ) : (
                          formatForPeriodicity(log.time, (userData?.getUserById?.company?.periodicity || 'month') as moment.unitOfTime.DurationAs)
                        )}
                      </td>
                      <td className="pb-3">{log.action?.name || ''}</td>
                      <td className="font-bold pb-3 overflow-hidden" style={{maxWidth: 400}}>{value}</td>
                      <td className="pb-3 text-right">
                      {user.isAdmin && (<IconButton
                          icon={FaTrash}
                          onClick={() => askDelete(log)}
                        />)}
                      </td>
                    </tr>
                  ); 
                }
                )}
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}

export default MyProfilePage;
