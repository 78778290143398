import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import PrivateRoute from "./components/PrivateRoute";

import DashPersoPage from "./pages/DashPersoPage";
import DashProPage from "./pages/DashProPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";

import LogModal from "./pages/LogModal";
import LogEditModal from "./pages/LogEditModal";
import MyLogsPage from "./pages/MyLogsPage";
import MyProfilePage from "./pages/MyProfilePage";
import ChangePasswordModal from "./pages/ChangePasswordModal";
import TargetLogsModal from "./pages/TargetLogsModal";
import AdminIndexPage from "./pages/AdminIndexPage";
import AdminUsersPage from "./pages/AdminUsersPage";
import UserEditModal from "./pages/UserEditModal";
import UserEditTargetsModal from "./pages/UserEditTargetsModal";
import AdminTeamsPage from "./pages/AdminTeamsPage";
import TeamEditModal from "./pages/TeamEditModal";
import AdminTeamMembersPage from "./pages/AdminTeamMembersPage";
import TeamMemberEditModal from "./pages/TeamMemberEditModal";
import AdminImportPage from "./pages/AdminImportPage";
import PersoSelectTargetsPage from "./pages/PersoSelectTargetsPage";
import DashTargetIssuePage from "./pages/DashTargetIssuePage";
import AssignObstacleModal from "./pages/AssignObstacleModal";
import useAnalytics from "./hooks/analytics";
import LeaderboardForTarget from "./pages/LeaderboardForTarget";
import OverachievedModal from "./pages/OverachievedModal";

import ResetPasswordPage from "./pages/ResetPassword";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PersoAssignTargetsPage from "./pages/PersoAssignTargetsPage";
import SyncWatchModal from "./pages/SyncWatchModal";
import PortfolioPage from "./pages/PortfolioPage";
import SoonModal from "./pages/SoonModal";
import AdminCompanyPage from "./pages/AdminCompanyPage";
import UserEditTargetsPersoModal from "./pages/UserEditTargetsPersoModal";
import GlobalRankingPage from "./pages/GlobalRankingPage";
import PEReportPage from "./pages/PEReportPage";
import GenReportPage from "./pages/GenReportPage";
import ObjectivesPage from "./pages/ObjectivesPages";
import AddObjectiveModal from "./pages/AddObjectiveModal";
import ReviewObjectiveModal from "./pages/ReviewObjectiveModal";
import OverachieversPage from "./pages/OverachieversPage";
import OverachievedPublic from "./pages/OverachievedPublic";
import AutoLoginPage from "./pages/AutoLoginPage";
import ObjectivesKanbanPage from "./pages/ObjectivesKanbanPages";
import { useEffect } from "react";

function App() {
  useAnalytics();

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('firstPage', 'false'), 1000);
  }, []);

  return (
    <div className="bg-gray-50 text-gray-500 absolute left-0 top-0 right-0 min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yood - Performance management</title>
        <link rel="canonical" href="https://app.yood.co" />
      </Helmet>

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/autologin" element={<AutoLoginPage />} />

        <Route path="/forgot" element={<ForgotPasswordPage />} />
        <Route path="/reset/:token" element={<ResetPasswordPage />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/soon" element={<SoonModal />} />

          <Route path="/dash/perso" element={<DashPersoPage />} />

          <Route path="/dash/pro/:team" element={<DashProPage />} />
          <Route path="/dash/pro" element={<DashProPage />} />
          <Route path="/dash/:companyId" element={<DashProPage />} />

          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/ranking" element={<GlobalRankingPage />} />

          <Route path="/my/logs" element={<MyLogsPage />} />
          <Route path="/blockages" element={<DashTargetIssuePage />} />
          <Route path="/objectives" element={<ObjectivesPage />} />
          <Route path="/objectives/kanban" element={<ObjectivesKanbanPage />} />

          <Route path="/objectives/add" element={<AddObjectiveModal />} />
          <Route path="/objectives/add/:user/:team" element={<AddObjectiveModal />} />
          <Route path="/objectives/edit/:user/:team/:id" element={<AddObjectiveModal />} />
          <Route path="/objectives/review/:id" element={<ReviewObjectiveModal />} />

          <Route path="/overachievers" element={<OverachieversPage />} />

          <Route
            path="/leaderboard/:target"
            element={<LeaderboardForTarget />}
          />
          <Route path="/overachieved/:target" element={<OverachievedModal />} />

          {/* Profile pages */}
          <Route path="/my/profile" element={<MyProfilePage />} />
          <Route path="/profile/:id" element={<ProfilePage />} />

          {/* Admin pages (not modals) */}
          <Route path="/admin">
            <Route path="users" element={<AdminUsersPage />} />
            <Route path="teams" element={<AdminTeamsPage />} />
            <Route path="company" element={<AdminCompanyPage />} />
            <Route path="import" element={<AdminImportPage />} />
            <Route path="" element={<AdminIndexPage />} />
          </Route>

          <Route path="/perso">
            <Route path="dash" element={<DashPersoPage />} />
            <Route path="define" element={<PersoSelectTargetsPage />} />
            <Route path="assign" element={<PersoAssignTargetsPage />} />
            <Route path="editTarget/:id" element={<UserEditTargetsPersoModal />} />

            <Route path="sync" element={<SyncWatchModal />} />
          </Route>

          <Route path="/modal/logModal" element={<LogModal />} />
          <Route path="/modal/logModal/:type" element={<LogModal />} />

          <Route
            path="/modal/changePassword"
            element={<ChangePasswordModal />}
          />

          <Route path="/modal/editLog/:id" element={<LogEditModal />} />
          <Route
            path="/modal/targetLogs/:teamMember/:target"
            element={<TargetLogsModal />}
          />
          <Route
            path="/modal/editTargets/:id"
            element={<UserEditTargetsModal />}
          />

          <Route
            path="/modal/assignObstacle/:target"
            element={<AssignObstacleModal />}
          />

          <Route path="/modal/editUser">
            <Route path=":id" element={<UserEditModal />} />
            <Route path="" element={<UserEditModal />} />
          </Route>

          <Route path="/modal/editTeam">
            <Route path="members/:id" element={<AdminTeamMembersPage />} />
            <Route path=":id" element={<TeamEditModal />} />
            <Route path="" element={<TeamEditModal />} />
          </Route>

          <Route path="/modal/teamMember">
            <Route path=":teamId/:id" element={<TeamMemberEditModal />} />
            <Route path=":teamId" element={<TeamMemberEditModal />} />
          </Route>
          
          <Route path="/" element={<DashProPage />} />
          <Route path="/:team" element={<DashProPage />} />

          <Route path="/genreport/" element={<GenReportPage />} />
        </Route>

        <Route path="/report/:company" element={<PEReportPage />} />
        <Route path="/public/overachieved/:token" element={<OverachievedPublic />} />

      </Routes>
    </div>
  );
}

export default App;
