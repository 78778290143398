import { FaCommentDots, FaPencilAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface TargetBlockagePillProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  color?: string;

  notSet?: boolean;
  index?: number;

  canEdit?: boolean;
  lastTarget?: ID;
  note?: string;
};



// Pill (Colored background)
function TargetBlockagePill(props: TargetBlockagePillProps) {
  const { children, color, className, notSet, index, canEdit, lastTarget, note, ...rest } = props;
  const navigate = useNavigate();

  function openAssignModal() {
    navigate(`/modal/assignObstacle/${lastTarget}`);
  }

  return (
    <div className={`px-2 py-1 relative  ${className}`} {...rest}>
      {!!note?.length && <span title={note} className="absolute -top-2 -right-2 bg-gray-400 text-white rounded-full cursor-help p-1"><FaCommentDots /></span>}
      {children} 
      {canEdit && <FaPencilAlt className='text-title inline-block mx-3 text-sm cursor-pointer' onClick={openAssignModal}/> }
    </div>
  );
}

export default TargetBlockagePill;


/*
// !!! for priority


export default function TargetBlockagePill(props: TargetBlockagePillProps) {
  const { children, color, className, notSet, index, ...rest } = props;

  const priority = "!".repeat(Math.max(0, 3 - (index || 0)));

  return (
    <div className={`px-2 py-1 rounded-md ${className}`} {...rest}>{children} <span  style={{ color }} className="font-bold">{!notSet && priority}</span></div>
  );
}



export default function TargetBlockagePill(props: TargetBlockagePillProps) {
  const { children, color, className, notSet, index, ...rest } = props;

  const priority = "⬤";

  return (
    <div className={`px-2 py-1 rounded-md ${className}`} {...rest}>{children} <span  style={{ color }} className="font-bold">{!notSet && priority}</span></div>
  );
}
*/