interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "danger" | 'outlined' | 'yellow' | 'noborder';
  sizeVariant?: "big" | "small"
};

export default function Button(props: ButtonProps) {
  const { children, className, sizeVariant, variant, ...rest } = props;

  let colors = 'border-transparent text-white bg-primary hover:bg-blue-900 disabled:bg-blue-100';

  switch (variant) {
    case 'danger':
      colors = 'border border-transparent text-white bg-red-500 hover:bg-red-700 disabled:bg-red-100'; 
      break;

    case 'outlined':
      colors = 'border border-primary text-primary hover:bg-blue-900 hover:text-white disabled:border-blue-100';
      break;

    case 'noborder':
      colors = 'border-0 text-primary hover:text-blue-400';
      break;
  
    case 'yellow':
      colors = 'border border-transparent text-white bg-yellow hover:bg-yellow-700'; 
      break;

    default:
      break;
  }

  let size = '';
  switch (sizeVariant) {
    case 'big':
      size = 'text-lg';
      break;
    case 'small':
      size = 'text-xs';
      break;
  }


  return (
    <button
      className={`${colors} ${size} py-2 px-4 inser box-border font-bold rounded focus:outline-none focus:shadow-outline ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
