import networkingIcon from '../assets/perso-categories/networking.png'
import businessIcon from '../assets/perso-categories/business.png'
import entrepreneurshipIcon from '../assets/perso-categories/entrepreneurship.png'
import investmentIcon from '../assets/perso-categories/investment.png'
import coachingIcon from '../assets/perso-categories/coaching.png'
import learningIcon from '../assets/perso-categories/learning.png'
import familyIcon from '../assets/perso-categories/family.png'
import healthIcon from '../assets/perso-categories/health.png'
import travelIcon from '../assets/perso-categories/travels.png'
import artIcon from '../assets/perso-categories/art.png'



const persoChallenges = {
    'Networking': { icon: networkingIcon, periodicity: 'month', global: false, name: 'Networking', verb: 'meet', value: 5, alternativeKpi: ['# Touchpoints'], periodicities: ['week', 'month', 'year'] },
    'Business': { icon: businessIcon, periodicity: 'month', global: false, name: 'Business', verb: 'generate', value: 20, alternativeKpi: [], periodicities: ['week', 'month', 'year'] },
    'Entrepreneurship': { icon: entrepreneurshipIcon, periodicity: 'month', global: false, name: 'Entrepreneurship', verb: 'acquire', value: 20, alternativeKpi: ['# Users'], periodicities: ['week', 'month', 'year'] },
    'Finance': { icon: investmentIcon, periodicity: 'month', global: false, name: 'Finance', verb: 'invest',  value: 500, alternativeKpi: ['$ Saved'], periodicities: ['week', 'month', 'year'] },
    'Investment': { icon: investmentIcon, periodicity: 'month', global: false, name: 'Investment', verb: 'invest',  value: 500, alternativeKpi: ['$ Saved'], periodicities: ['week', 'month', 'year'] },
    'Coaching': { icon: coachingIcon, periodicity: 'month', global: false, name: 'Coaching', verb: 'help',  value: 5, alternativeKpi: ['# Sessions'], periodicities: ['week', 'month', 'year'] },
    'Learning': { icon: learningIcon, periodicity: 'year', global: true, name: 'Learning', verb: 'learn',  value: 20, alternativeKpi: ['# Modules'], periodicities: ['week', 'month', 'year'] },
    'Family': { icon: familyIcon, periodicity: 'week', global: false, name: 'Family', verb: 'experience',  value: 2, alternativeKpi: ['# Touchpoints'], periodicities: ['week', 'month', 'year'] },
    'Health': { icon: healthIcon, periodicity: 'week', global: false, name: 'Health', verb: 'complete',  value: 4, alternativeKpi: ['# Hours'], periodicities: ['week', 'month', 'year'] },
    'Travels': { icon: travelIcon, periodicity: 'year', global: true, name: 'Travels', verb: 'do',  value: 5, alternativeKpi: ['# Journeys'], periodicities: ['week', 'month', 'year']},
    'Art': { icon: artIcon, periodicity: 'year', global: true, name: 'Art', verb: 'make', value: 5, alternativeKpi: ['# Practice hours', '# Lessons'], periodicities: ['week', 'month', 'year'] },
};

export default persoChallenges;