import { useEffect, useState } from "react";

import { gql, useMutation } from "@apollo/client";

import { FaGripVertical, FaPlusCircle, FaStar, FaTrashAlt } from "react-icons/fa";

import './OKRKanbanCard.css'
import Donut from "./Donut";
import useAuth from "../hooks/useAuth";


const OBJECTIVE_ACTION = gql`
  mutation AddObjective(
    $text: String!
    $stateId: ID
    $type: String!
    $primary: Boolean
    $forUser: ID
    $forTeam: ID
    $id: ID
    $deleted: Boolean
  ) {
    addObjective(
      id: $id
      text: $text
      stateId: $stateId
      type: $type
      primary: $primary
      forUser: $forUser
      forTeam: $forTeam
      deleted: $deleted
    ) {
      success
      created
      message
      id
    }
  }
`;

export default function OKRKanbanCard({ create, teamMember, okr: okrP, state, update, handleProps }: any) {
  const { user } = useAuth();
  const [addObjective] = useMutation(OBJECTIVE_ACTION);
  const [okr, setOkr] = useState<OKR>(okrP || {
    primary: false,
    text: "",
    type: "user",
  });

  useEffect(() => {
    setOkr(okrP || {
      primary: false,
      text: "",
      type: "user",
    });
  }, [okrP]);

  async function updateField(field:string, value:any) {
    const okrT = okr as any;
    okrT[field] = value;

    await addObjective({
      variables: {
        ...okr,
        text: okrT.text,
        type: "user",
        stateId: state,
        primary: okrT.primary,
        forUser: user?.id,
        deleted: okrT.deleted,
        id: okrT.id,
      },
    })
    .then(async () => {
      if (create) {
        setOkr({
          primary: false,
          text: "",
          type: "user",
        });
      }

      return update && update();
    })
    .catch((e) => alert(e.message));
  }

  function deleteObj() {
    if (window.confirm("Are you sure you want to delete this objective ?")) updateField("deleted", true);
  }

  return (
    <div
      className="my-5 rounded-md w-72 shadow-sm bg-white bg-opacity-90 group"
			key={`kanbanCard-${okr.id}-${teamMember?.id}`}
			id={`kanbanCard-${okr.id}-${teamMember?.id}`}
    >
      {!!teamMember && (<div className="items-center mb-3 flex px-4 py-2">
        <img
          src={teamMember.user.picture}
          className="w-8 h-8 mb-2 m-auto rounded-full text-xs"
          alt={teamMember.user.name}
        />
        <div className="ml-5 flex-1">
          <h3 className="font-medium">{teamMember.user.name}</h3>
          <div className="text-sm">{teamMember.user.role}</div>
        </div>

        <Donut target={teamMember.currentTarget} size={20} />
      </div>)}
      {!!create && (
        <div className="items-center flex px-4 py-2">
          <FaPlusCircle className="w-8 h-8 mb-2 m-auto rounded-full text-xs text-gray-400"/> 
          <div className="ml-5 flex-1">
            <h3 className="font-medium">Create new card</h3>
            <div className="text-sm">Just type in</div>
          </div>
        </div>
      )}
      <div
        className={`cursor-text px-4 py-0 ${okr.primary ? "font-semibold" : ""}`}
        onBlur={(event) => updateField('text', event.currentTarget.textContent)} 
        contentEditable
        placeholder="Write your objective"
        suppressContentEditableWarning>
        {okr.text}
      </div>

      <div className="flex justify-end px-4 py-2 w-full">
        <div className="flex-1">
          <FaStar
            className={`cursor-pointer inline mr-2 ${
              okr?.primary
                ? "visible text-orange-300"
                : "invisible text-gray-300"
            } group-hover:visible`}
            title="Primary objective"
            onClick={() => updateField('primary', !okr.primary)}
          />
        </div>

        <div {...handleProps} className="flex space-x-3 invisible group-hover:visible">
            <FaTrashAlt
              className="cursor-pointer text-xl text-gray-300 hover:text-gray-400"
              onClick={deleteObj}
            />          
            <FaGripVertical className="cursor-grab text-xl text-gray-300 hover:text-gray-400"/>
        </div>
      </div>
    </div>
  );
}
