import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import { FaDownload } from "react-icons/fa";
import Button from "../components/Button";
import PEReportPage from "./PEReportPage";


const SEND_MUT = gql`
  mutation ResultsForTeam($time: DateTime!, $email: String) {
    url: downloadPEReport(time: $time, email: $email)
  }
`;

function GenReportPage() {
  const [getUrl, { loading: sending }] = useMutation(SEND_MUT);

  const time = moment();

  async function openModal() {
    const url = await getUrl({
      variables: {
        time: time.toDate(),
        email: '',
      },
    });

    const link = document.createElement("a");
    link.download = "yood-report.pdf";
    link.href = url.data.url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    return false;
  }


  return (
    <>
      <div className="flex-col relative">
        <div className="absolute top-6 right-0  border mr-4 ">
          <Button variant="primary" className="flex items-baseline" type="button" onClick={openModal} disabled={sending}>
            <FaDownload className="mr-4"/>
            {sending ? 'Downoading...' : 'Download report in PDF'}
          </Button>
        </div>
        <PEReportPage time={time.toISOString()} company={2} hideWatermark />
      </div>
    </>
  );
}

export default GenReportPage;
