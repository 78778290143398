import { gql, useQuery } from "@apollo/client";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import addLogIcon from "../assets/icons/addLog.svg";
import syncIcon from "../assets/icons/sync.svg";

import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from "recharts";
import { getColor } from "../components/TeamDashComponent";
import moment from "moment";
import { useMemo } from "react";
import { FaEllipsisV, FaPen, FaPenAlt, FaPlus } from "react-icons/fa";
import PersoEvolutionComponent from "../components/PersoEvolutionComponent";

const RESULTS_QUERY = gql`
  query ResultsForTeam($before: DateTime, $after: DateTime) {
    myPersonalTeams {
      id
      name
      kpiName
    }

    resultsPerso(before: $before, after: $after) {
      targets {
        id
        value
        time
        logTotal

        teamMember {
          id
          team {
            name
            id
          }
          kpiName
          kpiUnit
        }
      }
      after
      before
    }

    myLogs(type: "PERSO", limit: 10) {
      value
      time
      action {
        name
      }
      team {
        name
        kpiName
        kpiUnit
      }
      target {
        teamMember {
          id
          kpiUnit
          kpiName
        }
      }
    }
  }
`;

const NEEDWATCH_QUERY = gql`
  query needWatchSync {
    needWatchSync
  }
`;

function getColorPie(value: number) {
  //value from 0 to 1
  var hue = (value * 360).toString(10);
  return `hsl(${hue},80%,65%)`;
}

const renderColorfulLegendText = (value: string) => {
  return <span style={{ color: "white" }}>{value}</span>;
};

function DashPersoPage() {
  const { data, loading } = useQuery(RESULTS_QUERY, {
    variables: {
      before: moment().endOf("month"),
      after: moment().startOf("month"),
    },
  });
  const { data: needWatchData } = useQuery(NEEDWATCH_QUERY);

  const navigate = useNavigate();

  const dataOverview = useMemo(() => {
    if (!data?.myPersonalTeams?.length) return [];

    return data.myPersonalTeams.map((team: Team, i: number) => {
      const target = data?.resultsPerso?.targets.find(
        (target: Target) => target.teamMember.team.id === team.id
      );

      const percentage = Math.round(
        Math.min(100, Math.max(1, (100 * (target?.logTotal || 0)) / (target?.value || 1)))
      );
      return {
        name: team.name,
        value: percentage,
        fill: getColorPie(i / data.myPersonalTeams.length),
      };
    });
  }, [data]);

  if (!loading && !data?.myPersonalTeams?.length)
    return <Navigate to="/perso/define" />;

  return (
    <div className="w-full p-4">
      <div className="flex justify-end">
        <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1 hidden lg:inline">
          Dashboard
        </h1>

        {!!needWatchData?.needWatchSync && (
          <Link to={{ pathname: "/perso/sync" }} state={{ modal: true }}>
            <Button variant="yellow" className="flex mx-4" sizeVariant="big">
              <img src={syncIcon} className="h-6 w-6 mr-2" alt="Sync watch" />
              Sync your watch
            </Button>
          </Link>
        )}

        <Link to="/modal/logModal/perso" className="fixed bottom-2 right-2 z-30 lg:z-0 lg:static">
          <Button variant="primary" className="hidden lg:flex" sizeVariant="big">
            <img src={addLogIcon} className="h-6 w-6 mr-2" alt="Add progress" />
            Update results
          </Button>

          <Button variant="primary" className="rounded-full h-16 w-16 shadow-md block lg:hidden" sizeVariant="big">
            <img src={addLogIcon} className="h-6 w-6 m-auto" alt="Add progress" />
          </Button>
        </Link>
      </div>

      <div className="flex border-b mb-4 text-xl">
        <Link
          to="/"
          className={`border-b-4 py-2 mr-4 text-text border-transparent`}
        >
          Business
        </Link>
        <Link
          to="/perso/dash"
          className={`border-b-4 py-2 mr-4 text-title border-title`}
        >
          Life
        </Link>
      </div>

      {loading ? (
        <div>Loading dashboard...</div>
      ) : (
        <div>
          <div className="flex">
            <div className="text-title space-y-6 w-1/4 mt-6 hidden lg:block">
              <h2 className="font-semibold text-xl mb-6">
                Manage your life goals
              </h2>
              <p>
                Here's what's happening with your goals and achievements today.
              </p>
              <p>
                Use your watch device to have an easier access to productivity
                rings and make it your goal to close them.
              </p>
            </div>

            <div className="flex flex-1 flex-col space-y-4 lg:flex-row">
              <div
                className="primary-box flex-1 lg:-mb-1"
                style={{
                  background:
                    "linear-gradient(246.09deg, #11405D 0%, #00C49A 100%)",
                }}
              >
                <h3 className="text-white font-medium text-lg mb-6">
                  Overview
                </h3>

                <ResponsiveContainer width={250} height={270} className="m-auto mt-5">
                  <RadialBarChart
                    data={dataOverview}
                    innerRadius="40%"
                    outerRadius={100}
                    cx="50%"
                    cy={100}
                    startAngle={90}
                    endAngle={-270}
                  >
                    <PolarAngleAxis
                      type="number"
                      domain={[0, 100]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar cornerRadius={100} background dataKey="value">
                      <Cell max={100} />
                    </RadialBar>
                    <Legend
                      verticalAlign="bottom"
                      iconType="circle"
                      color="#FFFFFF"
                      formatter={renderColorfulLegendText} 
                    />
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>

              {data.myPersonalTeams.map((team: Team) => {
                console.log(team)
                const target = data?.resultsPerso?.targets.find(
                  (target: Target) => target.teamMember.team.id === team.id
                );
                  
                if (!target) return null; // If no target is defined => avoid crash
                
                const percentage = Math.round(
                  Math.max(
                    0,
                    (100 * (target?.logTotal || 0)) / (target?.value || 1)
                  )
                );
                const doneG = Math.min(100, percentage);
                const dataForPie = [
                  { name: "Done", value: doneG },
                  { name: "To Do", value: 100 - doneG },
                ];

                return (
                  <div className="primary-box flex-1 relative group">
                    <ResponsiveContainer width="100%" height={150}>
                      <PieChart width={50} height={50}>
                        <Pie
                          data={dataForPie}
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          innerRadius={45}
                          outerRadius={50}
                          startAngle={90}
                          endAngle={-270}
                          isAnimationActive={false}
                        >
                          {!!target && (
                            <Label
                              value={`${percentage}%`}
                              position="center"
                              fill={getColor(percentage)}
                            />
                          )}
                          <Cell fill={getColor(percentage)} />
                          <Cell fill="#DDDDDD" />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>

                    <h3 className="text-title font-medium text-lg mb-2">
                      {team.name}

                      <FaPen
                        className="text-gray-300 hover:text-title cursor-pointer inline-block ml-2 pt-1 lg:invisible group-hover:visible"
                        onClick={() => navigate(`/perso/editTarget/${target.teamMember.id}`)}
                      />

                    </h3>
                    <h5 className="text-text text-sm">{`${target.logTotal}/${
                      target.value
                    } ${target.kpiName || team.kpiName}`}</h5>
                  
                  </div>
                );
              })}

              {data.myPersonalTeams?.length < 3 && (
                <div
                  className="primary-box flex-1"
                  onClick={() => navigate("/perso/define")}
                >
                  <FaPlus className="text-text text-8xl mx-auto my-6" />

                  <h3 className="text-title font-medium text-lg mb-2">
                    Select new target
                  </h3>
                  <h5 className="text-text text-sm">You can have 3 targets</h5>
                </div>
              )}
            </div>
          </div>

          <div className="hidden lg:block">
            <PersoEvolutionComponent />
          </div>
        </div>
      )}
    </div>
  );
}

export default DashPersoPage;
