import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { QUERY_TARGETS } from "../pages/UserEditTargetsModal";

const UPDATE_MUTATION = gql`
  mutation UpdateTarget(
    $targetId: ID
    $value: Float
    $teamMember: ID
    $time: DateTime
    $isCumulative: Boolean
  ) {
    updateTarget(
      targetId: $targetId
      value: $value
      teamMember: $teamMember
      time: $time
      isCumulative: $isCumulative
    ) {
      success
      message
    }
  }
`;

type TargetInputProps = {
  teamMember: TeamMember;
  period: moment.Moment;
  noCumul?: boolean;
  periodicity?: string;
};

export default function TargetInput({ teamMember, period, noCumul, periodicity = 'month' }: TargetInputProps) {
  const [success, setSuccess] = useState(false);
  const [value, setValue] = useState(0);
  const [isCumulative, setCumulative] = useState(false);

  const [updateTarget, { loading: updateLoading }] = useMutation(UPDATE_MUTATION, {
    refetchQueries: [QUERY_TARGETS, "targetsForUser"],      
  });

  const target = useMemo(() => teamMember.targets.find((target: Target) =>
    moment(target.time).isSame(period, periodicity as moment.unitOfTime.StartOf)
  ), [teamMember, period, periodicity]);

  useEffect(() => {
    setValue(target?.value || 0);
    setCumulative(target?.cumulPrevious || false);
  }, [target]);

  async function onBlur(cumulative?: boolean) {
    try {
      await updateTarget({
        variables: {
          targetId: target?.id,
          value,
          teamMember: teamMember.id,
          time: period.toISOString(),
          isCumulative: !noCumul && cumulative,
        },
      });
      setSuccess(true);
      setTimeout(() => setSuccess(false), 1000);
    } catch (e) {}
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(parseFloat(e.target.value));
  }

  function onChangeCumulative(e: React.ChangeEvent<HTMLInputElement>) {
    setCumulative(e.target.checked);
    onBlur(e.target.checked);
  }

  if (updateLoading) return <p>Loading...</p>;

  return (
    <div className="flex space-x-2">
      <input
        className={`w-full border px-2 pr-0 bg-white rounded-lg ${
          success ? "border-green-500" : "border-gray-300"
        } focus:border-white`}
        type="number"
        value={value}
        onChange={onChange}
        onBlur={() => onBlur(isCumulative)}
      />
      {!noCumul && <input type="checkbox" title="Check to include results from previous period" checked={isCumulative} onChange={onChangeCumulative} />}
    </div>
  );
}
