import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import TargetBlockagePill from "./TargetBlockagePill";

const TARGET_QUERY = gql`
  query targetBlockagesForTeams($company: ID!, $time: DateTime!) {
    targetBlockagesForCompany(company: $company, time: $time) {
      blockage {
        id
        note
        solution {
          id
          name
        }
        reason {
          name
          id
        }
        target {
          value
          logTotal
        }
      }
      team {
        id
        name
      }
    }
  }
`;

function getColorPie(value: number) {
  //value from 0 to 1
  var hue = (value * 120).toString(10);
  return `hsl(${hue},80%,65%)`;
}


function ObstaclesComponentForTeams({ company, time, periodicity = 'month' } : { company: ID, time: Date, periodicity: string}) {
  const { data, loading } = useQuery(TARGET_QUERY, {
    variables: {
      company,
      time: moment(time).subtract(1, periodicity as moment.unitOfTime.DurationAs).toDate(),
    }
  });
  if (loading) return <p>Loading...</p>;
  if (!data) return null;

  return (
    <>
      <h3 className="text-title font-bold text-lg">Obstacles</h3>

      {!!data?.targetBlockagesForCompany?.length ? (

      <table className="w-full text-center mt-5 table-fixed" id="obstaclesGraph">
        <thead>
          <tr>
            <th className="text-left">Team</th>
            {/*<th>Result</th>*/}
            <th>Obstacle</th>
            <th>Solution</th>
          </tr>
        </thead>

        <tbody>
          {data?.targetBlockagesForCompany?.map(
            ({ team, blockage }: { team: Team, blockage: TargetIssue }, index: number) => {
              const target = blockage.target as any;

              /*
              const done = target?.logTotal || 0;
              const percentage = Math.min(
                100,
                Math.round(Math.max(0, (100 * done) / (target?.value || 1)))
              );
              const dataIn = [
                { name: "Done", value: percentage },
                { name: "To Do", value: 100 - percentage },
              ];*/

              return (
                <tr>
                  <td className="py-2">
                    <h3 className="text-lg font-medium text-left">
                      {team.name}
                    </h3>
                  </td>
                  {/*<td>
                    <ResponsiveContainer width="100%" height={100}>
                      <PieChart width={40} height={40}>
                        <Pie
                          data={dataIn}
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          innerRadius={35}
                          outerRadius={40}
                          startAngle={90}
                          endAngle={-270}
                          isAnimationActive={false}
                        >
                          {!!target && (
                            <Label
                              value={`${percentage}%`}
                              position="center"
                              fill={getColor(percentage)}
                            />
                          )}
                          <Cell fill={getColor(percentage)} />
                          <Cell fill="#DDDDDD" />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                          </td>*/}

                  <td>
                    {!!blockage?.reason ? (
                      <TargetBlockagePill
                        color={getColorPie(index / data?.targetBlockagesForCompany?.length)}
                        lastTarget={target?.id}
                        note={blockage.solution?.note}
                        className={"inline-block"}
                      >
                        {blockage.reason.name}
                      </TargetBlockagePill>
                    ) : (
                      <TargetBlockagePill notSet lastTarget={target?.id}
                      className={"inline-block"}
                      >
                        No obstacle set
                      </TargetBlockagePill>
                    )}
                  </td>
                  <td>
                    {!!blockage?.reason ? (
                      <TargetBlockagePill color={getColorPie(index / data?.targetBlockagesForCompany?.length)} 
                      className={"inline-block"}
                      >
                        {blockage.solution
                          ? blockage.solution.name
                          : "No default solution"}
                      </TargetBlockagePill>
                    ) : (
                      <TargetBlockagePill notSet
                      className={"inline-block"}
                      >
                        No focus set
                      </TargetBlockagePill>
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table> ) : (
        <div className="text-center p-8" id="obstaclesGraph">No obstacles for this period.</div>
      )}
    </>
  );
}

export default ObstaclesComponentForTeams;
