import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import Button from "../components/Button";

function MyProfilePage() {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user)
    return (
      <Alert type="error" display>
        You are not logged in
      </Alert>
    );

  return (
    <div className="w-full p-4">
      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <div className="flex px-8">
          <img
            src={user.picture}
            className="w-32 h-32 rounded-full border-2 border-white"
            alt={`Profile of ${user.name}`}
          />
          <div className="px-8">
            <h1 className="text-3xl pb-4 font-bold font-sans">{user.name}</h1>
            <h1 className="text-xl pb-4 font-sans">
              {user.role} {user.isAdmin && (<em>Admin</em>)}
            </h1>
          </div>
          <div></div>
        </div>
      </div>

      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <h1 className="text-2xl pb-4 font-bold font-sans">
          Account management
        </h1>

        <div>
          <Button onClick={() => navigate("/modal/changePassword")}>Change password</Button>
        </div>
      </div>
    </div>
  );
}

export default MyProfilePage;
