import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { gql, useMutation } from "@apollo/client";

const DELETE_UNUSED_ACTIONS_MUT = gql`
  mutation DeleteUnusedActions {
    deleteUnusedActions {
      message
      success
    }
  }
`;

function AdminIndexPage() {
  const { user } = useAuth();
  const [deleteActionsMutation] = useMutation(DELETE_UNUSED_ACTIONS_MUT);

  function deleteUnusedActions() {
    if (!window.confirm("Are you sure you want to delete all unused actions?"))
      return;

    deleteActionsMutation().then(({ data }) => {
      alert(data.deleteUnusedActions.message);
    });
  }

  if (!user)
    return (
      <Alert type="error" display>
        You are not logged in
      </Alert>
    );

  return (
    <div className="w-full p-4">
      <div className="flex">
        <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
          Administration
        </h1>
      </div>

      <div className="primary-box mx-0 relative" id="teamResults">
        <div className="space-x-3">
          <Link to="/admin/company">
            <Button>Edit Company</Button>
          </Link>
          <Link to="/admin/users">
            <Button>Edit Users</Button>
          </Link>
          <Link to="/admin/teams">
            <Button>Edit Teams</Button>
          </Link>
        </div>
      </div>
      <div className="primary-box mx-0 relative" id="teamResults">
        <div className="space-x-3">
          <Link to="/admin/import">
            <Button>Import from CSV</Button>
          </Link>

          <Button onClick={deleteUnusedActions}>Delete unused actions</Button>
        </div>
      </div>
    </div>
  );
}

export default AdminIndexPage;
