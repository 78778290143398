import React, { useEffect, useState } from "react";

interface AlertProps {
  children: React.ReactNode;
  type: "success" | "error" | "info";
  autoDismiss?: number;
  display: boolean;
  onDismiss?: Function;
}

export default function Alert(props: AlertProps) {
  const { children, type, autoDismiss, display, onDismiss } = props;
  const [color, setColor] = useState("");
  const [width, setWidth] = useState("0px");

  useEffect(() => {
    if (type === "success")
      return setColor("border-green-400 bg-green-200 text-green-800");
    if (type === "error")
      return setColor("border-red-400 bg-red-200 text-red-800");
    if (type === "info")
      return setColor("border-blue-400 bg-blue-200 text-blue-800");
  }, [type]);

  useEffect(() =>{
    if (display) {
      requestAnimationFrame(() => {
        setWidth("100%");
      });
      if (onDismiss && autoDismiss) setTimeout(() => onDismiss(), autoDismiss);
    } else {
      setWidth("0px");
    }
  }, [display, autoDismiss, onDismiss]);

  if (!display) return null;

  return (
    <div className={`border rounded-md my-3 ${color}`}>
      {!!autoDismiss && (
        <div className={`w-full border-4 ${color}`} style={{ width, transition: `all ${autoDismiss}ms`, }}></div>
      )}
      <div className="p-2">{children}</div>
    </div>
  );
}
