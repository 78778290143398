import { gql, useQuery } from "@apollo/client";
import { ReactNode, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaCheck } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

import dashboardIcon from "../assets/icons/dashboard.svg";
import obstaclesIcon from "../assets/icons/obstacles.svg";
import rankingIcon from "../assets/icons/ranking.svg";
import globalRankingIcon from "../assets/icons/ranking.svg";
import overachieversIcon from "../assets/icons/share.svg";
import performanceReportIcon from "../assets/icons/logs.svg";

import logsIcon from "../assets/icons/logs.svg";
import useAuth from "../hooks/useAuth";

const TEAMS_QUERY = gql`
  query Teams {
    teams(onlyPro: true) {
      name
      id
    }
  }
`;

interface MainMenuProps {
  visible?: Boolean;
}

interface MenuButtonProps {
  children: ReactNode;
  to: string;
}

function MenuButton({ children, to }: MenuButtonProps) {
  return (
    <Link
      className="flex items-center px-1 py-2 text-title hover:text-primary overflow-hidden"
      to={to}
    >
      {children}
    </Link>
  );
}

export default function MainMenu({ visible }: MainMenuProps) {
  const [ expanded, setExpanded ] = useState(true);
  const { data: teamsData } = useQuery(TEAMS_QUERY);
  const { user } = useAuth();

  return (
    <div className="">
      <div
        className={`primary-box px-0 lg:px-4 absolute top-0 left-0 bg-white z-20 py-8 lg:py-4 lg:relative ${
          (expanded !== visible) ? "px-8 w-72" : "w-0 lg:w-16"
        } transition-width ease-linear`}
      >
        <MenuButton to="/">
          <img src={dashboardIcon} className="mr-3 h-5 w-5 hidden lg:inline-block" alt="Dashboard" />
          <span className={(expanded !== visible) ? "block" : "hidden"}>Dashboard</span>
        </MenuButton>
        {(expanded !== visible) && (
          <ul className="overflow-hidden">
            <li>
              <NavLink
                className={({ isActive }) =>
                  `${
                    isActive ? "bg-gray-100" : ""
                  } text-text py-1 px-4 rounded-full block`
                }
                to="/"
              >
                Overview
              </NavLink>
            </li>
            {!!teamsData?.teams?.length &&
              teamsData.teams
                .sort((a: Team, b: Team) => (a.name > b.name ? 1 : -1))
                .map((team: Team) => (
                  <li key={`mainMenu-team-${team.id}`}>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive ? "bg-gray-100" : ""
                        } text-text py-1 px-4 rounded-full block`
                      }
                      to={`/${team.id}`}
                    >
                      {team.name}
                    </NavLink>
                  </li>
                ))}
          </ul>
        )}

        {(!user?.company?.disabledFeatures?.includes('obstacles')) && <MenuButton to="/blockages">
          <img
            src={obstaclesIcon}
            className="mr-3 h-5 w-5 hidden lg:inline-block"
            alt="Obstacles & actions"
          />
          <span className={(expanded !== visible) ? "block" : "hidden"}>
            Obstacles & Actions
          </span>
        </MenuButton>}

        {(!user?.company?.disabledFeatures?.includes('objectives')) && <MenuButton to="/objectives">
          <FaCheck className="h-4 w-4 hidden lg:inline-block" />
          <span className={(expanded !== visible) ? "block lg:ml-4" : "hidden"}>
            Objectives
          </span>
        </MenuButton>}

        {!!(user?.teams?.length && !user?.company?.disabledFeatures?.includes('ranking')) && (
          <MenuButton to={`/leaderboard/${user?.teams[0]?.currentTarget?.id}`}>
            <img src={rankingIcon} className="mr-3 h-5 w-5 hidden lg:inline-block" alt="Ranking" />
            <span className={(expanded !== visible) ? "block" : "hidden"}>Ranking</span>
          </MenuButton>
        )}

        <MenuButton to="/my/logs">
          <img src={logsIcon} className="mr-3 h-5 w-5 hidden lg:inline-block" alt="My logs" />
          <span className={(expanded !== visible) ? "block" : "hidden"}>Your Logs</span>
        </MenuButton>

        {user?.isAdmin && (
        <>
          {(!user?.company?.disabledFeatures?.includes('globalRanking')) && <MenuButton to="/ranking">
          <img
            src={globalRankingIcon}
            className="mr-3 h-5 w-5 hidden lg:inline-block"
            alt="Global ranking"
          />
          <span className={(expanded !== visible) ? "block" : "hidden"}>
            Global ranking
          </span>
        </MenuButton>}
        {!user?.company?.disabledFeatures?.includes('overachievers') && <MenuButton to="/overachievers">
          <img
            src={overachieversIcon}
            className="mr-3 h-5 w-5 hidden lg:inline-block"
            alt="Overachievers"
          />
          <span className={(expanded !== visible) ? "block" : "hidden"}>
            Overachievers
          </span>
        </MenuButton>}
        {!user?.company?.disabledFeatures?.includes('peReport') && <MenuButton to="/genreport">
          <img
            src={performanceReportIcon}
            className="mr-3 h-5 w-5 hidden lg:inline-block"
            alt="Performance Report"
          />
          <span className={(expanded !== visible) ? "block" : "hidden"}>
            Performance Report
          </span>
        </MenuButton>}
          <MenuButton to="/admin">
            <img
              src={obstaclesIcon}
              className="mr-3 h-5 w-5 hidden lg:inline-block"
              alt="Administration"
            />
            <span className={(expanded !== visible) ? "block" : "hidden"}>
              Administration
            </span>
          </MenuButton>
        </>
        )}

        <button
          onClick={() => setExpanded(!expanded)}
          className="absolute -right-5 top-10 shadow-md bg-white p-3 rounded-full text-title hidden lg:block"
        >
          {(expanded !== visible) ? <FaArrowLeft /> : <FaArrowRight />}
        </button>
      </div>
    </div>
  );
}
