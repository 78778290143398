import moment from "moment";

export function formatTarget(amount:number) {
  if (amount > 1000) {
    return `${Math.round(amount/1000)}K`;
  }

  return amount.toFixed(2).replace(/\.00$/, '');
}

export function formatForPeriodicity(date: moment.MomentInput, periodicity: string = 'month', sorted: boolean = false) {
  if (sorted) {
    if (periodicity === 'month') return moment(date).format("YYYY MM");
    if (periodicity === 'week') return moment(date).format("YYYY [week] Wo");
    if (periodicity === 'quarter') return moment(date).format("YYYY [Q]Q");

    return moment(date).format("YYYYMMDD");
  } else {
    if (periodicity === 'month') return moment(date).format("MMMM YYYY");
    if (periodicity === 'week') return moment(date).format("Wo [week] YYYY");
    if (periodicity === 'quarter') return moment(date).format("[Q]Q YYYY");  

    return moment(date).format("DD/MM/YYYY");
  }
}