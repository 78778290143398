import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Button from "./Button";
import FormInput from "./FormInput";
import Alert from "./Alert";
import { USERS_QUERY } from "../pages/AdminUsersPage";

const QUERY_USER = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      picture
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateUser($user: ID!, $picture: Upload!) {
    updateProfilePicture(user: $user, picture: $picture) {
      success
      message
    }
  }
`;

function UserEditProfilePicture({ userId }: { userId: ID }) {
  /*
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  */
  const [success, setSuccess] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);

  const { data: initialUser, loading } = useQuery(QUERY_USER, {
    variables: { id: userId },
  });

  const [updateUser, { loading: updateLoading }] = useMutation(
    UPDATE_MUTATION,
    {
      refetchQueries: [QUERY_USER, "getUserById", USERS_QUERY, "Users"],
    }
  );

  async function upload() {
    await updateUser({
      variables: {
        user: userId,
        picture: file,
      },
    });

    setSuccess(true);
  }

  if (loading) return null;

  return (
    <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
      <h1 className="text-2xl pb-4 font-bold font-sans">Profile picture</h1>
      <Alert
        type="success"
        display={!!success}
        onDismiss={() => setSuccess(false)}
        autoDismiss={3000}
      >
        User picture edited successfully !
      </Alert>

      <img
        src={file ? URL.createObjectURL(file) : initialUser.getUserById.picture}
        className={"rounded-full my-3 bg-white w-32 h-32 mx-auto"}
        alt="Profile"
      />

      <FormInput
        label="Profile picture"
        type="file"
        onChange={(e) => setFile(e?.target?.files ? e.target.files[0] : null)}
      />

      {loading || updateLoading ? (
        <div>Loading...</div>
      ) : (
        <Button onClick={upload}>Upload picture</Button>
      )}
    </div>
  );
}

export default UserEditProfilePicture;
