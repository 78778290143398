import React from "react";
import { useParams, useNavigate } from "react-router";
import { gql, useMutation } from "@apollo/client";

import Alert from "../components/Alert";
import Button from "../components/Button";
import Modal from "../components/Modal";
import UserEditInfoComponent from "../components/UserEditInfoComponent";
import UserTeamsEditComponent from "../components/UserTeamsEditComponent";
import { USERS_QUERY } from "./AdminUsersPage";
import UserEditProfilePicture from "../components/UserEditProfilePicture";


const UPDATE_MUTATION = gql`

mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    code
    success
  }
}
`;



function UserEditModal() {
  const params = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = React.useState<string>("userInfo");
  const [deleteUser] = useMutation(
    UPDATE_MUTATION,
    {
      refetchQueries: [USERS_QUERY, "Users"],
    }
  );

  const id = params.id as ID;

  function _deleteUser() {
    const confirm = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirm) return;

    deleteUser({ variables: { id } }).then(() => navigate(-1));
  }

  return (
    <Modal title={!!id ? "Edit user" : "Create user"}>
      <div className="p-4">
        {!!id && (
          <div className="flex align-middle">
            <Button onClick={() => setTab("userInfo")}>User info</Button>
            <Button onClick={() => setTab("profilePicture")}>Profile picture</Button>
            <Button onClick={() => setTab("teams")}>Teams & actions</Button>
            <Button onClick={() => setTab("delete")}>Deactivate user</Button>
          </div>
        )}

        {tab === "userInfo" && <UserEditInfoComponent userId={id} />}
        {tab === "profilePicture" && <UserEditProfilePicture userId={id} />}
        {tab === "teams" && <UserTeamsEditComponent userId={id} />}
        {tab === "delete" && (
          <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
            <h1 className="text-2xl pb-4 font-bold font-sans">Deactivate user</h1>
            <Alert type="error" display>
              Deactivating a user stops the access to the account. His actions will stay stored and used in computations of totals but aren't visible individualy.
            </Alert>

            <Button onClick={_deleteUser} variant="danger">
              Deactivate user
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UserEditModal;
