import { gql, useMutation, useQuery } from "@apollo/client";
import {
  FaCheck,
  FaPencilAlt,
  FaPlusCircle,
  FaRegCheckCircle,
  FaRegCircle,
  FaRegDotCircle,
  FaSpinner,
  FaStar,
  FaTrashAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Fragment, useMemo } from 'react'
import { Listbox, Menu, Transition } from '@headlessui/react'
import { ChevronUpDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import useAuth from '../hooks/useAuth';

import Button from "./Button";

export const OBJECTIVES_QUERY = gql`
query Query {
  objectives: myObjectives {
    id
    primary
    text
    type
    stateObj {
      id
      text
      textColor
      color
    }
  }
}
`;

const CHECK_MUT = gql`
mutation CheckObjective($state: String!, $id: ID!) {
  checkObjective(state: $state, id: $id) {
    success
    message
  }
}
`;

interface RankingBarProps {
  expanded: boolean;
}

export interface RankingItemProps {
  okr: OKR;
  expanded: boolean;
  displayReview?: boolean;
  static?: boolean;
  onUpdate?: Function,
  user?: ID,
  team?: ID,
}


interface SelectFieldProps {
  options: {value: string, text: any}[],
  value: string,
  onChange: (value:string) => void,
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const StatusSelect = (props: SelectFieldProps) => {
  const { options, value, onChange } = props;

  const selected = useMemo(() => options.find((d) => d.value === value), [options, value]);

  return (
  <Listbox value={value} onChange={onChange}>
    {({ open }) => (
      <div className="relative">
        <Listbox.Button className="flex relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 group-hover:shadow-sm group-hover:ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span className="text-gray-600 mr-4">
            Set status:
          </span>
          {selected?.text}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 opacity-0 group-hover:opacity-100">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={({active} : {active: boolean}) =>
                  classNames(
                    active ? 'bg-blue-400 text-white' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9'
                  )
                }
                value={option.value}
              >
                {() => (option.text)}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>);
};

export function OKRItemWithButton({ okr, onUpdate, expanded, displayReview, user, team, static: isStatic }: RankingItemProps) {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();

  const [checkObjective, { loading }] = useMutation(CHECK_MUT, {
    refetchQueries: [OBJECTIVES_QUERY, 'Query'],
  });

  const canReview = useMemo(() => {
    return team && currentUser?.teams.find((t) => t.team.id === team && t.isManager);
  }, [currentUser, team]);


  async function updateStatus(value:string) {
    await checkObjective({
      variables: {
          id: okr.id,
          state: value,
        },
      })

      if (onUpdate) onUpdate();
  }

  async function deleteObjective(e:any) {
    e.preventDefault();

    if (window.confirm('Are you sure you want to delete this objective ?')) {
      await checkObjective({
        variables: {
          id: okr.id,
          state: "DELETED",
        },
      });

      if (onUpdate) onUpdate();
    }
  }

  let reviewColor = 'bg-red-400 border-red-200'; 
  if (okr?.reviews?.[0]?.state === 'meets expectations') reviewColor = 'bg-orange-400 border-orange-200'; 
  else if (okr?.reviews?.[0]?.state === 'outstanding') reviewColor = 'bg-green-400 border-green-200'; 

  return (
    <div className="block mb-3 items-center relative">
      <div className="flex items-center group" title={okr.text}>
        <div className={`flex-1 items-center flex ${okr.primary ? "font-medium" : "font-normal"} ${okr.state === 'done' ? "line-through" : ""} pl-1 text-left text-sm flex flex-1 space-x-10`}>
          <div className="w-4 mr-1">
            {okr.state === 'DONE' && (<FaRegCheckCircle className="text-green-700" title="Done" />)}
            {okr.state === 'PROGRESS' && (<FaRegDotCircle className="text-orange-400" title="In progress" />)}
            {okr.state === 'TODO' && (<FaRegCircle title="To do"/>)}
          </div>
          {loading && <FaSpinner className="animate-spin inline-block"/>}
          {okr.text}
        </div>
        <div className="">
          <StatusSelect
            options={[
              { text: (<div className="flex items-center space-4"><FaRegCheckCircle className="text-green-700 mr-2" title="Done" /> Completed</div>), value: "DONE"},
              { text: (<div className="flex items-center space-4"><FaRegDotCircle className="text-orange-400 mr-2" title="In progress" /> In&nbsp;progress</div>), value: "PROGRESS"},
              { text: (<div className="flex items-center space-4"><FaRegCircle className="mr-2" title="Not started" /> Not&nbsp;started</div>), value: "TODO"},
            ]}
            onChange={updateStatus}
            value={okr.state || ""}
          />
        </div>
        <div className="flex-1 flex justify-center">
        {displayReview ? (
          <div>
            {!!okr.reviews?.length && <div className={`${reviewColor} border mx-2 px-1 rounded-md text-white text-sm font-medium`}>{okr.reviews?.[0].state?.toLocaleUpperCase()}</div>}
            {canReview && <Button className={`hidden group-hover:block`} variant="outlined" sizeVariant="small" onClick={() => navigate(`/objectives/review/${okr.id}`)}>Add review</Button>}
          </div>
        ): ''}
        </div>


        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <FaPencilAlt onClick={() => navigate(`/objectives/edit/${user}/${team}/${okr.id}`)} className={classNames("m-4 cursor-pointer text-gray-400", active && 'text-gray-600')} />
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <FaTrashAlt onClick={deleteObjective} className={classNames("m-4 cursor-pointer text-gray-400", active && 'text-gray-600')} />
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

export function OKRItem({ okr, onUpdate, expanded, displayReview, static: isStatic }: RankingItemProps) {
  const navigate = useNavigate();

  return (
    <div className="block mb-3 items-center relative">
      <div className="flex items-center group" title={okr.text}>
        <button onClick={() => navigate('/objectives')} className="flex items-center w-full" disabled={isStatic}>
          <div className="w-4 mr-1">
            <FaRegCircle style={{backgroundColor: okr.stateObj?.color, color: okr.stateObj?.textColor}} className="rounded-full" title={okr.stateObj?.text} />
          </div>
          <div
            className={`${okr.primary ? "font-medium" : "font-normal"} ${okr.state === 'done' ? "line-through" : ""} pl-1 text-left text-sm flex flex-1`}
          >
            {expanded ? (
            <div className="flex-1">
              {okr.primary && <FaStar className="inline mr-2 text-orange-300" title="Primary objective: This objective helps to achieve your KPI" />}
              {okr.text}
            </div>) : ""}
          </div>
        </button>
      </div>
    </div>
  );
}

export default function OKRBar({ expanded }: RankingBarProps) {
  const navigate = useNavigate();
  const { data } = useQuery(OBJECTIVES_QUERY);

  return (
    <div className="flex">
      <div
        className={`primary-box flex flex-col ${
          expanded ? "w-52" : "w-16"
        } transition-width ease-linear`}
      >
        <div className="flex-1 mb-4">
          <h3 className="text-title font-bold text-lg mb-4">
            <FaCheck className="inline-block h-6 w-6 mr-3" />{" "}
            {expanded && "Objectives"}
          </h3>

          {data?.objectives?.length ? data?.objectives.slice(0, 5).map((okr: OKR) => (
            <OKRItem
              okr={okr}
              expanded={expanded}
            />)) : (
            !!expanded && <div className="text-sm text-center">You didn't define your key objectives.</div>
          )}
        </div>

        {expanded && (
          <Button onClick={() => navigate('/objectives')} className={!data?.objectives?.length ? 'bg-red-100 animate-pulse' : ''}>
            <FaPlusCircle className="inline-block" /> Add objective
          </Button>
        )}
      </div>
    </div>
  );
}
