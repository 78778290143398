import { ReactNode } from "react";

export interface SelectFieldProps {
  error?: string | undefined;
  label: string;
  name: string;
  nolabel?: boolean;
  children: ReactNode;
}

export default function FormGenericInput(props: SelectFieldProps) {
  const { label, nolabel, name, error, children } = props;

  return (
    <div className="mb-4">
      {!nolabel && <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {label}
      </label>}

      {children}

      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};
