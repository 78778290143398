import {
  Cell,
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
} from "recharts";
import { formatTarget } from "../libs/formatFunctions";

interface DashGaugeProps {
  onClick?: () => void;
  className?: string;

  value: number;
  metric: string;
  background: string;
  target?: number;
  unit: string;
  title: string;
}

export default function DashGauge(props: DashGaugeProps) {
  const { className, value, metric, target, title, background } = props;
  const percentage = (100 * value || 0) / (target || 1);

  return (
    <div
      className={`md:w-1/2 py-2 px-4 text-black rounded-lg flex justify-between shadow-md items-center ${className}`}
      style={{
        background,
      }}
    >
      <div>
        <div className="m-3 text-lg font-bold">{title}</div>
        <div className="m-3 text-gray-500">{metric}</div>

        <div className="m-3 font-medium text-2xl">
        {!!target && `${formatTarget(value)}/${formatTarget(target)}`}
        </div>
      </div>

      <RadialBarChart
        data={[{ name: "Test", value: percentage }]}
        width={200}
        height={90}
        innerRadius={70}
        outerRadius={85}
        cx="50%"
        cy={90}
        startAngle={180}
        endAngle={0}
        className=""
      >
        <defs>
          <linearGradient id={`myGradient`}>
            <stop offset="0%" stopColor="#F4511E" />
            <stop offset="8%" stopColor="#F4511E" />
            <stop offset="32%" stopColor="#FB8C00" />
            <stop offset="53%" stopColor="#FDD835" />
            <stop offset="92%" stopColor="#43A047" />
            <stop offset="100%" stopColor="#43A047" />
          </linearGradient>
        </defs>
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar background dataKey="value">
          <Cell fill="url(#myGradient)" max={100} />
        </RadialBar>

        <text
          x="50%"
          y={80}
          fontSize={25}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#000000"
        >
          {`${Math.round(percentage)}%`}
        </text>     
      </RadialBarChart>
    </div>
  );
}
