import React, { useMemo } from "react";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  ComposedChart,
  Line,
  Legend,
} from "recharts";
import Log from "../interfaces/Log";
import useAuth from "../hooks/useAuth";

import { generateColors } from "../libs/colors";
import { formatForPeriodicity } from "../libs/formatFunctions";

const TARGET_QUERY = gql`
  query resultsForTeamMember(
    $teamMember: ID!
    $before: DateTime
    $after: DateTime
  ) {
    resultsForTeamMember(
      teamMember: $teamMember
      before: $before
      after: $after
    ) {
      targets {
        value
        time
        logTotal
        logs {
          id
          value
          action {
            name
          }
          virtualForCumul
        }
      }
      before
      after
    }
  }
`;

function TargetHistoricComponent({
  teamMember,
  time,
  legend = false,
}: {
  teamMember?: string;
  time: Date;
  legend?: boolean;
}) {
  const { user } = useAuth();
  const { data, loading } = useQuery(TARGET_QUERY, {
    variables: {
      teamMember,
      before: moment(time).endOf( user?.company.periodicity || "month").toDate(),
      after: moment(time).subtract(12,  user?.company.periodicity || "month").startOf( user?.company.periodicity || "month").toDate(),
    },
  });

  const dataBarChartValues = useMemo(() => {
    if (!data) return [];
    const outputTable: object[] = [];

    data.resultsForTeamMember.targets.forEach((target: Target) => {
      const logsByActionName = target.logs.reduce(
        (acc: { [key: string]: number }, log: Log) => {
          const actionName = log.action.name;
          if (acc[actionName]) acc[actionName] += log.value;
          else acc[actionName] = log.value;
          return acc;
        },
        {}
      );

      outputTable.push({
        __period: formatForPeriodicity(target.time, user?.company.periodicity || "month"),
        time: target.time,
        ...logsByActionName,
        __target: target.value,
      });
    });

    return outputTable.sort((a: any, b: any) => moment(a.time).isAfter(b.time) ? 1 : -1);
  }, [data, user]);

  const dataBarChartKeys = useMemo(() => {
    if (!dataBarChartValues?.length) return [];

    const keys = dataBarChartValues.reduce((acc: string[], row: any) => {
      const newLocal = new Set([...acc, ...Object.keys(row)]);
      return [...newLocal];
    }, []);

    return keys.filter(
      (key: string) => key !== "__period" && key !== "__target"
    );
  }, [dataBarChartValues]);

  if (loading) return <p>Loading...</p>;
  if (!data) return null;

  const COLORS = generateColors(dataBarChartKeys.length);
  return (
    <>
      <h3 className="text-title font-bold text-lg">
        Performances for last 12 months
      </h3>

      <ResponsiveContainer width="100%" height={450} id="performancesGraph">
        <ComposedChart
          width={500}
          height={400}
          data={dataBarChartValues}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="__period" />
          <YAxis domain={[0, "dataMax"]} allowDataOverflow />
          <Tooltip />

          {legend && <Legend />}

          {dataBarChartKeys.filter(t => t !== 'time').map((key: string, index: number) => (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={COLORS[index % COLORS.length]}
              stroke={COLORS[index % COLORS.length]}
              fillOpacity={key.includes("previous") ? 0.5 : 1}
            />
          ))}

          <Line
            type="monotone"
            dataKey="__target"
            stroke="#777777"
            name="Target"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}

export default TargetHistoricComponent;
