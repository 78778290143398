
export function hsvToHex(h: number, s: number, v: number) {
  // Convert the hue, saturation, and value to the range 0-1
  h = h / 360;
  s = s / 100;
  v = v / 100;

  // Calculate the R, G, and B values
  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);
  let r = 0;
  let g = 0;
  let b = 0;
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }

  // Convert the R, G, and B values to HEX
  var hexR = Math.floor(r * 255).toString(16);
  var hexG = Math.floor(g * 255).toString(16);
  var hexB = Math.floor(b * 255).toString(16);

  // Return the HEX color
  return (
    "#" +
    (hexR.length === 1 ? "0" + hexR : hexR) +
    (hexG.length === 1 ? "0" + hexG : hexG) +
    (hexB.length === 1 ? "0" + hexB : hexB)
  );
}

export function generateColors(n: number) {
  // Define the range of hues
  const hueMin = 0;
  const hueMax = 360;
  const hueRange = hueMax - hueMin;

  // Set the saturation and value to maximum
  const saturation = 60;
  const value = 90;

  // Create an array to store the colors
  const colors = [];

  // Generate the colors
  for (let i = 0; i < n; i++) {
    // Calculate the hue for this color
    const hue = hueMin + i * (hueRange / n);
    // Convert the color to the HSV format
    const color = hsvToHex(hue, saturation, value);

    // Add the color to the array
    colors.push(color);
  }

  // Return the array of colors
  return colors;
}