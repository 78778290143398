import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Hotjar from '@hotjar/browser';

import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./hooks/useAuth";
import GraphQlService from "./libs/GraphQlService";

import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";


const siteId = 3603362;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

Sentry.init({
  dsn: "https://5985f24921e146fcb3b0be36637e54cd@o4504735990022144.ingest.sentry.io/4504735991332864",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={GraphQlService}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
