import { useMemo } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { getColor } from "./TeamDashComponent";

interface DonutProps {
  target: Target;
  size: number;
}

export default function Donut({ target, size }: DonutProps) {
  const [percentage, data] = useMemo(() => {
    const done = Math.round(
      Math.max(0, (100 * target?.logTotal || 0) / (target?.value || 1))
    );
    const doneG = Math.min(100, done);
    const data = [
      { name: "Done", value: doneG },
      { name: "To Do", value: 100 - doneG },
    ];
    return [done, data];
  }, [target]);

  return (
    <ResponsiveContainer width={2*size + 10} height={2*size + 10} className="m-auto">
      <PieChart width={size} height={size}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={size - 5}
          outerRadius={size}
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
          stroke="none"
        >
          {!!target && (
            <Label
              value={`${percentage}%`}
              position="center"
              fill={getColor(percentage)}
              className="text-sm"
            />
          )}
          <Cell fill={getColor(percentage)} />
          <Cell fill="#DDDDDD" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
