import moment from "moment";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { gql, useMutation, useQuery } from "@apollo/client";

import { formatForPeriodicity, formatTarget } from "../libs/formatFunctions";

import artIcon from "../assets/perso-categories/art.png";
import businessIcon from "../assets/perso-categories/business.png";
import coachingIcon from "../assets/perso-categories/coaching.png";
import entrepreneurshipIcon from "../assets/perso-categories/entrepreneurship.png";
import familyIcon from "../assets/perso-categories/family.png";
import healthIcon from "../assets/perso-categories/health.png";
import investmentIcon from "../assets/perso-categories/investment.png";
import learningIcon from "../assets/perso-categories/learning.png";
import networkingIcon from "../assets/perso-categories/networking.png";
import travelsIcon from "../assets/perso-categories/travels.png";
import FormSelectInput from "./FormSelectInput";
import ObjectiveStateSelector from "../pages/ObjectiveStateSelector";
import { LOGACTION } from "../pages/LogModal";
import useAuth from '../hooks/useAuth';


const inputTypeOrder: Record<string, number> = {
  NUMERIC: 1,
  SELECT: 2,
  TEXT: 3,
};

export function getIconForTeam(name: string) {
  switch (name.toLowerCase()) {
    case "art":
      return artIcon;
    case "business":
      return businessIcon;
    case "coaching":
      return coachingIcon;
    case "entrepreneurship":
      return entrepreneurshipIcon;
    case "family":
      return familyIcon;
    case "health":
      return healthIcon;
    case "investment":
      return investmentIcon;
    case "learning":
      return learningIcon;
    case "networking":
    case "network":
      return networkingIcon;
    case "travels":
      return travelsIcon;
  }
}

const RESULTS_QUERY = gql`
  query ResultsForTeam($team: ID, $before: DateTime, $after: DateTime) {
    resultsForTeam(team: $team, before: $before, after: $after) {
      targets {
        id
        logs {
          id
          action {
            name
          }
          value
          valueText
          valueSelected {
            id
            text
          }
        }
        logTotal
        time
        value
        teamMember {
          id
          user {
            id
            name
            picture
          }
          kpiName
          kpiUnit
        }
      }
      after
      before
    }
  }
`;

interface Props {
  team?: Team;
  periodicity?: string;
}

export function findPeriodId(date: Date, after: Date, periodicity: String = 'month') {
  const ret = moment(date).startOf(periodicity as moment.unitOfTime.StartOf).diff(after, periodicity as moment.unitOfTime.DurationAs) - 1;
  return ret;
}

export function getColor(value: number) {
  if (value < 40) return "#F4511E";
  if (value < 60) return "#EA7317";
  if (value < 80) return "#FFD166";
  if (value < 95) return "#4CAF50";

  return "#226F54";
}

export function getStatusName(value: number) {
  if (value < 40) return "Off Track";
  if (value < 60) return "At risk";

  return "On track";
}


type TargetCellProps = {
  period: string,
  target: Target,
  teamMember: TeamMember
};

export function NumericTargetCell({period, target, teamMember} : TargetCellProps)  {
    const done = target?.logTotal || 0;
    const doneG = Math.min(
      100,
      Math.max(0, (100 * done) / (target?.value || 1))
    );
    const data = [
      { name: "Done", value: doneG },
      { name: "To Do", value: 100 - doneG },
    ];

    return (
      <td
        key={`td-team-prod-dash-${teamMember.id}-${period}`}
        className="text-center"
      >
        <Link
          to={
            target ? `/modal/targetLogs/${teamMember.id}/${target.id}` : ""
          }
        >
          <ResponsiveContainer width="100%" height={120}>
            <PieChart width={50} height={50}>
              <Pie
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={45}
                outerRadius={50}
                startAngle={90}
                endAngle={-270}
                cursor="pointer"
                isAnimationActive={false}
              >
                {!!target && (
                  <Label
                    value={`${Math.round(
                      (100 * done) / (target?.value || 1)
                    )}%`}
                    position="center"
                    cursor="pointer"
                    fill={getColor(
                      (100 * done) / (target?.value || 1)
                    )}
                  />
                )}
                <Cell
                  fill={getColor(
                    (100 * done) / (target?.value || 1)
                  )}
                />
                <Cell fill="#DDDDDD" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Link>
        <span
          title={
            target
              ? `Achieved/Goal: ${done}/${target?.value}`
              : "Target not defined yet"
          }
        >
          {target ? (
            `${formatTarget(done)} / ${formatTarget(
              target?.value
            )}`
          ) : (
            <>&zwnj;</>
          )}
        </span>
      </td>
    );
}

export function TextTargetCell({period, target, teamMember} : TargetCellProps)  {
  const lastLog = target?.logs?.[target?.logs?.length - 1];

  return (
    <td
      key={`td-team-prod-dash-${teamMember.id}-${period}`}
      className="text-left"
    >
      {lastLog?.valueText ? (
        <div className="py-1 px-2 text-sm" dangerouslySetInnerHTML={{__html: lastLog?.valueText.replace(/(?:\r\n|\r|\n)/g, '<br />')}} ></div>
      ) : (
        <div className="text-gray-400 text-sm text-center">-</div>
      )}
    </td>
  );
}

export function SelectTargetCell({period, target, teamMember} : TargetCellProps)  {
  const { user } = useAuth();

  const [lastSent, setLastSent] = useState<TargetSelectOption>();

  const lastLog = target?.logs?.[target?.logs?.length - 1];
  const [logAction, { loading }] = useMutation(LOGACTION, {
    // refetchQueries: [RESULTS_QUERY, 'ResultsForTeam'],
  });

  function sendLog(selectedOption: TargetSelectOption) {
    logAction({
      variables: {
        action: teamMember?.actions?.[0],
        valueSelect: selectedOption.id,
        time: moment(target?.time).add(2, 'day'),
        target: target?.id,
        teamMember: teamMember?.id,
        userId: teamMember?.user?.id,
      },
    }).then(({ data }) => {
      if (data.logNewAction.message) {
        alert(data.logNewAction.message);
      }

      if (data.logNewAction.success) {
        // setSuccess(true);
        setLastSent(selectedOption);
      }
    });
  }

  return (
    <td
      key={`td-team-prod-dash-${teamMember.id}-${period}`}
      className="text-left relative"
    >
      <div className="text-gray-400 text-sm mx-3">
        <ObjectiveStateSelector
          states={teamMember?.targetSelectOptions || []}
          value={lastSent || teamMember?.targetSelectOptions?.find((l) => lastLog?.valueSelected?.id === l.id)}
          onChange={sendLog}
          loading={loading}
          readOnly={!user?.isAdmin}
        />
      </div>
    </td>
  );
}


export default function TeamDashComponent({ team, periodicity }: Props) {
  const options = useMemo(() => {
    const thisMoment = moment().startOf(periodicity as moment.unitOfTime.StartOf);
    return [
      {
        label: `Last ${periodicity} & next 4 ${periodicity}s`,
        value: {
          before: moment(thisMoment).add(5, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment).subtract(2, periodicity as moment.unitOfTime.DurationAs),
          id: 0,
        },
      },
      {
        label: `Last 6 ${periodicity}s`,
        value: {
          before: moment(thisMoment).add(1, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment).subtract(6, periodicity as moment.unitOfTime.DurationAs),
          id: 1,
        },
      },
      {
        label: `Last 12 ${periodicity}s`,
        value: {
          before: moment(thisMoment).add(1, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment).subtract(12, periodicity as moment.unitOfTime.DurationAs),
          id: 2,
        },
      },
      {
        label: `Next 6 ${periodicity}s`,
        value: {
          before: moment(thisMoment).add(6, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment).subtract(1, periodicity as moment.unitOfTime.DurationAs),
          id: 3,
        },
      },
      {
        label: `Next 12 ${periodicity}s`,
        value: {
          before: moment(thisMoment).add(12, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment).subtract(1, periodicity as moment.unitOfTime.DurationAs),
          id: 4,
        },
      },
      {
        label: "This year",
        value: {
          before: moment(thisMoment).endOf("year").add(1, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment).startOf("year").subtract(1, periodicity as moment.unitOfTime.DurationAs),
          id: 5,
        },
      },
      {
        label: "Last year",
        value: {
          before: moment(thisMoment).startOf("year").add(1, periodicity as moment.unitOfTime.DurationAs),
          after: moment(thisMoment)
            .startOf("year")
            .subtract(1, "year")
            .subtract(1, periodicity as moment.unitOfTime.DurationAs),
          id: 6,
        },
      },
    ];
  }, [periodicity])

  const [selectedPeriod, setSelectedPeriod] = useState(options[0]);
  const { data: teamsData, loading, refetch } = useQuery(RESULTS_QUERY, {
    variables: {
      after: selectedPeriod.value.after,
      before: selectedPeriod.value.before,

      nextMonth: moment().endOf(periodicity as moment.unitOfTime.StartOf),
      prevMonth: moment().startOf(periodicity as moment.unitOfTime.StartOf),
    },
  });

  const periods = useMemo(() => {
    const { after, before } = selectedPeriod?.value;

    const len = findPeriodId(before.toDate(), after.toDate(), periodicity) + 1;
    const array = [];
    for (let i = 1; i < len; i++) {
      const date = moment(after).add(i, periodicity as moment.unitOfTime.DurationAs);
      array.push(formatForPeriodicity(date, periodicity));
    }
    refetch({
      after,
      before,
    })
    return array;
  }, [selectedPeriod, periodicity, refetch]);

  const targetsForUsers = useMemo(() => {
    const targetsUsers = new Map<string, Target[]>();
    if (!teamsData?.resultsForTeam) return targetsUsers;

    const { targets, after } = teamsData?.resultsForTeam;
    team?.members
      ?.filter((m) => !m.user.deleted)
      .forEach((member) => {
        const periodsForUser = new Array<Target>(periods.length); // Create array of right size

        // Get targets for crrent user
        const targetsToPlaceInPeriods = targets.filter(
          (target: Target) => target.teamMember.id === member.id
        );
        targetsToPlaceInPeriods.forEach((target: Target) => {
          periodsForUser[findPeriodId(target.time, after, periodicity)] = target;
        });
        targetsUsers.set(`${member.id}`, periodsForUser);
      });

    return targetsUsers;
  }, [team, teamsData, periods.length, periodicity]);

  if (!team) return null;
  if (loading) return <div>Loading...</div>;

  return (
    <div className="w-screen -mr-8 lg:mr-3 lg:w-auto relative">
      <div className="flex justify-between items-center ">
        <h4 className="text-title font-bold text-xl">{team.name}</h4>
        <div className="flex">
          <FormSelectInput 
            options={options.map(t => ({value: `${t.value.id}`, text: t.label}))}
            onChange={(va) => {
              const selected = options.find((t) => va.target.value === `${t.value.id}`);
              if (selected) setSelectedPeriod(selected);
            }}
            value={selectedPeriod.value.id}
          />
        </div>
      </div>

      <div className="w-fit mx-0 overflow-x-scroll flex" id="teamResults">
        <table className="table-auto flex-1 mt-3 shadow-md rounded-lg bg-white" style={{ tableLayout: 'fixed', width: '100%'}} >
          <thead className="">
            <tr>
              <th className="text-center p-4 rounded-tl-lg bg-tablehead"></th>
              {periods.map((period, i, a) => (
                <th key={`periods-${team.id}-${period}`} className={`font-medium text-gray-500 text-center p-4 bg-tablehead ${a.length-1 === i ? 'rounded-tr-lg' : ''}`}>
                  <h3 className="">{period}</h3>
                </th>
              ))}
            </tr>
          </thead>

          {team?.members?.sort((a, b) => {
            if (a.user.name !== b.user.name) {
              return a.user.name.localeCompare(b.user.name);
            }
          
            // If names are the same, compare by inputType using the custom order
            const inputTypeOrderA = inputTypeOrder[a.inputType || 'NUMERIC'];
            const inputTypeOrderB = inputTypeOrder[b.inputType || 'NUMERIC'];

            if (inputTypeOrderA !== inputTypeOrderB) {
              return inputTypeOrderA - inputTypeOrderB;
            }
          
            // If inputTypes are the same, compare by kpiName
            return a.kpiName.localeCompare(b.kpiName);
          }).map((t: TeamMember, index, array: TeamMember[]) => {
            const targets = targetsForUsers.get(`${t.id}`);
            if (!targets) return null;

            return (
              <tbody
                key={`prodash-column-tm-${t?.user?.id}-${t.id}`}
                className="font-normal"
              >
                <tr className="" >
                  <th className="text-center font-normal px-2">
                    <Link to={`/profile/${t?.user?.id}`}>
                      {(index === 0 || (t.user?.id !== array[index - 1]?.user?.id)) ? (<>
                        <img
                          src={t?.user?.picture}
                          className="rounded-full max-w-24 max-h-24 m-3 bg-white mx-auto"
                          alt={t?.user?.name}
                        />
                        <h3 className="text-md mb-2">{t?.user?.name}</h3>
                        <span className="text-sm font-medium">{t?.kpiName}</span>
                      </>) : (<div className="text-sm font-medium my-8">{t?.kpiName}</div>)}
                    </Link>
                  </th>

                  {periods.map((period, index) => {
                    const target = targets[index];
                    if (t?.inputType === 'SELECT') return (<SelectTargetCell period={period} target={target} teamMember={t} />)
                    if (t?.inputType === 'TEXT') return (<TextTargetCell period={period} target={target} teamMember={t} />)
                    if (t?.inputType === 'NUMERIC') return (<NumericTargetCell period={period} target={target} teamMember={t} />)
                    return null
                  })}
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
}
