import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from "react";

import useAuth from "../hooks/useAuth";
import MainMenu from "./MainMenu";
import logo from "../assets/scaleurs.png";
import NotificationTray from "./NotificationsTray";



export default function PrivateRoute() {
  const { authed, user, logout } = useAuth();
  const location = useLocation();
  
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      setVisible(false);
    }, [location]);


  // If auth isn't initialized yet, don't do anything
  if (authed === null) return <Outlet />;
  if (!authed) return <Navigate to="/login" state={{ error: "Not logged in" }} />;

  return (
    <>
      <div className="primary-box text-title font-bold flex items-center justify-between sticky top-0 z-20 lg:relative">
        <div className="flex space-x-2">

          <button onClick={() => setVisible(!visible)} className="block lg:hidden">
            <FaBars />
          </button>
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              className="h-10 w-10 lg:h-16 lg:w-16 rounded-full mr-4"
              alt="Yood Logo"
            />
          </Link>
        </div>

        {!!user && (
          <>
            <h2 className="text-title text-3xl hidden lg:block">
              Hello {user.name?.split(" ")[0] || ""} !
            </h2>
            <div className="items-center flex space-x-3">
              <NotificationTray />

              <div className="items-center relative group">
                <img
                  src={user.picture}
                  className={`rounded-full bg-gray-200 ml-4 h-8 w-8 lg:h-16 lg:w-16 text-center`}
                  alt="Profile"
                />

                <div
                  className={`w-52 p-5 text-left bg-white rounded-md absolute right-0 font-normal border hidden z-10 group-hover:block`}
                >
                  <ul>
                    <li className="text-text">{user.name}</li>
                    <li className="text-text">{user.role}</li>
                    <li className="pt-4 border-b mb-4"></li>
                    <li>
                      <Link to={`/profile/${user.id}`}>My profile</Link>
                    </li>
                    <li>
                      <Link to="/modal/changePassword">Change password</Link>
                    </li>
                    <li className="pt-4 border-b mb-4"></li>

                    <li>
                      <button
                        className="text-red-600 font-semibold"
                        onClick={logout}
                      >
                        Log out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-row relative w-full">
        <MainMenu visible={!visible} />

        <div className="flex flex-1">
          <Outlet />
        </div>
      </div>
    </>
  );
}
