import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Alert from "../components/Alert";
import { useNavigate, useParams } from "react-router-dom";
import LoginWrapper from "../components/wrappers/LoginWrapper";
import { logEvent } from "../hooks/analytics";

type FormValues = {
  new: string;
  confirm: string;
};

const UPDATE_MMUTATION = gql`
  mutation resetPassword($token: String!, $password: String!) {
    mut: resetPassword(token: $token, password: $password) {
      success
      message
    }
  }
`;

function ResetPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const params = useParams();
  const navigate = useNavigate();

  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");

  const [updatePassword, { loading: updateLoading }] =
    useMutation(UPDATE_MMUTATION);

  async function onSubmit(data: FormValues) {
    setSuccess(false);
    setError("");

    try {
      const result = await updatePassword({
        variables: {
          token: params.token,
          password: data.new,
        },
      });

      logEvent('resetPassword2');
      if (result.data?.mut?.success) return setSuccess(true);
      alert(result.data?.mut?.message);
    } catch (e: any) {
      setError(e.message);
    }
  }

  return (
    <LoginWrapper>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="text-3xl mb-10 text-gray-800">
          Set a new password
        </h1>
        <Alert
          type="success"
          display={success}
          autoDismiss={3000}
          onDismiss={() => navigate('/login')}
        >
          Password changed !
        </Alert>

        <Alert
          type="error"
          display={!!error.length}
        >
          {error}
        </Alert>

        <FormInput
          label="New password"
          type="password"
          error={errors.new?.message}
          {...register("new", {
            required: true,
          })}
        />

        <FormInput
          label="Confirm password"
          type="password"
          error={errors.confirm?.message}
          {...register("confirm", {
            required: true,
          })}
        />

        {updateLoading ? <div>Loading...</div> : <Button>Save</Button>}
      </form>
    </LoginWrapper>
  );
}

export default ResetPasswordPage;
