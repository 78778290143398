import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, ArrowPathIcon } from "@heroicons/react/20/solid";

function classNames({ classes = [] }: { classes?: any[] } = {}) {
  return classes.filter(Boolean).join(" ");
}

type InputProps = {
  onChange?: ((value: OKRState) => void) | undefined,
  value?: OKRState,
  states: OKRState[] | TargetSelectOption[]
  loading?: boolean,
  readOnly?: boolean,
}

export default function ObjectiveStateSelector({onChange, value, states, loading, readOnly}: InputProps) {
  return (
    <Listbox onChange={onChange} value={value} disabled={readOnly}>
      {({ open }) => (
        <>
          <div className="relative">
            <div className="w-full flex rounded-md shadow-sm">
              <div className={`flex-grow items-center text-left gap-x-1.5 ${readOnly ? 'rounded-md' : 'rounded-l-md'} px-3 py-2 shadow-sm text-sm ${value?.text ? 'font-semibold' : 'text-gray-400'} truncate overflow-ellipsis`} style={{ backgroundColor: value?.color, color: value?.textColor  }} >
                {value?.text || (readOnly ? 'Not set' : 'Select a value')}
              </div>
              {!readOnly && <Listbox.Button style={{ backgroundColor: value?.color, color: value?.textColor  }} className="items-center rounded-l-none rounded-r-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                <span className="sr-only">Change state</span>
                {loading ? (
                  <ArrowPathIcon
                    className="h-5 w-5 animate-spin"
                    style={{ color: value?.textColor }}
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="h-5 w-5"
                    style={{ color: value?.textColor }}
                    aria-hidden="true"
                  />
                )}
              </Listbox.Button>}
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-auto min-w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {states?.map((option) => (
                  <Listbox.Option
                    key={option.text}
                    className={({ active }) =>
                      classNames({
                        classes: [
                          active ? "text-white" : "text-gray-900",
                          "cursor-pointer select-none p-4 text-sm",
                        ],
                      })
                    }
                    style={{ backgroundColor: option.color, color: option.textColor }}
                    value={option}
                  >
                    {({ selected, active }) => (
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.text}
                          </p>
                          {selected ? (
                            <span
                                style={{ color: option.textColor }}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            </div>
        </>
      )}
    </Listbox>
  );
}
