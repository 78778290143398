import React, { useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import { formatForPeriodicity } from "../libs/formatFunctions";
import { logEvent } from "../hooks/analytics";

const LOGACTION = gql`
  mutation Mutation($reason: ID!, $target: ID!, $note: String) {
    logTargetIssueReason(reason: $reason, target: $target, note: $note) {
      success
      message
    }
  }
`;

const PENDING_CAMPAIGNS_QUERY = gql`
query CampaingsQuery {
  needTargetIssue {
    reasons {
      id
      name
      phrase
      noteMandatory
    }
    target {
      id
      value
      logs {
        value
      }
      logTotal
      teamMember {
        kpiName
        kpiUnit
        team {
          kpiName
          kpiUnit
        }
      }
    }
  }
}
`;


type FormValues = {
  reason: string;
  note: string;
};


function TargetIssueModal() {
  const { user } = useAuth();
  const {register, handleSubmit, watch} = useForm<FormValues>();

  const [success, setSuccess] = React.useState(false);
  const [logTargetIssue, { loading }] = useMutation(LOGACTION);
  const { data: pendingCampaigns, refetch: refetchCampaigns } = useQuery(PENDING_CAMPAIGNS_QUERY);

  const selectedReason = useMemo(() => {
    return pendingCampaigns?.needTargetIssue?.reasons.find((r:TargetIssueReason) => r.id === watch("reason"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('reason'), pendingCampaigns])


  function onSubmit(data:FormValues) {
    const { reason, note } = data;

    const target = pendingCampaigns?.needTargetIssue?.target?.id;

    logEvent('logObstacle', { reason, target });

    logTargetIssue({
      variables: {
        reason,
        target,
        note,
      },
    }).then(({ data }) => {
      if (data.logTargetIssueReason.success) setSuccess(true);
    }).catch(e => console.error(e));
  }


  if (!pendingCampaigns?.needTargetIssue) return null;
  if (!user) return null;

  const target = pendingCampaigns?.needTargetIssue?.target;
  
  const monthName = formatForPeriodicity(target.time, user.company.periodicity);
  // const kpiUnit = campaignTeamMember.kpiUnit || campaignTeamMember.team.kpiUnit;
  // const kpiName = campaignTeamMember.kpiName || campaignTeamMember.team.kpiName;

  return (
    <Modal title={`What stopped you from reaching your target in ${monthName}?`} autoHeight closeFunction={() => refetchCampaigns()} cannotClose>
      <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
        <Alert type="success" autoDismiss={3000} display={success} onDismiss={() => refetchCampaigns()}>Obstacle logged successfully!</Alert>

        <div className="my-5">
          <ul>
          {pendingCampaigns?.needTargetIssue.reasons.map((issueAction: TargetIssueReason) => {
            return (
              <li key={`team-button-${issueAction?.id}`} className="m-4">
                <input 
                  type="radio"
                  id={`team-button-${issueAction?.id}`}
                  key={`team-button-${issueAction?.id}`}
                  className="cursor-pointer"
                  value={issueAction?.id}
                  {...register("reason", { required: true })}
                />
                <label htmlFor={`team-button-${issueAction?.id}`} className="ml-3 cursor-pointer">{issueAction.phrase || issueAction.name}</label>
              </li>
            );
          })}
          </ul>

          <div className="mx-2 my-10 max-w-lg">
            <FormInput
              label={`Add a comment ${selectedReason?.noteMandatory ? "(required)" : "(optional)"}`}
              type="text"
              placeholder="Want to give more details on your obstacles?"
              required={!!selectedReason?.noteMandatory}
              {...register("note", { required: !!selectedReason?.noteMandatory })}
            />
          </div>
        </div>

        {loading && <div>Loading...</div>}

        <div className="flex justify-center">
          <Button>Submit obstacle</Button>
        </div>
      </form>
    </Modal>
  );
}

export default TargetIssueModal;
