import moment from "moment";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { getColor } from "./TeamDashComponent";

interface Props {
  teams: Team[];
  targets: Target[];
  before: Date;
  after: Date;
  loading?: boolean;
  periodicity?: string;
}

export default function TeamDashComponentForTeams({
  teams,
  targets,
  periodicity = 'month',
  loading,
}: Props) {
  const navigate = useNavigate();

  const targetsForTeams = useMemo(() => {
    const targetsTeams = new Map<string, {logTotal: number, value: number}>();

    teams.forEach((team) => {
      // Get targets for crrent user
      const targetsToPlaceInPeriods = targets.filter(
        (target: Target) => target.teamMember.team.id === team.id
      );

      const forTeam = targetsToPlaceInPeriods.reduce((acc, target: Target) => {
        if (!moment().isSame(target.time, periodicity as moment.unitOfTime.StartOf)) return acc;
        return { logTotal: (acc?.logTotal || 0) + target.logTotal, value: (acc?.value || 0) + target.value };
      }, {logTotal: 0, value: 0});
      targetsTeams.set(`${team.id}`, forTeam);
    });
    return targetsTeams;
  }, [teams, targets, periodicity]);

  if (!teams) return null;
  if (loading) return <div>Loading...</div>;

  return (
    <div className="primary-box mx-0 relative" id="overview">
      <h4 className="text-title font-bold text-lg">Overview</h4>
      <table className="w-full p-3 table-fixed" id="overviewGraph">
        <tr className={`p-3 cursor-pointer`}>
          {teams?.map((t: Team) => {
            const target = targetsForTeams.get(`${t.id}`);
            if (!target) return null;

            const done = target?.logTotal || 0;
            const doneG = Math.min(
              100,
              Math.max(0, (100 * done) / (target?.value || 1))
            );
            const data = [
              { name: "Done", value: doneG },
              { name: "To Do", value: 100 - doneG },
            ];
            const percentage = (100 * done) / (target?.value || 1);
            return (
              <td
                key={`dashForTeam-${t.id}`}
                className="text-center cursor-pointer"
                onClick={() => navigate(`/${t?.id}#overview`)}
              >
                <ResponsiveContainer width="100%" height={150} className="cursor-pointer">
                  <PieChart width={50} height={50} className="cursor-pointer">
                    <Pie
                      data={data}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={45}
                      outerRadius={50}
                      startAngle={90}
                      endAngle={-270}
                      isAnimationActive={false}
                      cursor="pointer"
                      >
                      {!!target && (
                        <Label
                          value={`${Math.round(percentage)}%`}
                          position="center"
                          cursor="pointer"
                          fill={getColor(percentage)}
                        />
                      )}
                      <Cell
                        fill={getColor(percentage)}
                      />
                      <Cell fill="#DDDDDD" />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </td>
            );
          })}
        </tr>
        <tr>
          {teams?.map((t: Team) => (
            <td className="text-center" key={`period-name-${t.id}`}>
              <h3 className="">{t.name}</h3>
            </td>
          ))}
        </tr>
      </table>
    </div>
  );
}
