import { useMemo } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { getColor } from "./TeamDashComponent";

import bgLeft from "../assets/bg-share-l.svg";
import bgRight from "../assets/bg-share-r.svg";

interface OverrachievedComponentProps {
  target: Target;
  style?: any;
  id?: string;
}

export default function OverrachievedComponent(
  props: OverrachievedComponentProps
) {
  const { target, id, style } = props;

  const pieData = useMemo(() => {
    const done = target?.logTotal || 0;
    const doneG = Math.min(
      100,
      Math.max(0, (100 * done) / (target?.value || 1))
    );
    return [
      { name: "Done", value: doneG },
      { name: "To Do", value: 100 - doneG },
    ];
  }, [target]);

  return (
    <div
      className="m-auto relative rounded-lg overflow-hidden"
      style={{ aspectRatio: "1.9", maxWidth: 800, ...style }}
    >
      <div className=" h-full flex-col self-center my-4 relative" style={{zIndex: 10, background: "#BDE0FE" }} id={id || "overachievedImage"}>

      <img className="absolute rounded-tl-lg left-0 top-0 h-full z-0" style={{ zIndex: 0 }} src={bgLeft} alt="Background left" />
      <img className="absolute rounded-tr-lg right-0 top-0 h-full z-0" style={{ zIndex: 0 }} src={bgRight} alt="Background right" />

        <div className="text-center pt-10 pb-8 text-title z-10">
          <h2 className="pb-4 text-3xl font-medium">
            Congrats, {target.teamMember.user.name} !
          </h2>
          <h4 className="text-lg font-medium">
            Overachieved this month !
          </h4>
        </div>

        <div className="flex flex-row justify-center space-x-16 z-10">
          <div className="text-center justify-center space-y-3">
            <img
              src={target.teamMember.user.picture}
              className="h-32 w-32 rounded-full mx-auto mb-6"
              alt={target.teamMember.user.name}
            />
            <div className="text-xl font-bold">
              {target.teamMember.user.role}
            </div>
          </div>

            <ResponsiveContainer width={180} height={180}>
              <PieChart width={120} height={120}>
                <Pie
                  data={pieData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={75}
                  outerRadius={90}
                  startAngle={90}
                  endAngle={-270}
                  isAnimationActive={false}
                >
                  {!!target && (
                    <Label
                      value={`${Math.round(
                        (100 * target.logTotal) / (target?.value || 1)
                      )}%`}
                      position="center"
                      fontSize={32}
                    />
                  )}
                  <Cell
                    fill={getColor(
                      (100 * target.logTotal) / (target?.value || 1)
                    )}
                  />
                  <Cell fill="#DDDDDD" />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
        </div>

      </div>
    </div>
  );
}
