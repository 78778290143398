import { useNavigate } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";

import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import IconButton from "../components/IconButton";
import { FaPen, FaPlus, FaTrash, FaUsers } from "react-icons/fa";

export const TEAMS_QUERY = gql`
  query teams {
    teams(onlyPro: true) {
      id
      name
      members {
        id
        user {
          name
          id
        }
      }
      actions {
        id
        name
      }
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      code
      success
    }
  }
`;

function AdminTeamsPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: teamsData } = useQuery(TEAMS_QUERY);
  const [deleteTeam] = useMutation(DELETE_MUTATION, {
    refetchQueries: [TEAMS_QUERY, "teams"],
  });

  async function _delete(id: ID) {
    if (window.confirm("Are you sure you want to delete this team?")) {
      await deleteTeam({ variables: { id } });
    }
  }

  if (!user?.isAdmin)
    return (
      <Alert type="error" display>
        You are not logged in or not admin.
      </Alert>
    );

  return (
    <div className="w-full p-4">
      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <div className="flex content-between">
          <div className="w-full">
            <h1 className="text-2xl pb-4 font-bold font-sans">
              Teams administration
            </h1>
          </div>
          <IconButton
            icon={FaPlus}
            onClick={() => navigate(`/modal/editTeam/`)}
            title="Create new team"
          />
        </div>

        {!teamsData?.teams?.length ? (
          <p className="text-center p-4">
            No team yet. Please use the "+" button to create one.
          </p>
        ) : (
          <div className="p-4 bg-white w-full shadow-sm rounded-lg">
            <table className="table-auto w-full">
              <thead>
                <tr className="text-center text-lg">
                  <th className="pb-5 pt-3">Name</th>
                  <th className="pb-5 pt-3">Members</th>
                  <th className="pb-5 pt-3">Default actions</th>
                  <th className="pb-5 pt-3 text-right"></th>
                </tr>
              </thead>
              <tbody>
                {teamsData?.teams?.map((team: Team) => (
                  <tr className="text-left" key={`users-${team.id}`}>
                    <td className="font-bold pb-3">{team.name}</td>
                    <td className="pb-3">
                      {team.members?.length
                        ? team.members
                            .map((member) => member.user.name)
                            .join(", ")
                        : "No members yet"}
                    </td>

                    <td className="pb-3">
                      {team.actions?.length
                        ? team.actions
                            .map((action) => action.name)
                            .join(", ")
                        : "No default actions"}
                    </td>
                    <td className="pb-3 text-right whitespace-nowrap">
                      <IconButton
                        icon={FaUsers}
                        onClick={() =>
                          navigate(`/modal/editTeam/members/${team.id}`)
                        }
                        title="Edit members"
                      />
                      <IconButton
                        icon={FaPen}
                        onClick={() => navigate(`/modal/editTeam/${team.id}`)}
                        title="Edit team"
                      />
                      <IconButton
                        icon={FaTrash}
                        onClick={() => _delete(team.id)}
                        title="Delete team"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminTeamsPage;
