import React from "react";
import { useParams } from "react-router";

import Button from "../components/Button";
import Modal from "../components/Modal";
import TargetDetailsComponent from "../components/TargetDetailsComponent";
import GoogleSheetComponent from "../components/GoogleSheetComponent";
import TargetHistoricComponent from "../components/TargetHistoricComponent";
import ObstaclesComponent from "../components/ObstaclesComponent";
import ObjectivesComponent from "../components/ObjectivesComponent";

function TargetLogsModal() {
  const { teamMember, target } = useParams();
  const [tab, setTab] = React.useState<string>("performance");

  return (
    <Modal title="Target &amp; logs details">
      <div className="p-4">
        <div className="flex align-middle pb-6 space-x-2">
          <Button onClick={() => setTab("performance")}>Performance</Button>
          <Button onClick={() => setTab("objectives")}>Objectives</Button>
          <Button onClick={() => setTab("details")}>Details</Button>
          <Button onClick={() => setTab("google")}>Google spreadsheet</Button>
          <Button onClick={() => setTab("obstacles")}>Obstacles</Button>
        </div>

        {tab === "performance" && (<TargetHistoricComponent teamMember={teamMember} time={new Date()} />)}
        {tab === "details" && <TargetDetailsComponent target={target} />}
        {tab === "google" && (<GoogleSheetComponent teamMember={teamMember} />)}
        {tab === "obstacles" && (<ObstaclesComponent teamMember={teamMember} />)}
        {tab === "objectives" && (<ObjectivesComponent teamMember={teamMember} />)}

      </div>
    </Modal>
  );
}

export default TargetLogsModal;
