import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Alert from "../components/Alert";
import LoginWrapper from "../components/wrappers/LoginWrapper";
import { Link } from "react-router-dom";
import { logEvent } from "../hooks/analytics";

type FormValues = {
  email: string;
};

const UPDATE_MMUTATION = gql`
  mutation askPasswordReset($email: String!) {
    mut: askPasswordReset(email: $email) {
      success
      message
    }
  }
`;

function ForgotPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");

  const [updatePassword, { loading: updateLoading }] =
    useMutation(UPDATE_MMUTATION);

  async function onSubmit(data: FormValues) {
    setSuccess(false);
    setError("");
  
    try {
      const result = await updatePassword({
        variables: {
          email: data.email,
        },
      });

      logEvent('resetPassword');

      if (result.data?.mut?.success) return setSuccess(true);
      setError(result.data?.mut?.message);
    } catch(e: any) {
      setError(e.message);
    }
  }

  return (
    <LoginWrapper>
      <form
        className="w-full m-auto p-1 lg:p-3 space-y-5 max-w-sm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="text-3xl mb-10 text-gray-800">
          Forgot your password ?
        </h1>
        <Alert
          type="success"
          display={success}
          onDismiss={() => setSuccess(false)}
        >
          You'll receive a reset link by email !
        </Alert>

        <Alert
          type="error"
          display={!!error}
        >
          {error}
        </Alert>


        <FormInput
          label="Email address of your account"
          type="email"
          error={errors.email?.message}
          {...register("email", {
            required: true,
          })}
        />

        {updateLoading ? <div>Loading...</div> : <Button className="inline-block w-full">Ask new password</Button>}


      <Link to="/login" className="block text-sm pt-6 text-center">
        Back to login
      </Link>
      </form>

    </LoginWrapper>
  );
}

export default ForgotPasswordPage;
