import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import Modal from "../components/Modal";
import moment from "moment";
import TargetInput from "../components/TargetInput";
import Alert from "../components/Alert";
import Button from "../components/Button";
import useAuth from "../hooks/useAuth";
import { formatForPeriodicity } from "../libs/formatFunctions";

export const QUERY_TARGETS = gql`
  query TargetsForUser($user: ID) {
    targetsForUser(user: $user, onlyPro: true) {
      id
      team {
        id
        name
      }
      targets {
        id
        time
        value
        cumulPrevious
      }
      kpiName
      kpiUnit
    }
  }
`;

function UserEditTargetsModal() {
  const params = useParams();
  const { user } = useAuth();

  // const [success, setSuccess] = React.useState(false);

  const { data: targets /*, loading */ } = useQuery(QUERY_TARGETS, {
    variables: { user: params.id },
  });

  const periods = useMemo(() => {
    if (!targets?.targetsForUser) return [];
    const periodicity = (user?.company?.periodicity || 'month') as moment.unitOfTime.DurationAs;

    // Get max é min times for the different targets
    let minTime = moment().subtract(6, periodicity);
    let maxTime = moment().add(6, periodicity);
    targets.targetsForUser.forEach((teamMember: TeamMember) => {
      teamMember.targets.forEach((target) => {
        const time = moment(target.time);
        if (time.isBefore(minTime)) minTime = time;
        if (time.isAfter(maxTime)) maxTime = time;
      });
    });

    // Generate months between first target and next month
    const periods = [];
    let currentPeriod = minTime;
    while (currentPeriod.isBefore(maxTime)) {
      periods.push(currentPeriod);
      currentPeriod = currentPeriod.clone().add(1, periodicity);
    }

    periods.push(currentPeriod); // Next month

    return periods;
  }, [targets, user]);

  if (!targets?.targetsForUser || !periods) return null;

  return (
    <Modal title="Edit targets of user">
      <div className="p-4">
        {targets?.targetsForUser.length ? (<table className="table-auto w-full">
          <thead>
            <tr className="text-left">
              <th className="pb-5 pt-3">Period</th>
              {targets.targetsForUser.map((teamMember: TeamMember) => (
                <th className="pb-5 pt-3 text-center">
                  <strong className="block">{teamMember.team.name}</strong>
                  <span className="font-thin">{teamMember.kpiName || '-'}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {periods.map((period) => (
              <tr className={`text-left text-lg ${!period.month() ? 'border-t-2 mt-4' : ''}`}>
                <th>{formatForPeriodicity(period, user?.company.periodicity)}</th>
                {targets.targetsForUser.map((teamMember: TeamMember) => (
                  <th className="p-2">
                    <TargetInput period={period} teamMember={teamMember} periodicity={user?.company?.periodicity} />
                  </th>
                ))}
              </tr>
            ))}
          </tbody>
        </table>) : (
        <>
          <Alert display type="error">This user has no team yet. Please assign a team first.</Alert>
          <Link to="/admin/teams"><Button>Go to Team admin</Button></Link>
        </>
        )}
      </div>
    </Modal>
  );
}

export default UserEditTargetsModal;
