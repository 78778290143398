import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import useAuth from "../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { getColor } from "../components/TeamDashComponent";
import { sortTargetsByPercent } from "../libs/SortFunctions";

const LEADERBOARD_QUERY = gql`
  query LeaderBoardForTeamOfTarget($target: ID!) {
    leaderboard: leaderBoardForTeamOfTarget(target: $target) {
      value
      id
      logTotal
      teamMember {
        kpiName
        kpiUnit
        user {
          name
          picture
          id
        }
      }
    }

    ranking(target: $target) {
      company
      team
    }
  }
`;

/*
const percentages = [
  { start: 0, end: 40, color: "#c7264e" },
  { start: 40, end: 60, color: "#d18400" },
  { start: 60, end: 80, color: "#d1ae00" },
  { start: 80, end: 95, color: "#048200" },
  { start: 95, end: 100, color: "#07de00" },
];
*/

let redirectTriggered = false;

function LeaderboardForTarget() {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(LEADERBOARD_QUERY, {
    variables: { target: params.target },
  });

  useEffect(() => {
    if (user && data?.leaderboard?.length) {
      const resultOfUser = data.leaderboard.find(
        (t: Target) => t.teamMember.user.id === user.id
      );

      if (
        resultOfUser &&
        resultOfUser.logTotal > resultOfUser.value &&
        !redirectTriggered
      ) {
        redirectTriggered = true;
        // setTimeout(() => navigate(`/overachieved/${resultOfUser.id}`), 5000);
      }
    }
  }, [user, data?.leaderboard, navigate]);

  if (!user) return null;
  if (loading) return <div>"Loading screen..."</div>;
  if (!data) return null;
  return (
    <div
      role="button"
      className="fixed top-0 left-0 right-0 bottom-0 bg-gray-400 bg-opacity-80 z-20"
      onClick={() => navigate(-1)}
    >
      <div
        role="button"
        className={`bg-white m-2 lg:w-2/3 lg:m-auto lg:mt-20 rounded shadow-md cursor-default`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row lg:space-x-24 w-full h-1/4">
          <div className="flex-1 max-w-1/2 flex items-center py-10 ">
            <div className="w-full text-gray-800">
              <div className="mx-auto">
                <img
                  src={user.picture}
                  className="mx-auto text-center border h-24 w-24 rounded-full"
                  alt={user.name}
                />
                <h2 className="text-center pt-12 text-2xl">{user.name}</h2>
                <h5 className="text-center pt-6 pb-12 text-lg">{user.role}</h5>
              </div>

              <div className="flex">
                <div className="w-1/2 text-center">
                  <h3 className="text-4xl font-bold">{data.ranking.company}</h3>
                  <div className="text-md">Company rank</div>
                </div>

                <div className="w-1/2 text-center">
                  <h2 className="text-4xl font-bold">{data.ranking.team}</h2>
                  <div className="text-md">Team rank</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 w-auto overflow-y-scroll bg-gray-50">
            <table className="pl-2 table-auto w-full border-collapse rounded-lg">
              {data?.leaderboard
                ?.sort(sortTargetsByPercent)
                .map((target: Target, i: number) => {
                  const isUser = target.teamMember.user.id === user.id;
                  return (
                    <tr
                      key={`table-line-${target.teamMember.user.id}`}
                      className={`px-4 ${
                        isUser
                          ? "text-white bg-primary"
                          : "text-gray-800 "
                      }`}
                    >
                      <td className="py-3 pl-6 font text-xl">{i + 1}.</td>
                      <td className="py-3 pl-3 pr">
                        <img
                          src={target.teamMember.user.picture}
                          className="h-12 w-12 rounded-full"
                          alt={target.teamMember.user.name}
                        />
                      </td>
                      <td className="py-2">
                        <div className="font-medium text-lg pb-1">
                          {`${target.teamMember.user.name}${
                            isUser ? " (You)" : ""
                          }`}
                        </div>
                        <div
                          title={target.teamMember.kpiName}
                        >{`${target.logTotal} / ${target.value}`}</div>
                      </td>
                      <td className="py-3 pr-2 font-medium text-xl text-right">
                        {Math.round((100 * target.logTotal) / target.value) ||
                          0}
                        %
                      </td>

                      <td className="py-3 relative m-4 w-6">
                        <div
                          className="absolute top-3 bottom-3 left-0 right-0 w-2 rounded-full"
                          style={{
                            backgroundColor: getColor(
                              (100 * target.logTotal) / target.value
                            ),
                          }}
                        >
                          {" "}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
        <div className="border-t border-gray-200 bg-gray-50 p-4 justify-end flex">
          <Button onClick={() => navigate(-1)}>Back to dashboard</Button>
        </div>
      </div>
    </div>
  );
}

export default LeaderboardForTarget;
