import React, { useMemo } from "react";
import { useParams, useNavigate } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import Creatable from "react-select/creatable";
import Select from "react-select";

import Alert from "../components/Alert";
import Button from "../components/Button";
import Modal from "../components/Modal";
import FormInput from "../components/FormInput";
import { Controller, useForm } from "react-hook-form";
import { TEAMS_QUERY } from "./AdminTeamsPage";
import User from "../interfaces/User";
import FormGenericInput from "../components/FormSelectableInput";
import { MEMBERS_QUERY } from "./AdminTeamMembersPage";

type FormValues = {
  user: string;
  countsInTeamResult: boolean;
  actions: string[];
  kpiName: string;
  kpiUnit: string;
  isManager: boolean;
};

const QUERY_TEAM = gql`
  query teamMemberById($id: ID!, $teamId: ID!) {
    teamMemberById(id: $id) {
      id
      user {
        id
        name
      }
      actions {
        id
        name
      }
      kpiName
      kpiUnit
      countsInTeamResult
      isManager
    }

    getTeamById(id: $teamId) {
      id
      name
      kpiName
      kpiUnit
      members {
        id
        user {
          id
        }
      }
      actions {
        id
        name
      }
    }

    actions {
      id
      name
    }

    users {
      id
      name
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation assignTeam(
    $teamMember: ID,
    $user: ID!
    $team: ID!
    $actions: [ActionInput!]
    $countsInTeamResult: Boolean
    $isManager: Boolean
    $kpiName: String!
    $kpiUnit: String!
  ) {
    assignTeam(
      teamMember: $teamMember,
      user: $user
      team: $team
      actions: $actions
      countsInTeamResult: $countsInTeamResult
      isManager: $isManager
      kpiName: $kpiName
      kpiUnit: $kpiUnit
    ) {
      success
      message
    }
  }
`;

const CREATE_ACTION_MUTATION = gql`
  mutation createAction($name: String!) {
    createAction(name: $name) {
      success
      message
      id
    }
  }
`;

function TeamMemberEditModal() {
  const params = useParams();
  const navigate = useNavigate();

  const [success, setSuccess] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormValues>();

  const id = params.id as ID;
  const teamId = params.teamId as ID;

  const { data: initialValues, loading } = useQuery(QUERY_TEAM, {
    variables: { id: id || -1, teamId },
  });

  const initialUrl = useMemo(() => {
    return window.location.href;
  }, []);

  const actionsOptions = useMemo(() => {
    if (initialValues?.actions) {
      const options = initialValues.actions.map((action: Action) => ({
        value: action.id,
        label: action.name,
      }));
      setValue("actions", (initialValues?.teamMemberById || initialValues?.getTeamById).actions.map((action: Action) => action.id));
      return options;
    }
    return [];
  }, [initialValues, setValue]);

  const usersOptions = useMemo(() => {
    if (initialValues?.users) {
      return initialValues.users
        /*.filter(
          (user: User) =>
            !initialValues.getTeamById.members.find(
              (m: TeamMember) => m.user.id === user.id
            )
        )*/
        .map((user: User) => ({
          value: user.id,
          label: user.name,
        }));
    }
    return [];
  }, [initialValues]);

  const [updateTeamMember, { loading: updateLoading }] = useMutation(
    UPDATE_MUTATION,
    {
      refetchQueries: [
        QUERY_TEAM,
        "teamMemberById",
        TEAMS_QUERY,
        "teams",
        MEMBERS_QUERY,
        "team",
      ],
    }
  );

  const [createAction /*, { loading: createActionLoading }*/] = useMutation(
    CREATE_ACTION_MUTATION,
    {
      refetchQueries: [QUERY_TEAM, "teamMemberById"],
    }
  );

  async function onSubmit(data: FormValues) {
    try {
      const result = await updateTeamMember({
        variables: {
          teamMember: id,
          user: data.user || initialValues?.teamMemberById?.user?.id,
          actions: data.actions.map((a: string) => ({ id: a })),
          countsInTeamResult: data.countsInTeamResult,
          team: teamId,
          kpiName: data.kpiName,
          kpiUnit: data.kpiUnit,
          isManager: data.isManager,
        },
      });
      if (result.data?.assignTeam?.success) setSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  async function _createAction(value: string) {
    await createAction({
      variables: {
        name: value,
      },
    });
  }

  if (loading) return null;
  if (!initialValues) return null;

  return (
    <Modal title={!!id ? "Edit team assignment" : `Assign user to ${initialValues?.getTeamById.name}`}>
      <div className="p-4">
        <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
          <Alert
            type="success"
            display={!!success}
            onDismiss={() => {
              setSuccess(false);
              if (window.location.href === initialUrl) navigate(-1);
            }}
            autoDismiss={3000}
          >
            Team member edited successfully !
          </Alert>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!!initialValues?.teamMemberById?.user ? (
              <FormGenericInput name="user" label="User">
                <Select
                  name="user"
                  options={usersOptions}
                  value={{ label: initialValues?.teamMemberById?.user?.name }}
                  isDisabled
                />
              </FormGenericInput>
            ) : (
              <FormGenericInput name="user" label="User">
                <Controller
                  name="user"
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <Select
                        options={usersOptions}
                        onChange={(option: any) => onChange(option.value)}
                        onBlur={onBlur}
                        value={usersOptions.filter((option: any) => value?.includes(option.value))}
                        defaultValue={usersOptions.filter((option: any) => value?.includes(option.value))}
                        isDisabled={!!initialValues?.teamMemberById?.user}
                      />
                    );
                  }}
                />
              </FormGenericInput>
            )}

            <FormGenericInput name="actions" label="Actions">
              <Controller
                name="actions"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <Creatable
                      options={actionsOptions}
                      onChange={(options) =>onChange(options?.map((option: any) => option.value))}
                      onBlur={onBlur}
                      value={actionsOptions.filter((option: any) => value?.includes(option.value))}
                      defaultValue={actionsOptions.filter((option: any) => value?.includes(option.value))}
                      onCreateOption={(value) => _createAction(value)}
                      isSearchable
                      isMulti
                    />
                  );
                }}
              />
            </FormGenericInput>

            <FormInput
              label="KPI name"
              type="text"
              error={errors.kpiName?.message}
              {...register("kpiName", {
                required: true,
                value: initialValues?.teamMemberById?.kpiName || initialValues?.getTeamById.kpiName,
              })}
            />

            <FormInput
              label="KPI unit"
              type="text"
              error={errors.kpiUnit?.message}
              {...register("kpiUnit", {
                required: true,
                value: initialValues?.teamMemberById?.kpiUnit || initialValues?.getTeamById.kpiUnit,
              })}
            />

            <FormInput
              label="Counts in team results ?"
              type="checkbox"
              error={errors.countsInTeamResult?.message}
              {...register("countsInTeamResult", {
                value: initialValues?.teamMemberById
                  ? initialValues?.teamMemberById?.countsInTeamResult
                  : true,
              })}
            />

            <FormInput
              label="Is manager ?"
              type="checkbox"
              error={errors.isManager?.message}
              {...register("isManager", {
                value: initialValues?.teamMemberById
                  ? initialValues?.teamMemberById?.isManager
                  : true,
              })}
            />

            {loading || updateLoading ? (
              <div>Loading...</div>
            ) : (
              <Button>Save team member</Button>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default TeamMemberEditModal;
