import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import rankingIcon from "../assets/icons/ranking.svg";
import useAuth from "../hooks/useAuth";
import { formatTarget } from "../libs/formatFunctions";
import { sortTargetsByPercent } from "../libs/SortFunctions";

const LEADERBOARD_QUERY = gql`
  query LeaderBoardForTeamOfTarget($target: ID!) {
    leaderboard: leaderBoardForTeamOfTarget(target: $target) {
      value
      id
      logTotal
      teamMember {
        kpiName
        kpiUnit
        user {
          name
          picture
          id
        }
      }
    }
  }
`;

interface RankingBarProps {
  expanded:boolean;
}

export interface RankingItemProps {
  target: Target;
  expanded: boolean;
  rank?: number;
}

export function RankingItem({ target, expanded, rank = 0 }: RankingItemProps) {
  return (
    <div className="mb-3 items-center relative flex">
      <img
        src={target.teamMember.user.picture}
        className={`${expanded ? 'h-10 w-10' : 'h-8 w-8'} rounded-full text-xs border`}
        alt={target.teamMember.user.name}
      />
      {!!rank && (
        <div className="absolute -bottom-1 -left-1 bg-white border rounded-full text-xs w-5 h-5 text-center font-bold">
          {rank}
        </div>
      )}

      {expanded && (
        <div className="flex-1 ml-2 overflow-hidden">
          <div className="font-medium pb-1 truncate">
            {target.teamMember.user.name}
          </div>
          <div
            className="text-sm"
            title={target.teamMember.kpiName}
          >{`${formatTarget(target.logTotal)} / ${formatTarget(target.value)}`}</div>
        </div>
      )}
    </div>
  );
}

export default function RankingBar({expanded}: RankingBarProps) {
  const { user } = useAuth();

  const { data } = useQuery(LEADERBOARD_QUERY, {
    variables: {
      target: user?.teams[0]?.currentTarget?.id,
    }
  });

  return (
    <div className="flex">
      <div
        className={`primary-box flex flex-col ${
          expanded ? "w-52" : "w-16"
        } transition-width ease-linear`}
      >
        <div className="flex-1">
          <h3 className="text-title font-bold text-lg mb-4">
            <img
              src={rankingIcon}
              className="inline-block h-8 w-8 mr-3"
              alt="Ranking"
            />{" "}
            {expanded && 'Ranking'}
          </h3>

          {data?.leaderboard?.sort(sortTargetsByPercent).slice(0,8).map((target: Target, i:number) => (
            <RankingItem key={`rankingItem-${target.id}-${i}`} target={target} expanded={expanded} rank={i + 1}/>
          ))}
        </div>

        {expanded && (
          <Link
            to={`/leaderboard/${user?.teams[0]?.currentTarget?.id}`}
            className="text-center"
          >
            See all
          </Link>
        )}
      </div>
    </div>
  );
}
