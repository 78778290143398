import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
// import { hotjar } from 'react-hotjar';

const buildType = process.env.REACT_APP_BUILD_TYPE;


if (buildType === 'prod') {
//  hotjar.initialize(3024936, 6);
  ReactGA.initialize("G-BDJY497CP4");
  ReactGA.set({ contentGroup1: 'app' })
}

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (buildType !== 'prod') {
      console.log("Page change "+location.pathname);
    } else {
//      hotjar.stateChange(location.pathname);
      ReactGA.event("page_view", { page_location: location.pathname });
    }
  }, [location]);
};

/**
 * Send an event, recommended to send standard events
 *
 * @param eventName See https://developers.google.com/tag-platform/gtagjs/reference/events
 * @param options 
 * @returns 
 */
export function logEvent(eventName:string, options?:any) {
  if (buildType !== 'prod') return console.log(`Log event ${eventName}`);
  try { return ReactGA.event(eventName, options); } catch (e) { console.warn('Error in logEvent: ', e)}
}

export default useAnalytics;
