import { gql, useQuery } from "@apollo/client";
import { FaColumns, FaList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import Donut from "../components/Donut";
import ObjectiveInputLine from "../components/ObjectiveInputLine";


function OKRTableLine({member, okr, index, refetch} : {member: TeamMember, okr: OKR | null, index: number, refetch: Function}) {
  return (<tr
    key={`teamMember-${member.id}-${okr?.id||"create"}`}
    className={`group border-b bg-white border-gray-200 align-top last:border-b-0 last:mb-0 ${index < member?.objectives?.length ? 'h-full' : ''}`}
  >
    {index === 0 && (
      <>
      <td
        className="m-3 mb-8 py-8"
        rowSpan={member.objectives.length + 1}
      >
        <div className="items-center mb-3 flex-1">
          <img
            src={member.user.picture}
            className="w-16 h-16 mb-2 m-auto rounded-full text-xs"
            alt={member.user.name}
          />
          <div className="items-center text-center">
            <h3 className="font-medium">
              {member.user.name}
            </h3>
            <div className="text-sm">
              {member.user.role}
            </div>
          </div>
        </div>
      </td>
      <td
        className="m-3 mb-8 py-8"
        rowSpan={member.objectives.length + 1}
      >
        <div className="justify-center invisible xl:visible">
          <Donut
            size={30}
            target={member.currentTarget}
          />
        </div>
      </td>
      </>
    )}

    <ObjectiveInputLine key={`teamMember-${member.id}-line-${okr?.id||"create"}`} okr={okr} user={member.user.id} onUpdate={() => { console.log("refetch"); refetch(); } } />
  </tr>)
}


export const OBJECTIVES_MEMBERS_QUERY = gql`
  query myQuery {
    teams(onlyPro: true) {
      id
      name
      members {
        id
        user {
          id
          name
          role
          picture
          deleted
        }
        currentTarget {
          id
          time
          value
          logTotal
          logs {
            value
          }
        }
        kpiName
        isManager
        objectives {
          id
          primary
          state
          text
          type
          stateObj {
            id
            text
            textColor
            color
          }
          reviews {
            state
            comment
            color
          }
          assignees {
            id
            user {
              name
              picture
              id
            }
          }
        }
      }
    }
  }
`;

function ObjectivesPage() {
  const { data: dashData, refetch } = useQuery(OBJECTIVES_MEMBERS_QUERY);
  const navigate = useNavigate();

  return (
    <div className="w-full p-4 pt-6">
      <div className="flex">
        <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
          Objectives - Table view
        </h1>

        <div className="flex-row space-x-3 mr-6  hidden lg:flex">
          <FaList className="h-6 w-6 cursor-pointer text-title" onClick={() => navigate('/objectives')} />
          <FaColumns className="h-6 w-6 cursor-pointer text-title"  onClick={() => navigate('/objectives/kanban')} />
        </div>
      </div>

      <div>
        {!dashData?.teams.length && (
          <Alert type="info" display>
            No team defined yet. Please ask your administrator to create teams.
          </Alert>
        )}

        {dashData?.teams &&
          dashData.teams /* For Mockups .sort((a:Team, b:Team) => a.name === 'SALES' ? -1 : 1) */
            .map((team: Team) => {
              const members = team?.members?.reduce((list: TeamMember[], member) => list?.find((m) => m.user.id === member.user.id) ? list : [...list, member], []);

              return (
                <div key={`tableDiv-${team.id}`}>
                  <div className="flex p-4 w-full text-center text-sm uppercase text-gray-600">
                    <h2 className="text-2xl font-medium text-left">
                      {team.name}
                    </h2>
                  </div>

                  <table
                    key={`tableT-${team.id}`}
                    className="shadow-md table-auto w-full hidden lg:block"
                  >
                    <thead className="rounded-lg text-center bg-tablehead">
                      <tr>
                        <th className="p-3 mt-3 px-12 text-left">Person</th>
                        <th className="p-3 mt-3 px-12">Performance</th>
                        <th className="p-3 mt-3 px-12 w-full">Priority</th>
                        <th className="p-3 mt-3 px-12">Status</th>
                        <th className="p-3 mt-3 px-12" colSpan={2}> </th>
                      </tr>
                    </thead>

                    <tbody className="p-3 rounded-b-lg bg-white">
                      {members?.map((member: TeamMember) => {
                          const okrLines = member.objectives.sort((a, b) => (a.primary && !b.primary) ? -1 : 1);
                          return (<>
                            {okrLines.map((okr: OKR | null, index: number) => <OKRTableLine okr={okr} member={member} index={index} refetch={refetch} />)}
                            <OKRTableLine okr={null} member={member} index={okrLines?.length ? (okrLines?.length + 1) : 0} refetch={refetch}/>
                          </>);
                      })}
                    </tbody>
                  </table>

                  <div
                    key={`tableT-${team.id}`}
                    className="shadow-md table-auto w-full block lg:hidden"
                  >
                    {members?.map((member: TeamMember) => {
                      const okrLines = member.objectives.sort((a, b) => (a.primary && !b.primary) ? -1 : 1);
                      return (<div
                        className="mx-3 mt-8 mb-12"
                      >
                        <div className="mb-3 flex">
                          <img
                            src={member.user.picture}
                            className="w-16 h-16 mb-2 m-auto rounded-full text-xs"
                            alt={member.user.name}
                          />
                          <div className="ml-8 flex-1">
                            <h3 className="font-medium">
                              {member.user.name}
                            </h3>
                            <div className="text-sm">
                              {`${member.user.role} - ${member.kpiName}`}
                            </div>
                          </div>
                        </div>


                        {okrLines.map((okr: OKR | null, index: number) => <ObjectiveInputLine key={`teamMember-${member.id}-line-${okr?.id||"create"}`} okr={okr} user={member.user.id} onUpdate={refetch} mobile />)}
                        <ObjectiveInputLine key={`teamMember-${member.id}-line-create`} okr={null} user={member.user.id} onUpdate={refetch} mobile />
                      </div>);
                    })}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default ObjectivesPage;
