import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { logEvent } from "../hooks/analytics";

function AutoLoginPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = searchParams;
    const token = params.get("token");
    const slug = params.get("slug");

    if (token?.length) {
      localStorage.setItem("jwt", token);

      logEvent('login', { method: 'auto' });

      if (slug === 'life') return window.location.replace("/perso/dash");
      return window.location.replace("/");
    }
  }, [searchParams]);

  return (
    <div>
      Auto login in progress. If nothing happens, please login manually:{" "}
      <a href="/login">Login here</a>.
    </div>
  );
}

export default AutoLoginPage;
