import { gql, useQuery, useMutation } from "@apollo/client";
import { Link, useNavigate } from 'react-router-dom';
import { FaPen, FaTrash } from 'react-icons/fa';
import IconButton from "../components/IconButton";
import Log from "../interfaces/Log";

export const MY_LOGS_QUERY = gql`
  query MyLogsQuery {
    myLogs(type: "PRO") {
      id
      time
      value
      valueText
      id
      action {
        name
      }
      target {
        type
      }
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation DeleteLog($deleteLogId: ID!) {
    deleteLog(id: $deleteLogId) {
      success
    }
  }
`;

function MyLogsPage() {
  const { data: logsData, loading: teamLoading } = useQuery(MY_LOGS_QUERY);
  const [ deleteLog ] = useMutation(DELETE_MUTATION, {
    refetchQueries: [ MY_LOGS_QUERY, 'MyLogsQuery' ],
  });


  const navigate = useNavigate();

  if (teamLoading) return <div>Loading...</div>;

  function askDelete(log:Log) {
    if (window.confirm(`Are you sure you want to delete this log?`)) {
      deleteLog({variables: {deleteLogId: log.id}});
    }
  }

  return (
    <div className="w-full p-4">
      <h1 className="text-3xl pb-4 font-bold font-sans">Your logs (actions)</h1>

      <div className="p-4 bg-white w-full shadow-sm rounded-lg">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-left text-lg">
              <th className="pb-5 pt-3">Time</th>
              <th className="pb-5 pt-3">Action</th>
              <th className="pb-5 pt-3">Value</th>
            </tr>
          </thead>
          <tbody>
            {logsData?.myLogs?.map((log: Log) => (
              <tr className="text-left" key={`mylogs-${log.id}`}>
                <td className="font-bold pb-3">
                  <Link to={`/modal/editLog/${log.id}`}>
                  { Intl.DateTimeFormat(undefined, {timeStyle:"short", dateStyle: "medium"}).format(new Date(log.time))}
                  </Link>
                  </td>
                <td className="pb-3">{log.action.name}</td>
                <td className="font-bold pb-3 text-ellipsis">{log?.valueText || log.value}</td>
                <td className="pb-3 text-right">
                  <IconButton icon={FaPen} onClick={() => navigate(`/modal/editLog/${log.id}`)}/>
                  <IconButton icon={FaTrash} onClick={() => askDelete(log)}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyLogsPage;
