import React, { useEffect, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import FormSelectInput from "../components/FormSelectInput";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { OBJECTIVES_QUERY } from '../components/OKRBar';

const OBJECTIVE_ACTION = gql`
mutation AddObjective($text: String!, $state: String!, $type: String!, $primary: Boolean, $forUser: ID, $forTeam: ID, $id: ID) {
  addObjective(id: $id, text: $text, state: $state, type: $type, primary: $primary, forUser: $forUser, forTeam: $forTeam) {
    success
    created
    message
  }
}
`;

const OBJECTIVE_QUERY = gql`
query GetObjective($id: ID!) {
  objective: getObjective(id: $id) {
    primary
    state
    text
    type
  }
}`

type FormValues = {
  text: string
  type: string;
  state: string;
  primary: boolean; 
};

function AddObjectiveModal() {
  const { user } = useAuth();
  const {register, handleSubmit, setValue, formState: { errors }} = useForm<FormValues>();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [success, setSuccess] = React.useState(false);
  const [addObjective, { loading }] = useMutation(OBJECTIVE_ACTION);
  const { data } = useQuery(OBJECTIVE_QUERY, { variables: { id: params.id }});

  const initialUrl = useMemo(() => window.location.href, []);

  useEffect(() => {
    if (data?.objective) {
      setValue('primary', data.objective.primary);
      setValue('state', data.objective.state.toLowerCase());
      setValue('text', data.objective.text);
      setValue('type', data.objective.type === 'team' ? 'team' : 'perso');
    } else if (searchParams.get('primary') || searchParams.get('text')) {
      if (searchParams.get('primary')) setValue('primary', searchParams.get('primary') === 'true');
      if (searchParams.get('text')) setValue('text', decodeURIComponent(searchParams.get('text') || ''));
    }
  }, [data, setValue, searchParams]);

  function onSubmit(data:FormValues) {
    const { text, type, primary } = data;

    addObjective({
      variables: {
        text, type, state: 'todo', primary, forUser: params.user, forTeam: params.team, id: params.id,
      },
      refetchQueries: [OBJECTIVES_QUERY, 'Query'],
    }).then(({ data }) => {
      if (data.addObjective.success) setSuccess(true);
    }).catch(e => alert(e.message));
  }

  if (!user) return null;

  return (
    <Modal title={params.id ? "Edit objective" : "Create a new objective"} autoHeight >
      <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
        <Alert type="success" autoDismiss={3000} display={success} onDismiss={() => (window.location.href === initialUrl ? navigate(-1) : () => {})}>Objective {params.id ? "edited" : "added"} successfully!</Alert>
        <FormInput
          label="Objective"
          type="text"
          placeholder="Which objective do you want to achieve ?"
          error={errors.text?.message}
          {...register("text", { required: true })}
        />

        <FormSelectInput
          label="Type of objective"
          options={[{ value: 'perso', text: "Individual" }, { value: 'team', text: "Team" }]}
          error={errors.type?.message}
          {...register("type", { required: true, value: 'perso' })}
        />

        <FormInput
          label="Does it improve your KPI, or is it your top priority ?"
          type="checkbox"
          error={errors.primary?.message}
          {...register("primary")}
        />

        {loading && <div>Loading...</div>}

        <div className="flex justify-center">
          <Button type="submit">{params.id ? "Edit" : "Add"} Objective</Button>
        </div>
      </form>
    </Modal>
  );
}

export default AddObjectiveModal;
