import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FaCircle, FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import Alert from "../components/Alert";
// import Alert from "../components/Alert";
import TargetBlockagePill from "../components/TargetBlockagePill";
import { getColor } from "../components/TeamDashComponent";
import useAuth from "../hooks/useAuth";
import { formatForPeriodicity } from "../libs/formatFunctions";

// import TeamDashComponentForTeams from "../components/TeamDashComponentForTeams";

const RESULTS_QUERY = gql`
  query dashData($time: Date!) {
    teams (onlyPro: true) {
      id
      name
      kpiName
      members {
        id
        user {
          id
          name
          role
          picture
          deleted
        }
        targets {
          id
          time
          value
          logTotal
          logs {
            value
          }
        }
        kpiName
        isManager
      }

      targetIssueReasons {
        name
        solution
      }
    }

    targetBlockagesResults(time: $time) {
      blockages {
        id
        solution {
          id
          name
        }
        reason {
          name
          id
        }
        id
        note
        target {
          teamMember {
            id
            team {
              id
            }
          }
        }
      }
      after
      before
    }
  }
`;

function getColorPie(value: number) {
  //value from 0 to 1
  var hue = (value * 120).toString(10);
  return `hsl(${hue},80%,65%)`;
}

function getActionsForTeamChart(reasonsTeam: any, blockages: any) {
  if (!reasonsTeam) return [];

  const val = Object.keys(reasonsTeam)
    .map((reasonKey) => {
      const weight = reasonsTeam[reasonKey];
      const blockage = blockages.find(
        (b: TargetIssue) => b.reason.id === reasonKey
      );

      return {
        id: reasonKey,
        name: blockage?.reason?.name || "",
        value: weight,
      };
    })
    .sort((a, b) => b.value - a.value);

  return val.map((v: any, i: number) => ({
    ...v,
    index: i,
    color: getColorPie(i / val.length),
  }));
}

function DashTargetIssuePage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [resultsQuery, { data: dashData }] = useLazyQuery(RESULTS_QUERY);

  const options = useMemo(() => {
    return [1, 2, 3].map((i, id) => {
      const thisMoment = moment().subtract(i, user?.company.periodicity || 'month').startOf(user?.company.periodicity || 'month').add(3, 'days');
      return {
        label: formatForPeriodicity(thisMoment, user?.company.periodicity),
        value: {
          time: thisMoment.toDate(),
          id,
        },
      };
    });
  }, [user]);
  

  const [reasonsPerTeam, membersWithReasons] = useMemo(() => {
    const resultsForMembers: {
      member: TeamMember;
      results: { result: number; target: number; targetID: ID };
    }[] = []; // FIXME: Set the right type when defined

    dashData?.teams?.forEach((team: Team) => {
      team?.members?.forEach((member: TeamMember) => {
        const targets = member.targets.filter((t) =>
          moment(t.time).isBetween(
            dashData?.targetBlockagesResults?.after,
            dashData?.targetBlockagesResults?.before
          )
        );

        const aggregated = targets.reduce(
          (prev, current) => {
            prev.target += current.value;
            prev.result += current.logTotal;
            return prev;
          },
          { result: 0, target: 0 }
        );

        // TODO: Aggregated is currently only the sum of all results, should we take the mean, the last target/result instead ?
        resultsForMembers.push({
          member,
          results: { ...aggregated, targetID: targets[0]?.id },
        });
      });
    });

    const reasons: any = {};
    dashData?.targetBlockagesResults?.blockages?.forEach(
      (blockage: TargetIssue) => {
        const reasonsForTeam =
          reasons[blockage.target.teamMember.team.id] || {};

        const resultForMember: any = resultsForMembers.find(
          (result) => result.member.id === blockage.target.teamMember.id
        );

        const id = `${blockage.reason.id}`;
        if (!reasonsForTeam[id]) reasonsForTeam[id] = 0;

        const clampedRatio = Math.min(
          Math.max(
            0,
            (resultForMember?.results?.result || 0) /
              (resultForMember?.results?.target || 1)
          ),
          1
        );
        reasonsForTeam[id] += 1 - clampedRatio;

        reasons[blockage.target.teamMember.team.id] = reasonsForTeam; // Write updated values
      }
    );

    return [reasons, resultsForMembers];
  }, [dashData?.targetBlockagesResults, dashData?.teams]);


  let teamsDisplayed = false;

  function periodChanged(p: any) {
    resultsQuery({
      variables: {
        time: p.value.time,
      },
    });

    setSelectedPeriod(p.value.id);

    return false;
  }

  useEffect(() => {
    periodChanged(options[0]);
  }, [options]);

  return (
    <div className="w-full p-4 pt-6">
      <div className="flex">
        <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
          Obstacles &amp; solutions
        </h1>

        <div>
          <Select
            name="period"
            options={options}
            onChange={periodChanged}
            value={options[selectedPeriod]}
            defaultValue={options[0]}
          />
        </div>
      </div>

      <div>
        {!dashData?.teams.length && (<Alert type="info" display>No team defined yet. Please ask your administrator to create teams.</Alert>)}
        {dashData?.teams && dashData.teams /* For Mockups .sort((a:Team, b:Team) => a.name === 'SALES' ? -1 : 1)*/
          .map((team: Team) => {
            const manager = team.members?.find((m: TeamMember) => m.isManager);

            const teamMembers = team.members?.filter((m: TeamMember) => {
              const target = membersWithReasons.find(
                (r) => r.member.id === m.id
              )?.results;

              if (!user?.isAdmin && (m.user.id !== user?.id || m.user?.id === manager?.user.id)) return false;
              return (target?.result || 0) / (target?.target || 1) < 0.8;
            });

            if (!teamMembers?.length) return null

            teamsDisplayed = true;
            const actionsPieData = getActionsForTeamChart(
              reasonsPerTeam[team.id],
              dashData?.targetBlockagesResults?.blockages
            );

            return (
              <div className="mb-10">
                <h4 className="align-baseline group relative">
                  <span className="text-title font-bold text-xl">{team.name}</span>
                  <span className="text-text mx-3 text-md">{team.kpiName}</span>

{/*

                  <FaInfoCircle className="inline-block text-gray-400" />

                  <div className="absolute top-0 right-0 rounded-lg bg-gray-700 text-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">

                    <ul role="list" className="divide-y divide-white/5">
                      <li className="relative flex items-center space-x-4 py-4">

                      <TargetBlockagePill>
                                {"blockage.reason.name"}
                              </TargetBlockagePill>

<FaArrowRight />

                              <TargetBlockagePill>
                                {"blockage.solution.name"}
                              </TargetBlockagePill>

                          
                      
                      
                      </li>
                    </ul>

                  </div>
            */}

                </h4>

                <div key={`tableT-${team.id}`} className="shadow-md  text-sm lg:text-md">
                  <div className="w-full p-3 table-fixed flex mt-3 rounded-lg text-center bg-tablehead">
                    <div className="flex-1">Person</div>
                    <div className="flex-1 hidden lg:block">Target</div>
                    <div className="flex-1">Obstacle</div>
                    <div className="flex-1">Solution</div>
                    <div className="flex-1">Status</div>
                  </div>

                  <div className="w-full p-3 table-fixed rounded-b-lg bg-white">
                    {teamMembers?.map((member: TeamMember) => {
                      const blockage =
                        dashData?.targetBlockagesResults?.blockages?.find(
                          (b: TargetIssue) =>
                            b.target.teamMember.id === member.id
                        );

                      const { color, index } =
                        actionsPieData?.find(
                          (a: any) => a.id === blockage?.reason?.id
                        ) || {};

                      const target = membersWithReasons.find(
                        (r) => r.member.id === member.id
                      )?.results;
                      const done = target?.result || 0;
                      const doneG = Math.min(
                        100,
                        Math.round(Math.max(0, (100 * done) / (target?.target || 1)))
                      );
                      const data = [
                        { name: "Done", value: doneG },
                        { name: "To Do", value: 100 - doneG },
                      ];
                      const percentage = doneG;
                      
                      return (
                        <div
                          className="flex items-center pb-4 text-sm lg:text-md"
                          key={`teamMember-${member.id}`}
                        >
                          <div className="flex flex-1">
                            <img
                              src={member.user.picture}
                              className="w-12 h-12 rounded-full mr-4 text-xs hidden lg:block"
                              alt={member.user.name}
                            />
                            <div className="flex-grow items-center">
                              <h3 className="text-sm lg:text-lg font-medium">
                                {member.user.name}
                              </h3>
                              <span>{member.kpiName}</span>
                            </div>
                          </div>
                          <div className="items-center justify-center flex-1 hidden lg:block">
                            <ResponsiveContainer width="100%" height={100}>
                              <PieChart width={40} height={40}>
                                <Pie
                                  data={data}
                                  dataKey="value"
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={35}
                                  outerRadius={40}
                                  startAngle={90}
                                  endAngle={-270}
                                  isAnimationActive={false}
                                >
                                  {!!target && (
                                    <Label
                                      value={`${percentage}%`}
                                      position="center"
                                      fill={getColor(percentage)}
                                    />
                                  )}
                                  <Cell
                                  fill={getColor(percentage)}
                                  />
                                  <Cell fill="#DDDDDD" />
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </div>

                          <div className="flex flex-1 items-center justify-center">
                            {!!blockage?.reason ? (
                              <TargetBlockagePill
                                color={color}
                                index={index}
                                lastTarget={target?.targetID}
                                canEdit={user?.isAdmin || manager?.user.id === user?.id || member?.user.id === user?.id}
                                note={blockage.note}
                                className="font-bold"
                              >
                                {blockage.reason.name}
                              </TargetBlockagePill>
                            ) : (
                              <TargetBlockagePill
                                notSet
                                lastTarget={target?.targetID}
                                canEdit={user?.isAdmin || manager?.user.id === user?.id || member?.user.id === user?.id}
                              >
                                No obstacle set
                              </TargetBlockagePill>
                            )}
                          </div>

                          <div className="flex flex-1 items-center justify-center">
                            {!!blockage?.reason ? (
                              <TargetBlockagePill color={color} index={index} className="font-bold">
                                {blockage.solution
                                  ? blockage.solution.name
                                  : "No default solution"}
                                <FaPlusCircle className='text-title inline-block mx-3 cursor-pointer' onClick={() => navigate(`/objectives/add/${member?.user?.id}/${team?.id}?primary=true&text=${encodeURIComponent(blockage?.solution?.name || '')}`)} /> 
                              </TargetBlockagePill>
                            ) : (
                              <TargetBlockagePill notSet>
                                No focus set
                              </TargetBlockagePill>
                            )}
                          </div>

                          <div className="flex flex-1 items-center justify-center" style={{color}} >
                            { index >= 0 && <FaCircle className='block lg:inline-block mx-3 cursor-pointer text-xs' />}
                            { index === 0 && "Critical" }
                            { index === 1 && "High Priority" }
                            { index === 2 && "Priority" }
                            { index === 3 && "Medium" }
                            { index > 3 && "Low" }
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              </div>
            );
          })}

      {
        (dashData?.teams && !teamsDisplayed) && (
          <Alert type="info" display>
            No obstacle for this period. Good job !
          </Alert>
        )
      }

      </div>
    </div>
  );
}

export default DashTargetIssuePage;
