import React from "react";

import { gql, useQuery } from "@apollo/client";
import Alert from "../components/Alert";
import { getColor } from "../components/TeamDashComponent";

const TEAMS_QUERY = gql`
  query Teams {
    companies: getPEDash {
      id
      name
      logo
      mainMetric {
        currentTarget {
          logTotal
          value
        }
        kpiName
        kpiUnit
        team {
          kpiName
          kpiUnit
        }
      }
    }
  }
`;

function idToPercentage(id: any):number {
  let tmp = (id * 12154875431) % 100;
  if (tmp > 50) return tmp;
  return idToPercentage(tmp + 113);
}

function PortfolioPage() {
  const {
    data: companiesData,
    loading: loadingTeams,
  } = useQuery(TEAMS_QUERY);


  return (
    <div className="w-full p-4">
      <h1 className="text-3xl text-title pb-8 font-bold font-sans flex-1">
        Your portfolio's performance
      </h1>

      {loadingTeams ? (
        <Alert display type="info">
          Loading companies...
        </Alert>
      ) : (
        <div className="grid grid-cols-4 gap-8">
          {companiesData?.companies.sort((a:Company, b:Company) => a.name > b.name ? 1 : -1).map((cp: any) => {
            return (
              <a
                className="p-6 py-8 bg-white w-full shadow-sm rounded-lg items-center justify-start flex flex-col text-center hover:shadow-md"
                key={`team-perso${cp.id}`}
                href={`/dash/${cp.id}`}
              >
                <img 
                  src={cp.logo || ''}
                  alt={`Logo ${cp.name}`}
                  className="h-28" />

                {cp?.mainMetric ? (<>
                  <div>
                    <span className="text-lg text-gray-700 font-medium ">
                      {`${Math.round(100 * (cp.mainMetric?.currentTarget?.logTotal || 0 )/(cp.mainMetric?.currentTarget?.value || 1))}%`}
                    </span>
                    {!!cp.mainMetric.kpiName && <span className="text-md text-gray-500 ml-2">
                      {cp.mainMetric.kpiName}
                    </span>}
                  </div>
                  <div className="w-full h-4 relative rounded-sm bg-gray-200">
                    <div style={{width: `${100 * Math.min(1, (cp.mainMetric?.currentTarget?.logTotal || 0 )/(cp.mainMetric?.currentTarget?.value || 1))}%`, background: getColor(100 * (cp.mainMetric?.currentTarget?.logTotal || 0 )/(cp.mainMetric?.currentTarget?.value || 1))}} className="absolute left-0 top-0 bottom-0 rounded-sm rounded-r-md"> </div>
                  </div>
                </>
                ) : (<>
                  <div>
                    <span className="text-lg text-gray-700 font-medium ">
                      {`${Math.round(idToPercentage(cp.id))}%`}
                    </span>
                    <span className="text-md text-gray-500 ml-2">
                      $ monthly budget
                    </span>
                  </div>
                  <div className="w-full h-4 relative rounded-sm bg-gray-200">
                    <div style={{width: `${idToPercentage(cp.id)}%`, background: getColor(idToPercentage(cp.id))}} className="absolute left-0 top-0 bottom-0 rounded-sm rounded-r-md"> </div>
                  </div>
                </>
                )}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PortfolioPage;
