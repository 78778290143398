import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";
import { MY_LOGS_QUERY } from "./MyLogsPage";
import { useNavigate, useParams } from "react-router-dom";
import FormSelectInput from "../components/FormSelectInput";
import { logEvent } from "../hooks/analytics";

type FormValues = {
  value: string;
  valueText: string;
  valueSelect: ID;
  action: string;
};

export const LOGACTION = gql`
  mutation Mutation(
    $action: ID
    $value: Float
    $time: DateTime!
    $target: ID
    $teamMember: ID
    $valueText: String
    $valueSelect: ID,
    $userId: ID
  ) {
    logNewAction(action: $action, value: $value, valueText: $valueText, valueSelect: $valueSelect, time: $time, target: $target, teamMember: $teamMember, userId: $userId) {
      success
      message
    }
  }
`;

function LogModal() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<FormValues>();
  const [success, setSuccess] = React.useState(false);
  const [team, setTeam] = React.useState<TeamMember | null>(null);

  const [logAction, { loading }] = useMutation(LOGACTION, {
    refetchQueries: [MY_LOGS_QUERY, "MyLogsQuery"],
  });

  function onSubmit(data: FormValues) {
    logEvent('logProgress');

    logAction({
      variables: {
        action: parseInt(data.action),
        value: parseFloat(data.value),
        valueText: data.valueText ? data.valueText.trim() : null,
        time: new Date(),
        target: team?.currentTarget?.id,
        teamMember: team?.id,
      },
    }).then(({ data }) => {
      if (data.logNewAction.message) {
        setError("value", { message: data.logNewAction.message });
      }

      if (data.logNewAction.success) {
        setSuccess(true);
      }
    });
  }

  React.useEffect(() => {
    setTimeout(() => {
      try {
        setFocus("value");
      } catch (e) {
        console.warn(e)
      }
    }, 500);
  }, [setFocus])

  const teams = useMemo(
    () =>
      user?.teams.filter(
        (tm: TeamMember) =>
          tm.team?.type === (params.type || "pro").toUpperCase()
      ) || [],
    [user, params]
  );

  const inputType = useMemo(() => team?.inputType || 'NUMERIC', [team]);
  const initialUrl = useMemo(() => window.location.href, []);

  useEffect(() => {
    if (!team && teams?.length) {
      setTeam(teams[0]);
      setValue("action", teams[0]?.actions?.[0]?.id || 0);

      if (!teams[0]?.currentTarget) {
        setError("value", {
          message:
            "No target for this period. Please ask your admin to assign you one.",
        });
      }
    }
  }, [user, setError, setValue, team, teams]);

  if (!user) return null;
  if (!team)
    return (
      <div className="flex items-center w-full justify-center">
        <Alert type="error" display>
          You don't have any team. Please ask your administrator to configure
          your team.
        </Alert>
      </div>
    );

  return (
    <Modal title="Log your progression" autoHeight className="max-w-2xl">
      <div className="">
        <Alert
          type="success"
          autoDismiss={2000}
          display={success}
          onDismiss={() => {
            if (window.location.href === initialUrl) {
              navigate(((params.type || "pro").toUpperCase() === 'PERSO') ? '/dash/perso' : `/${team?.id}`, {
                replace: true,
              });
            }
          }}
        >
          Progress logged successfully !
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          {teams?.length > 1 && (<FormSelectInput
            label="Your goal"
            options={teams?.map((a) => ({
              value: `${a.id}`,
              text: `${a.team.name} - ${a.kpiName}`,
            }))}
            value={team.id}
            onChange={(e) =>
              setTeam(teams.find((t) => t.id === e.target.selectedOptions[0].value))
            }
          />)}

          {inputType === 'NUMERIC' && (
            <FormInput
              label="Your progression"
              type="number"
              error={errors.value?.message}
              placeholder={team?.kpiName}
              {...register("value", { required: true })}
            />
          )}
          {inputType === 'TEXT' && (
            <FormInput
              label="Your progression"
              error={errors.value?.message}
              placeholder="Describe your progression"
              autoFocus
              {...register("valueText")}
            />
          )}

          {team?.actions?.length > 1 && (<div className="my-5">
            <FormSelectInput
              label="Progression type"
              options={team?.actions.map((a) => ({
                value: `${a.id}`,
                text: a.name,
              }))}
              {...register("action", {
                required: true,
              })}
            />
          </div>)}

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="flex justify-end space-x-6 mt-10">
              <Button
                type="button"
                className="inline-block"
                disabled={!!errors.value}
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button className="inline-block" disabled={!!errors.value}>
                Save progress
              </Button>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
}

export default LogModal;
