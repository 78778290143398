import { gql, useQuery } from "@apollo/client";

import useAuth from "../hooks/useAuth";
import { getColor } from "../components/TeamDashComponent";
import { formatTarget } from "../libs/formatFunctions";
import { sortTargetsByPercent } from "../libs/SortFunctions";

const LEADERBOARD_QUERY = gql`
  query globalLeaderboard($time: Date) {
    leaderboard: globalLeaderboard(time: $time) {
      value
      id
      logTotal
      teamMember {
        team {
          id
        }
        kpiName
        kpiUnit
        user {
          name
          picture
          id
        }
      }
    }

    teams(onlyPro: true) {
      id
      name
      members {
        id
        user {
          id
          name
          role
          picture
          deleted
        }
        kpiName
        isManager
      }
    }
  }
`;

function GlobalRankingPage() {
  const { user } = useAuth();

  const { data, loading } = useQuery(LEADERBOARD_QUERY, {
    variables: {
      // time: moment().subtract(1, periodicity).toDate(),
    },
  });

  if (!user) return null;
  if (loading) return <div>Loading screen...</div>;
  if (!data) return null;
  return (
    <div className="w-full p-4">
      <div className="flex">
        <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
          Global Ranking
        </h1>
      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3">
        {data.teams.map((team: Team) => {
          const members = data.leaderboard.filter((a:any) => a.teamMember.team.id === team.id).sort(sortTargetsByPercent);

          return (
            <div className="pb-10 mr-4" key={`gr-team-${team.id}`}>
              <h2 className="text-xl text-title pb-4 font-bold font-sans flex-1">
                {team.name}
              </h2>

              <table className="primary-box table-auto w-full ml-0 border-collapse rounded-lg">
                <tbody>

              {
                members.map((target:any, i:number) => {
                  const isUser = target.teamMember.user.id === user.id;

                  return (
                    <tr
                      key={`table-line-${target.teamMember.user.id}-${target.id}`}
                      className={`px-4 ${
                        isUser
                          ? "text-white bg-primary"
                          : "text-gray-800 "
                      }`}
                    >
                      <td className="py-3 pl-6 font text-xl">{i + 1}.</td>
                      <td className="py-3 pl-3 pr">
                        <img
                          src={target.teamMember.user.picture}
                          className="h-12 w-12 rounded-full"
                          alt={target.teamMember.user.name}
                        />
                      </td>
                      <td className="py-3 flex-1">
                        <div className="font-medium text-lg pb-1">
                          {`${target.teamMember.user.name}${
                            isUser ? " (You)" : ""
                          }`}
                        </div>
                        <div
                          title={target.teamMember.kpiName}
                        >{`${formatTarget(target.logTotal)} / ${formatTarget(target.value)}`}</div>
                      </td>
                      <td className="py-3 pr-2 font-medium text-xl text-right">
                        {Math.round((100 * target.logTotal) / (target.value || 1)) ||
                          0}
                        %
                      </td>

                      <td className="py-3 relative m-4 w-6">
                        <div
                          className="absolute top-3 bottom-3 left-0 right-0 w-2 rounded-full"
                          style={{
                            backgroundColor: getColor(
                              (100 * target.logTotal) / (target.value || 1)
                            ),
                          }}
                        >
                        </div>
                      </td>
                    </tr>
                  );
                })
              }
                </tbody>

              </table>
            </div>
          );
        })}
      </div>

    </div>
  );
}

export default GlobalRankingPage;
