import React, { useEffect, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { useForm } from "react-hook-form";
import FormInput from "../components/FormInput";

const LOGACTION = gql`
  mutation Mutation($reason: ID, $target: ID!, $note: String) {
    logTargetIssueReason(reason: $reason, target: $target, note: $note) {
      success
      message
    }
  }
`;

const FETCH_OBSTACLES_QUERY = gql`
  query ObstaclesQuery($target: ID!) {
    targetIssueReasonsForTarget(target: $target) {
      id
      name
      phrase
      noteMandatory
    }

    issue: getTargetIssueById(id: $target) {
      id
      note
      reason {
        id
      }
    }
  }
`;

type FormValues = {
  reason: string;
  note: string;
};

function AssignObstacleModal() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const params = useParams();
  const { register, handleSubmit, watch, setValue } = useForm<FormValues>();

  const target = parseInt(`${params.target}`, 10);
  const watchReason = watch("reason");

  const [success, setSuccess] = React.useState(false);
  const [logAction, { loading }] = useMutation(LOGACTION);
  const { data: obstacles } = useQuery(FETCH_OBSTACLES_QUERY, {
    variables: { target },
  });

  function buttonClicked(target: ID) {
    logAction({
      variables: {
        target,
      },
    }).then(({ data }) => {
      if (data.logTargetIssueReason.success) setSuccess(true);
    });
  }

  function onSubmit(data: FormValues) {
    const { reason, note } = data;
    logAction({
      variables: {
        reason,
        target,
        note,
      },
    }).then(({ data }) => {
      if (data.logTargetIssueReason.success) setSuccess(true);
    });
  }

  const selectedReason = useMemo(() => {
    return obstacles?.targetIssueReasonsForTarget.find(
      (r: TargetIssueReason) => r.id === watchReason
    );
  }, [watchReason, obstacles]);

  useEffect(() => {
    if (obstacles?.issue?.reason) {
      setValue("reason", obstacles?.issue?.reason?.id);
      setValue("note", obstacles?.issue?.note);
    }
  }, [setValue, obstacles]);

  if (!user) return null;

  return (
    <Modal title={`What obstacle do you want to assign ?`} autoHeight>
      <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
        <Alert type="success" autoDismiss={3000} display={success} onDismiss={() => navigate('/blockages')}>
          Obstacle logged successfully!
        </Alert>

        <div className="my-5">
          <ul>
            {obstacles?.targetIssueReasonsForTarget?.map(
              (issueAction: TargetIssueReason) => {
                return (
                  <li key={`team-button-${issueAction?.id}`} className="m-4">
                    <input
                      type="radio"
                      id={`team-button-${issueAction?.id}`}
                      key={`team-button-${issueAction?.id}`}
                      className="cursor-pointer"
                      value={issueAction?.id}
                      {...register("reason", { required: true })}
                    />
                    <label
                      htmlFor={`team-button-${issueAction?.id}`}
                      className="ml-3 cursor-pointer"
                    >
                      {issueAction.phrase || issueAction.name}
                    </label>
                  </li>
                );
              }
            )}
          </ul>

          <div className="mx-2 my-10 max-w-lg">
            <FormInput
              label={`Add a comment ${
                selectedReason?.noteMandatory ? "(required)" : "(optional)"
              }`}
              type="text"
              placeholder="Want to give more details on your obstacles?"
              required={!!selectedReason?.noteMandatory}
              {...register("note", {
                required: !!selectedReason?.noteMandatory,
              })}
            />
          </div>
        </div>

        {loading && <div>Loading...</div>}

        <div className="flex justify-end space-x-10">
          <Button
            onClick={() => buttonClicked(target)}
            variant="danger"
            className="mx-2"
          >
            Unset reason
          </Button>

          <Button>Submit obstacle</Button>

        </div>

      </form>
    </Modal>
  );
}

export default AssignObstacleModal;
