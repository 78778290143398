import React, { useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

import useAuth from '../hooks/useAuth';
import Modal from "../components/Modal";
import TargetInput from "../components/TargetInput";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { formatForPeriodicity } from "../libs/formatFunctions";
import { logEvent } from "../hooks/analytics";

export const QUERY_TARGETS = gql`
  query TargetsForUser($user: ID) {
    targetsForUser(user: $user, onlyPerso: true) {
      id
      team {
        id
        name
      }
      targets {
        id
        time
        value
        cumulPrevious
      }
      kpiName
      kpiUnit
    }
  }
`;

const ASSIGN_TEAM_MUTATION = gql`
mutation assignTeam($user: ID!, $team: ID!, $teamMember: ID) {
  assignTeam(user: $user, team: $team, teamMember: $teamMember, deassign: true, perso: true) {
    success
    message
  }
}
`;


function UserEditTargetsPersoModal() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  // const [success, setSuccess] = React.useState(false);

  const { data: targets /*, loading */ } = useQuery(QUERY_TARGETS, {
    variables: { user: user?.id },
  });


  const [ deassignTeam ] = useMutation(
    ASSIGN_TEAM_MUTATION,
    { refetchQueries: [QUERY_TARGETS, 'TargetsForUser'] }
  );

  const periods = useMemo(() => {
    if (!targets?.targetsForUser) return [];

    // Get max é min times for the different targets
    let minTime = moment().subtract(3, 'months');
    let maxTime = moment().add(1, 'year');
    targets.targetsForUser.forEach((teamMember: TeamMember) => {
      teamMember.targets.forEach((target) => {
        const time = moment(target.time);
        // if (time.isBefore(minTime)) minTime = time;
        if (time.isAfter(maxTime)) maxTime = time;
      });
    });

    // Generate months between first target and next month
    const periods = [];
    let currentPeriod = minTime;
    while (currentPeriod.isBefore(maxTime)) {
      periods.push(currentPeriod);
      currentPeriod = currentPeriod.clone().add(1, "month");
    }

    periods.push(currentPeriod); // Next month

    return periods;
  }, [targets]);

  const targetsToDisplay = useMemo(() => {
    if (params?.id) return targets?.targetsForUser?.filter((t:TeamMember) => t.id === params.id);
    return targets?.targetsForUser;
  }, [targets?.targetsForUser, params]);

  function askLeave(teamMember: TeamMember) {
    const conf = window.confirm("Are you sure you want to give up on this objective ?");
    if (conf) {
      logEvent('giveUpPersoTarget');

      deassignTeam({ variables: {
        teamMember: teamMember.id,
        user: user?.id,
        team: teamMember.team.id,
      }}).then(() => {
        navigate(-1);
      })
    }
  }

  if (!targetsToDisplay || !periods) return null;

  return (
    <Modal title="Edit personal targets">
        {targetsToDisplay.length ? (<table className="table-fixed w-full">
          <thead>
            <tr className="text-left">
              <th className="pb-5 pt-3">Period</th>
              {targetsToDisplay.map((teamMember: TeamMember) => (
                <th className="pb-5 pt-3 text-center relative group">
                  <strong className="block">{teamMember.team.name}</strong>
                  <span className="font-thin">{teamMember.kpiName || '-'}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {periods.map((period) => (
              <tr className={`text-left ${!period.month() ? 'border-t-2 mt-4' : ''}`}>
                <td>{formatForPeriodicity(period, user?.company.periodicity)}</td>
                {targetsToDisplay.map((teamMember: TeamMember) => (
                  <td className="p-2">
                    <TargetInput period={period} teamMember={teamMember} noCumul/>
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td> </td>
              {targetsToDisplay.map((teamMember: TeamMember) => (
                <td className="p-2">
                  <Button variant="outlined" className="w-full" onClick={() => askLeave(teamMember)}>
                    Give up
                  </Button>
                </td>
              ))}
            </tr>
            </tbody>
        </table>) : (
        <>
          <Alert display type="error">You don't have any personal target yet.</Alert>
          <Link to="/perso/define"><Button>Assign personal targets</Button></Link>
        </>
        )}
    </Modal>
  );
}


export default UserEditTargetsPersoModal;