import { gql, useQuery } from "@apollo/client";

import { OKRItemWithButton } from "./OKRBar";

const TARGET_QUERY = gql`
  query teamMemberById($id: ID!) {
    member: teamMemberById(id: $id) {
      objectives {
        id
        primary
        state
        text
        type
        reviews {
          state
          comment
          color
        }
      }

      user {
        sheetURL
        id
        name
      }
    }
  }
`;


function ObjectivesComponent({ teamMember }: { teamMember?: string }) {
  const { data, loading, refetch } = useQuery(TARGET_QUERY, {
    variables: { id: teamMember },
  });

  if (loading) return <p>Loading...</p>;
  if (!data) return null;

  return (
    <div className="relative">
      <h3 className="text-2xl text-center">Objectives</h3>

      {!!data?.member.objectives?.length ? (
        <div className="mt-9">
          {data?.member.objectives?.map((okr: OKR) => (<OKRItemWithButton okr={okr} onUpdate={refetch} displayReview expanded/>))}
        </div>
      ) : (
        <div className="text-center p-8">This user didn't fill any obstacle yet.</div>
      )}
    </div>
  );
}

export default ObjectivesComponent;
