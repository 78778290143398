import { forwardRef, ReactNode } from "react";

export interface SelectFieldProps
  extends React.PropsWithoutRef<JSX.IntrinsicElements["select"]> {
  error?: string | undefined;
  label?: string;
  nolabel?: boolean;
  options: {value: string, text: string | ReactNode}[],
}

export default forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const { label, nolabel, name, error, options, ...rest } = props;

  return (
    <div className="mb-4">
      {!nolabel && <label
        className="block text-title text-sm mb-2"
        htmlFor={name}
      >
        {label}
      </label>}

      <select
        className="bg-transparent border-title border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        name={name}
        ref={ref}
        {...rest}
      >
        {options.map((a) => (
          <option
            value={a.value}
            key={`option-${a.value}`}
          >
            {a.text}
          </option>
        ))}
      </select>

      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
});
