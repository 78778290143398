import moment from "moment";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { gql, useQuery } from "@apollo/client";

import { formatForPeriodicity, formatTarget } from "../libs/formatFunctions";
import useAuth from "../hooks/useAuth";

import artIcon from "../assets/perso-categories/art.png";
import businessIcon from "../assets/perso-categories/business.png";
import coachingIcon from "../assets/perso-categories/coaching.png";
import entrepreneurshipIcon from "../assets/perso-categories/entrepreneurship.png";
import familyIcon from "../assets/perso-categories/family.png";
import healthIcon from "../assets/perso-categories/health.png";
import investmentIcon from "../assets/perso-categories/investment.png";
import learningIcon from "../assets/perso-categories/learning.png";
import networkingIcon from "../assets/perso-categories/networking.png";
import travelsIcon from "../assets/perso-categories/travels.png";
import FormSelectInput from "./FormSelectInput";

export function getIconForTeam(name: string) {
  switch (name.toLowerCase()) {
    case "art":
      return artIcon;
    case "business":
      return businessIcon;
    case "coaching":
      return coachingIcon;
    case "entrepreneurship":
      return entrepreneurshipIcon;
    case "family":
      return familyIcon;
    case "health":
      return healthIcon;
    case "investment":
      return investmentIcon;
    case "learning":
      return learningIcon;
    case "networking":
    case "network":
      return networkingIcon;
    case "travels":
      return travelsIcon;
  }
}

const RESULTS_QUERY = gql`
  query ResultsForPerso($before: DateTime, $after: DateTime) {
    results: resultsTotalForTeams(before: $before, after: $after, perso: true) {
      targets {
        id
        value
        time
        logTotal
        teamMember {
          team {
            name
            id
          }
          user {
            name
            id
          }
        }
      }
      after
      before
    }
  }
`;

const thisMoment = moment().startOf("month");
const options = [
  {
    label: "last month & next 4 months",
    value: {
      before: moment(thisMoment).add(5, "month"),
      after: moment(thisMoment).subtract(2, "month"),
      id: 0,
    },
  },
  {
    label: "last 6 months",
    value: {
      before: moment(thisMoment).add(1, "month"),
      after: moment(thisMoment).subtract(6, "month"),
      id: 1,
    },
  },
  {
    label: "last 12 months",
    value: {
      before: moment(thisMoment).add(1, "month"),
      after: moment(thisMoment).subtract(12, "month"),
      id: 2,
    },
  },
  {
    label: "next 6 months",
    value: {
      before: moment(thisMoment).add(6, "month"),
      after: moment(thisMoment).subtract(1, "month"),
      id: 3,
    },
  },
  {
    label: "next 12 months",
    value: {
      before: moment(thisMoment).add(12, "month"),
      after: moment(thisMoment).subtract(1, "month"),
      id: 4,
    },
  },
  {
    label: "This year",
    value: {
      before: moment(thisMoment).endOf("year").add(1, "month"),
      after: moment(thisMoment).startOf("year").subtract(1, "month"),
      id: 5,
    },
  },
  {
    label: "Last year",
    value: {
      before: moment(thisMoment).startOf("year").add(1, "month"),
      after: moment(thisMoment)
        .startOf("year")
        .subtract(1, "year")
        .subtract(1, "month"),
      id: 6,
    },
  },
];

export function findPeriodId(date: Date, after: Date, periodicity: String = 'month') {
  return moment(date).startOf(periodicity as moment.unitOfTime.StartOf).diff(after, periodicity as moment.unitOfTime.DurationAs) - 1;
}

export function getColor(value: number) {
  if (value < 40) return "#F4511E";
  if (value < 60) return "#EA7317";
  if (value < 80) return "#FFD166";
  if (value < 95) return "#4CAF50";

  return "#226F54";
}

export default function PersoEvolutionComponent() {
  const { user } = useAuth();

  const [selectedPeriod, setSelectedPeriod] = useState(options[0]);
  const { data, loading, refetch } = useQuery(RESULTS_QUERY, {
    variables: {
      after: selectedPeriod.value.after,
      before: selectedPeriod.value.before,

      nextMonth: moment().endOf(user?.company.periodicity || "month"),
      prevMonth: moment().startOf(user?.company.periodicity || "month"),
    },
  });

  const periods = useMemo(() => {
    const { after, before } = selectedPeriod?.value;

    const len = findPeriodId(before.toDate(), after.toDate()) + 1;
    const array = [];
    for (let i = 1; i < len; i++) {
      const date = moment(after).add(i, user?.company.periodicity || "month");
      array.push(date);
    }
    refetch({
      after,
      before,
    });
    return array;
  }, [selectedPeriod, refetch, user]);

  const teams = useMemo(() => {
    const teams = new Map<ID, Team>();
    if (!data?.results?.targets?.length) return [];
    data?.results?.targets?.map((target: Target) => {
      const team = teams.get(target.teamMember.team.id) || target.teamMember.team;
      team.targets = [...(team.targets || []), target];

      return teams.set(target.teamMember.team.id, team);
    });
    return Array.from(teams.values()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [data]);

  return (
    <div className="mr-3">
      <div className="flex justify-between items-center">
        <h4 className="text-title font-bold text-xl">Your progression</h4>
        <div className="flex">
          <FormSelectInput 
            options={options.map(t => ({value: `${t.value.id}`, text: t.label}))}
            onChange={(va) => {
              const selected = options.find((t) => va.target.value === `${t.value.id}`);
              if (selected) setSelectedPeriod(selected);
            }}
            value={selectedPeriod.value.id}
          />
        </div>
      </div>

        {loading ? (
            <div className="mb-5">
              Loading your progression
            </div>
        ) : (
          <div className="mx-0 relative" id="teamResults">
          <table className="w-full p-3 table-fixed mt-3 shadow-md rounded-lg bg-white">
            <thead className="">
              <tr>
                <th className="text-center p-4 rounded-tl-lg bg-tablehead"></th>
                {periods.map((period, i, a) => (
                  <th
                    className={`font-medium text-gray-500 text-center p-4 bg-tablehead ${
                      a.length - 1 === i ? "rounded-tr-lg" : ""
                    }`}
                  >
                    <h3 className="">{formatForPeriodicity(period, user?.company.periodicity || "month", false)}</h3>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody key={`persodash-column`} className="font-normal">
              {teams?.map((team: Team) => {
                return (
                  <tr className={`persodash-column-t-${team.id}`}>
                    <th className="text-center font-normal">
                      <img
                        src={getIconForTeam(team?.name)}
                        className="rounded-full w-24 h-24 m-3 bg-white mx-auto"
                        alt={team?.name}
                      />
                      <h3 className="text-md mb-2">{team?.name}</h3>
                      <span className="text-sm font-medium">{team?.kpiName}</span>
                    </th>

                    {periods.map((period) => {
                      const periodDate = moment(period);
                      const target = team.targets?.find((t) => moment(t.time).isSame(periodDate, user?.company.periodicity || "month"));

                      const done = target?.logTotal || 0;
                      const doneG = Math.min(
                        100,
                        Math.max(0, (100 * done) / (target?.value || 1))
                      );
                      const data = [
                        { name: "Done", value: doneG },
                        { name: "To Do", value: 100 - doneG },
                      ];

                      return (
                        <td
                          key={`td-team-prod-dash-${team.id}-${period}`}
                          className="text-center"
                        >
                          <Link
                            to={
                              target ? `/modal/targetLogs/${team.id}/${target.id}` : ""
                            }
                          >
                            <ResponsiveContainer width="100%" height={120}>
                              <PieChart width={50} height={50}>
                                <Pie
                                  data={data}
                                  dataKey="value"
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={45}
                                  outerRadius={50}
                                  startAngle={90}
                                  endAngle={-270}
                                  cursor="pointer"
                                  isAnimationActive={false}
                                >
                                  {!!target && (
                                    <Label
                                      value={`${Math.round(
                                        (100 * done) / (target?.value || 1)
                                      )}%`}
                                      position="center"
                                      cursor="pointer"
                                      fill={getColor(
                                        (100 * done) / (target?.value || 1)
                                      )}
                                    />
                                  )}
                                  <Cell
                                    fill={getColor(
                                      (100 * done) / (target?.value || 1)
                                    )}
                                  />
                                  <Cell fill="#DDDDDD" />
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </Link>
                          <span
                            title={
                              target
                                ? `Achieved/Goal: ${done}/${target?.value}`
                                : "Target not defined yet"
                            }
                          >
                            {target ? (
                              `${formatTarget(done)} / ${formatTarget(
                                target?.value
                              )}`
                            ) : (
                              <>&zwnj;</>
                            )}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
