import React, { useMemo } from "react";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

import Log from "../interfaces/Log";
import { formatForPeriodicity } from "../libs/formatFunctions";
import useAuth from "../hooks/useAuth";

const TARGET_QUERY = gql`
  query LogsForTarget($target: ID!) {
    logsForTarget(target: $target) {
      id
      action {
        name
      }
      value
      time
      virtualForCumul
    }

    targetById(id: $target) {
      id
      time
    }
  }
`;

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

function TargetDetailsComponent({ target }: { target?: string }) {
  const { data, loading } = useQuery(TARGET_QUERY, { variables: { target } });
  const { user } = useAuth();

  // Group logs by actions for the Donut Chart
  const actionsPieData = useMemo(() => {
    if (!data) return [];

    const actions = data.logsForTarget.reduce(
      (acc: { [key: string]: number }, log: Log) => {
        const name = log.action.name;
        acc[name] = (acc[name] || 0) + log.value;
        return acc;
      },
      {} as { [key: string]: number }
    );
    return Object.keys(actions).map((key) => ({
      name: key,
      value: actions[key],
    }));
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (!data) return null;
  
  return (
    <div className="relative">
        <h3 className="text-2xl text-center">{`Details for actions in ${formatForPeriodicity(data.targetById.time, user?.company.periodicity || "month")}`}</h3>

        <ResponsiveContainer width="100%" height={250}>
          <PieChart width={150} height={150}>
            <Pie
              data={actionsPieData}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={0}
              outerRadius={80}
              startAngle={90}
              endAngle={-270}
              label={({ name, value }) => `${name}: ${value}`}
            >
              {actionsPieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  stroke={COLORS[index % COLORS.length]}
                  fillOpacity={entry.name.includes("previous") ? 0.5 : 1}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      <div className="mt-4 p-4 w-full">
        <h3 className="text-2xl text-center">{`Actions in ${
          formatForPeriodicity(data.targetById.time, user?.company.periodicity || "month")}`}</h3>

        <table className="table-auto w-full">
          <thead>
            <tr className="text-left text-lg">
              <th className="pb-5 pt-3">Time</th>
              <th className="pb-5 pt-3">Action</th>
              <th className="pb-5 pt-3">Value</th>
            </tr>
          </thead>
          <tbody>
            {data.logsForTarget.map((log: Log) => (
              <tr key={log.id}>
                <td>{moment(log.time).format("DD/MM/YYYY")}</td>
                <td>{log.action?.name}</td>
                <td>{log.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
    </div>
  );
}

export default TargetDetailsComponent;
