import { gql, useMutation } from "@apollo/client";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";

import Alert from "../components/Alert";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import LoginWrapper from "../components/wrappers/LoginWrapper";
import { logEvent } from "../hooks/analytics";

const LOGIN = gql`
  mutation Mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      session {
        jwt
      }
      success
      message
    }
  }
`;

type FormValues = {
  email: string;
  password: string;
};

function LoginPage() {
  const [tryLogin, { loading, error }] = useMutation(LOGIN);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  function handleLoginResult(data: any) {
    if (data.login.success) {
      localStorage.setItem("jwt", data.login.session.jwt);

      logEvent('login');
      
      window.location.replace("/");
      return true;
    }

    setError(
      "email",
      { type: "manual", message: data.login.message },
      { shouldFocus: true }
    );
    return false;
  }

  const onSubmit: SubmitHandler<FormValues> = (variables) => {
    tryLogin({ variables }).then((r) => handleLoginResult(r.data));
    return false;
  };

  return (
    <LoginWrapper>
      <form
        className="w-full m-auto p-3 space-y-5 max-w-sm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="text-center mb-16">
          <h1 className="text-3xl my-5 text-gray-800">Welcome back</h1>
          <h3>Please enter your details.</h3>
        </div>

        <Alert
          type="error"
          display={!!(error || errors.email || errors.password)}
        >{`Error: ${
          (error || errors.email || errors.password)?.message
        }`}</Alert>

        <FormInput
          label="Email address"
          type="email"
          placeholder="Enter your email"
          {...register("email", { required: true })}
        />
        <FormInput
          label="Password"
          type="password"
          placeholder="*********"
          {...register("password", { required: true })}
        />

        <Button
          type="submit"
          className="inline-block w-full mt-4"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          {loading ? "Signing in..." : 'Sign in' }
        </Button>

        <Link
          to={"/forgot"}
          className="block text-center p-5 text-sm text-text hover:text-primary"
        >
          Forgot Password?
        </Link>
      </form>
    </LoginWrapper>
  );
}

export default LoginPage;
