import { Link, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import User from "../interfaces/User";
import IconButton from "../components/IconButton";
import { FaChartBar, FaPen, FaPlus } from "react-icons/fa";

export const USERS_QUERY = gql`
  query Users {
    users {
      id
      name
      role
      picture
      isAdmin
      deleted
      teams(onlyPro: true) {
        id
        team {
          id
          name
        }
      }
    }
  }
`;

function AdminUsersPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: usersData } = useQuery(USERS_QUERY);

  if (!user?.isAdmin)
    return (
      <Alert type="error" display>
        You are not logged in or not admin.
      </Alert>
    );

  return (
    <div className="w-full p-4">
      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <div className="flex content-between">
          <div className="w-full">
            <h1 className="text-2xl pb-4 font-bold font-sans">
              Users administration
            </h1>
          </div>
          <IconButton
            icon={FaPlus}
            onClick={() => navigate(`/modal/editUser/`)}
            title="Create new user"
          />
        </div>

        {!usersData?.users?.length ? (
          <p className="text-center p-4">
            No user yet. Please use the "+" button to create one.
          </p>
        ) : (
          <div className="p-4 bg-white w-full shadow-sm rounded-lg">
            <table className="table-auto w-full">
              <thead>
                <tr className="text-center text-lg">
                  <th className="pb-5 pt-3">Name</th>
                  <th className="pb-5 pt-3">Role</th>
                  <th className="pb-5 pt-3">Teams</th>
                  <th className="pb-5 pt-3 text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {usersData?.users?.sort((a:User, b:User) => a.deleted ? 1 : -1).map((user: User) => (
                  <tr className="text-left" key={`users-${user.id}`}>
                    <td className="pb-3">
                      <Link className={`${user.deleted ?  "line-through" : "font-bold" }`} to={`/profile/${user.id}`}>{user.name}</Link>
                      {user.deleted && <span className="ml-2 text-sm">(Deactivated)</span>}
                    </td>
                    <td className="pb-3">{user.role}</td>
                    {user.teams.length ? (
                      <td className="pb-3 text-center">
                        {user.teams
                          .map((team: TeamMember) => team.team.name)
                          .join(", ")}
                      </td>
                    ) : (
                      <td className="pb-3 text-center">
                        <Link to="/admin/teams" className="text-blue-400">
                          No team assigned
                        </Link>
                      </td>
                    )}

                    <td className="pb-3 text-right whitespace-nowrap">
                      <IconButton
                        icon={FaChartBar}
                        onClick={() =>
                          navigate(`/modal/editTargets/${user.id}`)
                        }
                        title="Set &amp; edit targets"
                      />
                      <IconButton
                        icon={FaPen}
                        onClick={() => navigate(`/modal/editUser/${user.id}`)}
                        title="Edit user"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminUsersPage;
