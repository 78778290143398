import { Link, useNavigate, useParams } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";

import useAuth from "../hooks/useAuth";
import Alert from "../components/Alert";
import IconButton from "../components/IconButton";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";

export const MEMBERS_QUERY = gql`
query team($id: ID!) {
  getTeamById(id: $id) {
    id
    name
    members {
      id
      user {
        name
        role
        id
      }
      actions {
        name
        id
      }
      countsInTeamResult
      isManager
    }
  }
}
`;

const DEASSIGN_MUTATION = gql`
mutation assignTeam($user: ID!, $team: ID!, $teamMember: ID) {
  assignTeam(user: $user, team: $team, teamMember: $teamMember, deassign: true) {
    code
    success
  }
}
`;



function AdminTeamMembersPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const id = params.id as ID;
  const { data: teamsData /*, loading */ } = useQuery(MEMBERS_QUERY, { variables: { id }});
  const [deleteTeam] = useMutation(
    DEASSIGN_MUTATION,
    {
      refetchQueries: [MEMBERS_QUERY, "teams"],
    }
  );

  async function _delete(userId: ID, teamMember: ID) {
    if (window.confirm("Are you sure you want to delete this team?")) {
      await deleteTeam({ variables: { user: userId, team: id, teamMember } });
    }
  }

  if (!user?.isAdmin)
    return (
      <Alert type="error" display>
        You are not logged in or not admin.
      </Alert>
    );

  if (!teamsData?.getTeamById) return null;

  return (
    <div className="w-full p-4">
      <div className="text-primary text-sm">
        <Link to="/admin/teams">&lt; Back to teams</Link>
      </div>
    
      <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
        <div className="flex content-between">
          <div className="w-full">
            <h1 className="text-2xl pb-4 font-bold font-sans">Team members of {teamsData.getTeamById.name}</h1>
          </div>
          <IconButton icon={FaPlus} onClick={() => navigate(`/modal/teamMember/${id}`)} title="Add to team"/>
        </div>

        {!teamsData?.getTeamById?.members?.length ? (
          <p className="text-center p-4">
            No team member yet. Please use the "+" button to assign users to the team.
          </p>
        ) : (
        <div className="p-4 bg-white w-full shadow-sm rounded-lg">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-center text-lg">
              <th className="pb-5 pt-3">Name</th>
              <th className="pb-5 pt-3">Actions</th>
              <th className="pb-5 pt-3"> </th>
            </tr>
          </thead>
          <tbody>
            {teamsData?.getTeamById.members?.map((member: TeamMember) => (
              <tr className="text-left" key={`users-${member.id}`}>
                <td className="pb-3">
                  <span><span className={`font-bold pr-2 ${member.isManager ? 'underline' : ''}`}>{member.user.name}</span>{`(${member.user.role})`}</span>
                  {!!member.countsInTeamResult && <span title="Counts for team results">*</span>}
                </td>
                <td className="pb-3">{(member.actions || []).map((a) => a.name).join(', ')}</td>

                <td className="pb-3 text-right whitespace-nowrap">
                  <IconButton icon={FaPen} onClick={() => navigate(`/modal/teamMember/${id}/${member.id}`)} title="Edit actions"/>
                  <IconButton icon={FaTrash} onClick={() => _delete(member.user.id, member.id)} title="Remove from team"/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ul className="text-xs pt-8">
          <li>* : Counts for team results</li>
          <li>_ : Manager of the team </li>
        </ul>
      </div>)}
      </div>
    </div>
  );
}

export default AdminTeamMembersPage;
