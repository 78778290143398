import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client'

const uri = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const httpLink = createUploadLink({
  uri: uri+'/graphql',
});

function getFromSearchParams(name:string){
  const nameR = (new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search)
  if(nameR) return decodeURIComponent(nameR[1]);
}

const authLink = setContext((_, { headers }) => {

  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('jwt') || getFromSearchParams('key'); 

  // console.log({token})
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export default new ApolloClient({
  link: authLink.concat(httpLink as any),
  cache: new InMemoryCache({ typePolicies: {
    Log: {
      keyFields: ['id'],
    },
    User: {
      keyFields: ['id'],
    },
  }}),
  defaultOptions: defaultOptions,
});
