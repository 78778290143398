import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import TeamDashComponentForTeams from "../components/TeamDashComponentForTeams";
import TargetHistoricComponent from '../components/TargetHistoricComponent';
import ObstaclesComponentForTeams from "../components/ObstaclesComponentForTeams";
import RankingBarWorstTeam from "../components/RankingBarWorstTeam";
import { useParams, useSearchParams } from "react-router-dom";

import logo from '../assets/transparent.png'
import { formatForPeriodicity } from "../libs/formatFunctions";
import { FaArrowUp, FaLifeRing } from "react-icons/fa";
import { HiOutlineSparkles } from "react-icons/hi";

import Button from "../components/Button";

const RESULTS_QUERY = gql`
  query ResultsForTeam($company: ID, $nextMonth: DateTime, $prevMonth: DateTime) {
    teams(onlyPro: true) {
      id
      name
      members {
        id
        user {
          id
          name
          role
          picture
          deleted
        }
        kpiName
        isManager
      }
    }

    resultsTotalForTeams(before: $nextMonth, after: $prevMonth, perso: false) {
      targets {
        id
        value
        time
        logTotal
        teamMember {
          team {
            name
            id
          }
          user {
            name
            id
          }
        }
      }
      after
      before
    }

    company(id: $company) {
      periodicity
      mainMetric {
        id
        currentTarget(time: "2022-10-01T00:00:00") {
          logTotal
          value
        }
      }
    }
  }
`;

interface ReportPageProps {
  time?: string,
  company?: string | number,
  hideWatermark?: boolean
};

function PEReportPage({time, company: companyProps, hideWatermark} : ReportPageProps) {
  const { company } = useParams();
  let [searchParams] = useSearchParams();
  
  const strategicAdvice = null;

  const { data: teamsData, loading: teamLoading } = useQuery(RESULTS_QUERY, {
    variables: {
      nextMonth: moment(searchParams.get('time') || time).endOf('month'),
      prevMonth: moment(searchParams.get('time') || time).startOf('month'),
      company: company || companyProps,
    },
  });

  if (!teamsData) return null;

  return (
    <>
      <div className="flex-1 p-4">
        <div className="flex space-x-5 items-baseline">
          <h1 className="text-title text-3xl font-bold text-left py-5">
            {`Performance Report - ${formatForPeriodicity(searchParams.get('time') || time, teamsData.company?.periodicity)}`}
          </h1>

          <span className="font-medium text-lg text-gray-400">{`Last update ${moment().startOf('day').calendar()}`}</span>
        </div>
        
        <div className="w-full flex items-baseline">
          <div className="primary-box flex space-x-10 sepa py-6 px-10 flex-1 bg-green-200 ml-0">
            <div className="flex-1 space-y-2">
              <div className="font-bold text-sm">2024 Budget</div>
              
              <div className="flex items-center space-x-5 ">
                <div className="font-bold text-black text-2xl">$42,724,000</div>
                <div className="font-bold text-sm">vs $35,400,000 ytd</div>
              </div>
              
              <div className="font-bold text-sm ">Expected YoY Growth <strong className="text-black">20%</strong></div>
            </div>
          </div>


          <div className="primary-box flex space-x-10 sepa py-6 px-10 flex-1 bg-cyan-100">
            <div className="flex-1 space-y-2">
              <div className="font-bold text-sm">Actual Performance</div>
              
              <div className="flex items-center space-x-5 ">
                <div className="font-bold text-black text-2xl">$38,414,000</div>
                <div className="font-bold text-sm">vs $34,005,000 ytd</div>
              </div>
              
              <div className="font-bold text-sm"><FaArrowUp className="inline-block text-green-600"/> 13% growth ytd</div>
            </div>
          </div>

          <div className="primary-box flex space-x-10 sepa py-6 px-10 flex-1 bg-orange-100 mr-0">
            <div className="flex-1 space-y-2">
              <div className="font-bold text-sm">Go Get</div>
              
              <div className="font-bold text-black text-2xl">$3,310,000</div>
              
              <div className="flex items-center space-x-5 ">
                <div className="font-bold text-sm ">Open renewal <strong className="text-black">$3,605,000</strong></div>
                <div className="font-bold text-sm ">New business gap <strong className="text-black">$705,000</strong></div>
              </div>
            </div>
          </div>
        </div>


        <div className="w-full flex items-baseline">
          <div className="primary-box flex space-x-10 sepa py-6 px-10 flex-1 ml-0">
            <div className="flex-1 space-y-2">
              <div className="font-bold text-sm">Expected EBIT</div>
              <div className="font-bold text-black text-2xl">$8,544,000</div>
              <div className="font-bold text-sm ">20% of revenues</div>
            </div>

            <div className="flex-1 space-y-2 border-l pl-10 ">
              <div className="font-bold text-sm">Actual EBIT</div>
              <div className="font-bold text-black text-2xl">$6,358,000</div>
              <div className="font-bold text-sm text-orange-600">16% of revenues</div>
            </div>
          </div>

          <div className="primary-box flex space-x-10 py-6 px-10 flex-1 mr-0">
            <div className="flex-1 space-y-2">
              <div className="font-bold text-sm">Expected Cash</div>
              <div className="font-bold text-black text-2xl">$5,200,000</div>
              <div className="font-bold text-sm"><br/> </div>
            </div>

            <div className="flex-1 space-y-2 border-l pl-10 ">
              <div className="font-bold text-sm">Actual cash</div>
              <div className="font-bold text-black text-2xl">$4,850,000</div>
              <div className="font-bold text-sm "> </div>
            </div>
          </div>
        </div>

        <div className="w-full flex items-baseline pl-5">
          <h3 className="flex-1 text-title text-xl font-bold">What we need to hit the Rule of 40:</h3>
          <div className="primary-box text-lg flex space-x-16 px-10 mr-0">
            <div><strong className="text-black mr-2">$4,300,000</strong> in Top Line</div>
            <div><strong className="text-black mr-2">$1,200,000</strong> in Bottom Line</div>
          </div>
        </div>
        
        <div className="w-full">
          <div className="" id="overview">
            {!!teamsData && (
              <TeamDashComponentForTeams
                teams={teamsData?.teams}
                targets={teamsData?.resultsTotalForTeams.targets}
                before={teamsData?.resultsTotalForTeams.before}
                after={teamsData?.resultsTotalForTeams.after}
                loading={teamLoading}
                periodicity={teamsData.company?.periodicity}
              />
            )}
          </div>
        </div>

        <div className="w-full primary-box mx-0" id="mainMetric">
          <TargetHistoricComponent teamMember={teamsData.company?.mainMetric?.id} time={moment(searchParams.get('time') || time).toDate()} legend />
        </div>

        <div className="flex mx-0" id="obstacles">
          <div className="primary-box ml-0 flex-1">
            <ObstaclesComponentForTeams company={company || companyProps as ID} periodicity={teamsData.company?.periodicity} time={moment(searchParams.get('time') || time).toDate()} />
            {/*           
              Afficher la liste des obstacles (1 par équipe, celui qui revient le plus)
              On trie les équipes par rapport à leur performances => LA moins performante au début
            */}
          </div>

          <div className="primary-box flex-1" id="leaderboard">
            <RankingBarWorstTeam company={company || companyProps as ID} time={moment(searchParams.get('time') || time).toDate()} />
          </div>

          <div className="primary-box mr-0 flex-1 flex flex-col" id="">
            <h3 className="text-title font-bold text-lg mb-4">
              Strategic advices
              <HiOutlineSparkles className="inline-block ml-3 text-orange-500" /> 
            </h3>

            { strategicAdvice ? (
              <div className="flex-1 flex flex-col">
                {strategicAdvice}
              </div>
              ) : (
              <div className="flex-1 flex flex-col justify-around text-center">

                <div className="block">
                  <div className="block mb-10">
                    No strategic advices yet.
                  </div>

                  <Button>
                    <FaLifeRing className="inline-block mr-4" />
                    Ask strategic analysis
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!hideWatermark && <div className="flex justify-end m-0 -mt-4"><a className="primary-box bottom-1 right-1 flex mt-0 p-1 pr-2 border-gray-100 border justify-end items-center" href="https://yood.co"><img src={logo} alt="Yood" className="h-8 w-8"/><span className="text-text font-medium">Powered by yood.co</span></a></div>}
    </>
  );
}

export default PEReportPage;
