import React, { useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import FormSelectInput from "../components/FormSelectInput";
import { useNavigate, useParams } from "react-router-dom";
import { OBJECTIVES_QUERY, OKRItem } from '../components/OKRBar';
import { logEvent } from "../hooks/analytics";

const OBJECTIVE_QUERY = gql`
query getObjective($id: ID!) {
  getObjective(id: $id) {
    id
    text
    state
  }
}
`;


const REVIEW_ACTION = gql`
mutation ReviewObjective($comment: String, $state: String!, $id: ID!) {
  reviewObjective(id: $id, state: $state, comment: $comment) {
    success
    created
    message
  }
}
`;

type FormValues = {
  comment: string
  state: string;
};

function ReviewObjectiveModal() {
  const { user } = useAuth();
  const {register, handleSubmit, formState: { errors }} = useForm<FormValues>();
  const navigate = useNavigate();
  const params = useParams();

  const [success, setSuccess] = React.useState(false);
  const [reviewObjective, { loading }] = useMutation(REVIEW_ACTION);
  const { data, loading: loadingObj } = useQuery(OBJECTIVE_QUERY, { variables: { id: params.id }});

  const initialUrl = useMemo(() => window.location.href, []);

  function onSubmit(data:FormValues) {
    const { comment, state } = data;

    logEvent('reviewObjective');

    reviewObjective({
      variables: {
        id: params.id, 
        comment,
        state
      },
      refetchQueries: [OBJECTIVES_QUERY, 'Query'],
    }).then(({ data }) => {
      if (data.reviewObjective.success) setSuccess(true);
    }).catch(e => alert(e.message));
  }

  if (!user) return null;

  return (
    <Modal title="Review an objective" autoHeight >
      <div className="m-4">
        <h3 className="text-title text-md">Objective to review:</h3>
        {!!data?.getObjective && <OKRItem okr={data.getObjective} expanded />}
      </div>

      <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
        <Alert type="success" autoDismiss={3000} display={success} onDismiss={() => (window.location.href === initialUrl) ? navigate(-1) : () => {}}>Review added successfully!</Alert>
          <FormSelectInput
            label="Review"
            options={[{ value: 'outstanding', text: "Outstanding" }, { value: 'meets expectations', text: "Meets Expectations" }, { value: 'needs improvement', text: "Needs Improvement" }]}
            error={errors.state?.message}
            {...register("state", { required: true, value: 'outstanding' })}
          />

          <FormInput
            label="Comment (optional)"
            type="text"
            placeholder="Add a comment to your review"
            error={errors.comment?.message}
            {...register("comment", { required: false })}
          />

        {(loading || loadingObj) && <div>Loading...</div>}

        <div className="flex justify-center">
          <Button type="submit">Review Objective</Button>
        </div>
      </form>
    </Modal>
  );
}

export default ReviewObjectiveModal;
