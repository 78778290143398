import React, { useMemo } from "react";
import { useParams } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import Creatable from "react-select/creatable";

import Alert from "../components/Alert";
import Button from "../components/Button";
import Modal from "../components/Modal";
import FormInput from "../components/FormInput";
import { Controller, useForm } from "react-hook-form";
import { TEAMS_QUERY } from "./AdminTeamsPage";
import FormGenericInput from "../components/FormSelectableInput";

type FormValues = {
  name: string;
  actions: string[];
  kpiName: string;
  kpiUnit: string;
};

const QUERY_TEAM = gql`
  query getTeamById($id: ID!) {
    getTeamById(id: $id) {
      id
      name
      kpiUnit
      kpiName
      actions {
        id
        name
      }
    }

    actions {
      id
      name
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateTeam(
    $id: ID
    $name: String!
    $actions: [ActionInput!]
    $kpiName: String!
    $kpiUnit: String!
  ) {
    updateTeam(
      id: $id
      name: $name
      actions: $actions
      kpiName: $kpiName
      kpiUnit: $kpiUnit
    ) {
      success
      message
    }
  }
`;

const CREATE_ACTION_MUTATION = gql`
  mutation createAction($name: String!) {
    createAction(name: $name) {
      success
      message
      id
    }
  }
`;

function TeamEditModal() {
  const params = useParams();

  const [success, setSuccess] = React.useState(false);
  const [actions, setActions] =
    React.useState<{ value: string; label: string }[]>();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const id = params.id as ID;

  const { data: initialValues, loading } = useQuery(QUERY_TEAM, {
    variables: { id: id || -1 },
  });

  const actionsOptions = useMemo(() => {
    if (initialValues?.getTeamById?.actions)
      setValue(
        "actions",
        initialValues?.getTeamById?.actions.map((action: Action) => action.id)
      );

    if (initialValues?.actions) {
      return initialValues.actions.map((action: Action) => ({
        value: action.id,
        label: action.name,
      }));
    }

    return [];
  }, [initialValues, setValue]);

  const [updateTeam, { loading: updateLoading }] = useMutation(
    UPDATE_MUTATION,
    {
      refetchQueries: [QUERY_TEAM, "getTeamById", TEAMS_QUERY, "teams"],
    }
  );

  const [createAction /*, { loading: createActionLoading }*/] = useMutation(
    CREATE_ACTION_MUTATION,
    {
      refetchQueries: [QUERY_TEAM, "getTeamById"],
    }
  );

  async function onSubmit(data: FormValues) {
    try {
      const result = await updateTeam({
        variables: {
          id: id,
          name: data.name,
          actions: data.actions.map((a) => ({ id: a })),
          kpiName: data.kpiName,
          kpiUnit: data.kpiUnit,
        },
      });
      if (result.data?.updateTeam?.success) setSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  /*
  function _actionsChanged(actionsIn: any) {
    const actions = (actionsIn || []).map((a: any) => ({
      value: a.value,
      label: a.label,
    }));

    setActions(actions);
  }
  */

  async function _createAction(value: string) {
    const result = await createAction({
      variables: {
        name: value,
      },
    });

    if (result.data?.createAction?.success) {
      setActions([
        ...(actions || []),
        { value: result.data.createAction.id, label: value },
      ]);
    }
  }

  if (loading) return null;
  if (!initialValues) return null;

  return (
    <Modal title={!!id ? "Edit team" : "Create new team"}>
      <Alert
        type="success"
        display={!!success}
        onDismiss={() => {
          setSuccess(false);
        }}
        autoDismiss={3000}
      >
        Team edited successfully !
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Team name"
          type="text"
          error={errors.name?.message}
          {...register("name", {
            required: true,
            value: initialValues?.getTeamById?.name,
          })}
        />

        <FormInput
          label="KPI name"
          type="text"
          error={errors.kpiName?.message}
          {...register("kpiName", {
            required: true,
            value: initialValues?.getTeamById?.kpiName,
          })}
        />

        <FormInput
          label="KPI unit"
          type="text"
          error={errors.kpiUnit?.message}
          {...register("kpiUnit", {
            required: true,
            value: initialValues?.getTeamById?.kpiUnit,
          })}
        />

        <FormGenericInput name="actions" label="Actions">
          <Controller
            name="actions"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <Creatable
                  options={actionsOptions}
                  onChange={(options) =>
                    onChange(options?.map((option: any) => option.value))
                  }
                  onBlur={onBlur}
                  value={actionsOptions.filter((option: any) =>
                    value?.includes(option.value)
                  )}
                  defaultValue={actionsOptions.filter((option: any) =>
                    value?.includes(option.value)
                  )}
                  onCreateOption={(value) => _createAction(value)}
                  isSearchable
                  isMulti
                />
              );
            }}
          />
        </FormGenericInput>

        {loading || updateLoading ? (
          <div>Loading...</div>
        ) : (
          <Button>Save changes to team</Button>
        )}
      </form>
    </Modal>
  );
}

export default TeamEditModal;
