import { IconType } from "react-icons";

type ButtonProps = {
  icon: IconType;

  onClick?: () => void;
  className?: string;
  title?: string;
  style?: any;
  variant?: "primary" | "danger";
};

export default function IconButton(props: ButtonProps) {
  const { className, icon, variant, ...rest } = props;

  const colors = variant === "danger" ? 'bg-red-500 hover:bg-red-700 disabled:bg-red-100' : 'bg-primary hover:bg-blue-700 disabled:bg-blue-100';

  return (
    <button
      className={`${colors} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${className}`}
      {...rest}
    >
      {icon({})}
    </button>
  );
}
