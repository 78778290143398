import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";
import { logEvent } from "../hooks/analytics";

type FormValues = {
  code: string;
};

const SYNC_MMUTATION = gql`
  mutation sync($code: String!) {
    syncWatch(code: $code) {
      success
      message
    }
  }
`;

function SyncWatchModal() {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string|null>(null);

  const [sync, { loading: updateLoading }] = useMutation(SYNC_MMUTATION);

  async function onSubmit(data: FormValues) {
    setSuccess(false);
    setError(null);
    const result = await sync({
      variables: {
        code: data.code,
      },
    });

    logEvent('syncWatchEnd', { success: `${result.data?.syncWatch?.success}` });

    if (result.data?.syncWatch?.success) return setSuccess(true);
    setError(result.data?.syncWatch?.message);
  }

  if (!user) return null;

  return (
    <Modal title="Synchronize your watch" autoHeight className="max-w-lg">
      <div className="pb-4">
        To log your personal progress on Yood, you can sync your Apple Watch
        with your account.
        <br />
        To do so, please download the Yood app from the App Store.
        <br />
        When opening the app, you'll be presented a personal code. Please enter
        it below.
        <br />
      </div>

      <div className="py-4">
        <Alert
          type="success"
          display={success}
          onDismiss={() => setSuccess(false)}
        >
          Your watch is now connected to your account !
        </Alert>
        <Alert
          type="error"
          display={!!error}
          onDismiss={() => setError(null)}
        >
          {error}
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label="Code displayed on your watch"
            type="text"
            error={errors.code?.message}
            {...register("code", {
              required: true,
            })}
          />

          {updateLoading ? (
            <div>Loading...</div>
          ) : (
            <Button>Connect your watch</Button>
          )}
        </form>
      </div>
    </Modal>
  );
}

export default SyncWatchModal;
