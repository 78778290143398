import { gql, useQuery } from "@apollo/client";
import { formatTarget } from "../libs/formatFunctions";

const LEADERBOARD_QUERY = gql`
  query LeaderBoardForTeamOfTarget($company: ID!, $time: DateTime!) {
    leaderboard: bestMemberOfTeams(company: $company, time: $time) {
      value
      user {
        name
        picture
      }
      logs
      team {
        name
      }
    }
  }
`;

interface RankingBarProps {
  company: ID,
  time: Date,
};

export default function RankingBarWorstTeam(props: RankingBarProps) {
  const { data } = useQuery(LEADERBOARD_QUERY, {
    variables: {
      company: props.company,
      time: props.time
    }
  });

  return (
    <div className="flex-1">
      <h3 className="text-title font-bold text-lg mb-4">
        Best performer per team
      </h3>

      <div id="leaderboardGraph">
        {data?.leaderboard?.map((target: any, i:number) => (
          <div className="flex mb-3 items-center relative">
            <img
              src={target.user.picture}
              className={`h-10 w-10 rounded-full text-xs border`}
              alt={target.user.name}
            />
    
            <div className="flex-1 ml-2 overflow-hidden">
              <div className="flex pb-1">
                <div className="font-medium truncate">
                {`${target.team.name}:`}
              </div>
              <div className="pl-1 truncate">
                {target.user.name}
              </div>
              </div>

              <div
                className="text-sm"
              >{`${formatTarget(target.logs)} / ${formatTarget(target.value)} (${Math.round(100 * target.logs/(target.value || 1))}%)`}</div>
            </div>
        </div>
        ))}
      </div>
    </div>
  );
}
