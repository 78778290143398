import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Alert from "./Alert";
import IconButton from "./IconButton";
import { FaMinus, FaPlus } from "react-icons/fa";
import { USERS_QUERY } from "../pages/AdminUsersPage";

const QUERY_USER = gql`
  query getUserAndTeam($id: ID!) {
    getUserById(id: $id) {
      id
      name
      role
      email
      isAdmin

      teams(onlyPro: true) {
        id
        team {
          id
          name
        }

        actions {
          name
          id
        }
      }
    }

    teams(onlyPro: true) {
      id
      name

      actions {
        name
        id
      }
    }
  }
`;

const ASSIGN_TEAM_MUTATION = gql`
mutation assignTeam($user: ID!, $team: ID!, $teamMember: ID, $deassign: Boolean) {
  assignTeam(user: $user, team: $team, deassign: $deassign, teamMember: $teamMember) {
    success
    message
  }
}
`;

function UserTeamsEditComponent({ userId }: { userId: ID }) {
  const [success, setSuccess] = React.useState(false);

  const { data: initialUser } = useQuery(QUERY_USER, {
    variables: { id: userId },
  });

  const [ assignTeam ] = useMutation(
    ASSIGN_TEAM_MUTATION,
    {
      refetchQueries: [QUERY_USER, "getUserById", USERS_QUERY, "Users"],
    }
  );


  function joinTeam(teamId: ID) {
    return async () => {
      await assignTeam({
        variables: {
          user: userId,
          team: teamId,
          deassign: false,
        },
      });

      setSuccess(true);
    }
  }

  function removeFromTeam(teamId: ID, teamMember: ID) {
    return async () => {
      const confirm = window.confirm("Are you sure you want to remove this user from this team?");
      if (!confirm) return;
      await assignTeam({
        variables: {
          user: userId,
          team: teamId,
          teamMember,
          deassign: true,
        },
      });

      setSuccess(true);
    }
  }


  if (!initialUser) return null;

  return (
    <div className="my-4 p-4 bg-white w-full shadow-sm rounded-lg">
      <h1 className="text-2xl pb-4 font-bold font-sans">
        User teams &amp; actions
      </h1>
      <Alert
        type="success"
        display={!!success}
        onDismiss={() => setSuccess(false)}
        autoDismiss={3000}
      >
        User's teams successfully updated !
      </Alert>

      <div className="my-4">
        <h3 className="text-xl pb-4 font-bold font-sans">
          Current teams & actions of {initialUser.getUserById.name}
        </h3>
        <table className="table-auto w-full">
          <thead>
            <tr className="text-left text-lg">
              <th className="pb-5 pt-3">Name</th>
              <th className="pb-5 pt-3">Actions</th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
          {!initialUser.getUserById.teams.length && <tr><td className="text-center" colSpan={2}>No team currently</td></tr>}

            {initialUser.getUserById.teams.map((teamMember: TeamMember) => (
              <tr className="text-left" key={`teamMember-${teamMember.id}`}>
                <td className="pb-3">{teamMember.team?.name}</td>
                <td className="pb-3">
                  {teamMember.actions
                    .map((action: Action) => action.name)
                    .join(", ")}
                </td>
                <td className="pb-3 text-right"><IconButton icon={FaMinus} title={`Remove ${initialUser.getUserById.name} from team`} onClick={removeFromTeam(teamMember.team.id, teamMember.id)}/></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="my-6">
        <h3 className="text-xl pb-4 font-bold font-sans">Available teams</h3>
        <table className="table-auto w-full">
          <thead>
            <tr className="text-left text-lg">
              <th className="pb-5 pt-3">Name</th>
              <th className="pb-5 pt-3">Default actions</th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
          {!initialUser.teams
            .filter((team: Team) => !initialUser.getUserById.teams.find((teamMember: TeamMember) => teamMember.team?.id === team.id)).length && <tr><td className="text-center" colSpan={2}>No team available currently</td></tr>}


            {initialUser.teams
            .filter((team: Team) => !initialUser.getUserById.teams.find((teamMember: TeamMember) => teamMember.team?.id === team.id))
            .map((team: Team) => (
              <tr className="text-left" key={`team-${team.id}`}>
                <td className="pb-3">{team.name}</td>
                <td className="pb-3">
                  {team.actions
                    ?.map((action: Action) => action.name)
                    .join(", ")}
                </td>
                <td className="pb-3 text-right"><IconButton icon={FaPlus} title={`Add ${initialUser.getUserById.name} to team`} onClick={joinTeam(team.id)}/></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserTeamsEditComponent;
