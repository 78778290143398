import { gql, useMutation, useQuery } from "@apollo/client";
import { FaColumns, FaList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import OKRKanbanCard from "../components/OKRKanbanCard";
import useAuth from "../hooks/useAuth";
import { useEffect, useState } from "react";


export const OBJECTIVES_MEMBERS_QUERY = gql`
  query myQuery {
    teams(onlyPro: true) {
      id
      name
      members {
        id
        user {
          id
          name
          role
          picture
          deleted
        }
        currentTarget {
          id
          time
          value
          logTotal
          logs {
            value
          }
        }
        kpiName
        isManager
        objectives {
          id
          primary
          state
          text
          type
          stateObj {
            id
            text
            textColor
            color
          }
          reviews {
            state
            comment
            color
          }
        }
      }
    }

    states: objectiveStates {
      id
      text
      color
      textColor
    }
  }
`;


const OBJECTIVE_ACTION = gql`
  mutation AddObjective(
    $text: String!
    $stateId: ID
    $type: String!
    $primary: Boolean
    $forUser: ID
    $forTeam: ID
    $id: ID
    $deleted: Boolean
  ) {
    addObjective(
      id: $id
      text: $text
      stateId: $stateId
      type: $type
      primary: $primary
      forUser: $forUser
      forTeam: $forTeam
      deleted: $deleted
    ) {
      success
      created
      message
      id
    }
  }
`;



function ObjectivesKanbanPage() {
  const { data: dashData, refetch } = useQuery(OBJECTIVES_MEMBERS_QUERY);
  const [addObjective] = useMutation(OBJECTIVE_ACTION);
  const [allOkrs, setAllOkrs] = useState<{okr:OKR, teamMember: TeamMember}[]>([]);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const okrs = dashData?.teams?.reduce((acc: { okr: OKR; teamMember: TeamMember; }[], team: Team) => {
      team.members?.forEach((tm: TeamMember) => acc = [...acc, ...tm.objectives.map((okr) => ({ okr, teamMember: tm }))]);
      return acc;
    }, []).sort((a:any, b:any) => (a.okr.primary && !b.okr.primary) ? -1 : 1);

    setAllOkrs(okrs);
  }, [dashData?.teams])

  async function handleDragEnd({ destination, source, draggableId }:any) { // { source, destination, type}: any) {
    if (!destination) return;
    if (source.droppableId === destination.droppableId) return;

    const found = allOkrs.find(({ okr, teamMember }:{ okr: OKR, teamMember: TeamMember }) => `${okr.id}-${teamMember.id}` === draggableId);
    if (!found) return;
    const okrT:OKR = found.okr;

    const spl = destination.droppableId.split('-');
    const newState = spl[spl.length - 1];
    await addObjective({
      variables: {
        text: okrT.text,
        type: "user",
        stateId: newState,
        primary: okrT.primary,
        forUser: user?.id,
        deleted: false,
        id: okrT.id,
      },
    })
    .then(async () => {
      refetch();
    })
    .catch((e) => alert(e.message));

    const index = allOkrs.findIndex(({ okr }:{ okr: OKR }) => okr.id === okrT.id);
    const okrs = allOkrs;

    okrs[index]!.okr.stateObj!.id = newState;

    // setAllOkrs(okrs);
  }

  return (
    <div className="p-4 pt-6 flex-1">
      <div className="flex">
        <h1 className="text-3xl text-title pb-4 font-bold font-sans flex-1">
          Objectives - Kanban view
        </h1>

        <div className="flex flex-row space-x-3 mr-6">
          <FaList className="h-6 w-6 cursor-pointer text-title" onClick={() => navigate('/objectives')} />
          <FaColumns className="h-6 w-6 cursor-pointer text-title"  onClick={() => navigate('/objectives/kanban')} />
        </div>
      </div>

      <div className="w-full relative h-full">
        <div className="absolute right-0 left-0 overflow-auto flex flex-row">

        <DragDropContext onDragEnd={handleDragEnd}>
          {dashData?.states &&
            dashData.states /* For Mockups .sort((a:Team, b:Team) => a.name === 'SALES' ? -1 : 1) */
              .map((state: OKRState) => {
                const okrs = allOkrs?.filter(({ okr } : {okr: OKR}) => okr.stateObj?.id === state.id)

                return (
                  <div key={`kanbanCol-${state.id}`} style={{backgroundColor: state.color}} className="p-4 m-4 border border-gray-200 rounded-md shadow-md">
                    <h2 className="text-lg font-bold text-center" style={{color: state.textColor}} >{ state.text}</h2>
                    <Droppable
                      droppableId={`${state.id}`}
                      type="column"
                      direction="vertical"
                      key={`okrs-column-sort-${state.id}`}
                    >
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <div className="flex-1 overflow-y-scroll px-4 max-h-screen">
                            {okrs?.map(({ teamMember, okr }: { okr: OKR, teamMember: TeamMember }, index: number) => (
                              <Draggable
                                index={index}
                                key={`${okr.id}-${teamMember.id}`}
                                draggableId={`${okr.id}-${teamMember.id}`}
                              >
                                {(provided) => (
                                  <div { ...provided.draggableProps} ref={provided.innerRef}>
                                    <OKRKanbanCard key={`${okr.id}-${teamMember.id}`} id={`${okr.id}-${teamMember.id}`} teamMember={teamMember} okr={okr} state={state.id} update={refetch} handleProps={provided.dragHandleProps} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder} 
                          </div>
                          <div className="rounded-md my-5 mx-4 shadow-sm bg-white bg-opacity-90">
                            <OKRKanbanCard create state={state.id} update={refetch} />
                          </div>
                      </div>     
                    )}
                    </Droppable>
                  </div>
                );
              })}
              </DragDropContext>
          </div>
      </div>
    </div>
  );
}

export default ObjectivesKanbanPage;
