import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import Button from "./Button";
import FormInput from "./FormInput";
import Alert from "./Alert";
import { USERS_QUERY } from "../pages/AdminUsersPage";

type FormValues = {
  name: string;
  email: string;
  isAdmin: boolean;
  role: string;
  sheetURL: string;
};

const QUERY_USER = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      name
      role
      email
      isAdmin
      sheetURL
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateUser(
    $email: String!
    $id: ID
    $name: String!
    $isAdmin: Boolean
    $role: String
    $sheetURL: String
  ) {
    updateUser(
      email: $email
      id: $id
      name: $name
      isAdmin: $isAdmin
      role: $role
      sheetURL: $sheetURL
    ) {
      success
      message
    }
  }
`;

function UserEditInfoComponent({ userId }: { userId: ID }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [success, setSuccess] = React.useState(false);

  const { data: initialUser, loading } = useQuery(QUERY_USER, {
    variables: { id: userId },
  });

  const [updateUser, { loading: updateLoading }] = useMutation(
    UPDATE_MUTATION,
    {
      refetchQueries: [QUERY_USER, "getUserById", USERS_QUERY, "Users"],
    }
  );

  async function onSubmit(data: FormValues) {
    await updateUser({
      variables: {
        id: userId,

        email: data.email,
        name: data.name,
        isAdmin: data.isAdmin,
        sheetURL: data.sheetURL,

        role: data.role,
      },
    });

    setSuccess(true);
  }

  if (loading) return null;

  return (
    <div>
      <Alert
        type="success"
        display={!!success}
        onDismiss={() => setSuccess(false)}
        autoDismiss={3000}
      >
        User info edited successfully !
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Name"
          type="text"
          error={errors.name?.message}
          {...register("name", {
            required: true,
            value: initialUser?.getUserById.name,
          })}
        />

        <FormInput
          label="Email"
          type="email"
          error={errors.email?.message}
          {...register("email", {
            required: true,
            value: initialUser?.getUserById.email,
          })}
        />

        <FormInput
          label="Role"
          type="text"
          error={errors.role?.message}
          {...register("role", {
            required: true,
            value: initialUser?.getUserById.role,
          })}
        />

        <FormInput
          label="Google Sheet URL"
          type="url"
          error={errors.sheetURL?.message}
          {...register("sheetURL", {
            value: initialUser?.getUserById.sheetURL,
          })}
        />
        <FormInput
          label="Admin user ?"
          type="checkbox"
          error={errors.isAdmin?.message}
          {...register("isAdmin", {
            value: initialUser?.getUserById.isAdmin,
          })}
        />

        {loading || updateLoading ? (
          <div>Loading...</div>
        ) : (
          <Button>Save changes to user</Button>
        )}
      </form>
    </div>
  );
}

export default UserEditInfoComponent;
