import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import bellIcon from "../assets/icons/bell.svg";

const NOTIFS_QUERY = gql`
query notifications {
  notifications {
    id
    text
    title
    link
    new
    author {
      id
      name
      picture
    }
  }
}`;

const SETREAD_MUTATION = gql`
mutation setNotifRead($id: ID!) {
  setNotifRead(id: $id) {
    success
  }
}
`;

export default function NotificationTray() {
  const { data, refetch } = useQuery(NOTIFS_QUERY);
  const [setRead] = useMutation(SETREAD_MUTATION);
  const { user } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => {
      try { if (user && refetch) refetch(); } catch (e) { console.warn(e); }
    }, 20000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="items-center relative group cursor-pointer">
      <img src={bellIcon} className="mr-3 h-6 w-6 center" alt="Notifications" />

      {!!data?.notifications?.filter((t:Notification) => t.new)?.length && (
        <FaCircle className="absolute top-0 right-3 text-red-600 h-3 w-3 animate-pulse" />
      )}

      <div
        className={`w-72 text-left bg-white rounded-md absolute right-0 font-normal border hidden z-10 group-hover:block`}
      >
        <ul>
          {!data?.notifications?.length && (<div className="p-5 text-sm text-text text-center">You don't have any notification</div>)}

          {!!data?.notifications?.length && data?.notifications?.map((notif:Notification) => (
            <li className="text-text">
              <Link to={notif.link || '/'} className="block" onClick={() => setRead({variables: {id: notif.id}})}>
                <div
                  className={`flex items-center m-1 px-3 py-3 sm:px-6 ${
                    notif.new
                      ? "bg-lightBlue-50 border hover:bg-lightBlue-100"
                      : "hover:bg-gray-50"
                  } border-blue-200  rounded-md`}
                >
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="">
                      <div className="truncate font-bold text-title text-sm">
                        {notif.title}
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm text-text">
                          {notif.text}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
