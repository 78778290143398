import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Modal from "../components/Modal";
import Alert from "../components/Alert";

import useAuth from "../hooks/useAuth";

type FormValues = {
  current: string;
  new: string;
  confirm: string;
};

const UPDATE_MMUTATION = gql`
  mutation updatePassword($current: String!, $newPass: String!) {
    updatePassword(current: $current, newPass: $newPass) {
      success
      message
    }
  }
`;

function ChangePasswordModal() {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [success, setSuccess] = React.useState(false);

  const [updatePassword, { loading: updateLoading }] = useMutation(UPDATE_MMUTATION);

  async function onSubmit(data: FormValues) {
    setSuccess(false);
    const result = await updatePassword({
      variables: {
        current: data.current,
        newPass: data.new,
      },
    });

    if (result.data?.updatePassword?.success) return setSuccess(true);
    alert(result.data?.updatePassword?.message);
  }

  if (!user) return null;

  return (
    <Modal title="Change password">
      <div className="p-4">
        <Alert
          type="success"
          display={success}
          onDismiss={() => setSuccess(false)}
        >
          Password changed !
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label="Current password"
            type="password"
            error={errors.current?.message}
            {...register("current", {
              required: true,
            })}
          />

          <FormInput
            label="New password"
            type="password"
            error={errors.new?.message}
            {...register("new", {
              required: true,
            })}
          />

          <FormInput
            label="Confirm password"
            type="password"
            error={errors.confirm?.message}
            {...register("confirm", {
              required: true,
            })}
          />

          {updateLoading ? <div>Loading...</div> : <Button>Save</Button>}
        </form>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
